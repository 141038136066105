import React from 'react'
import TablaDetalle from "../../common/Tabla/TablaDetalle";
import {formatFacturas, formatModificacionesKYC} from "../../../utils/helpers";
import {GET_VENDEDOR_MODIFICACIONES_KYC} from "../../../utils/queries/Queries";
import {useQuery} from "@apollo/client";

const VendedorKycTabla = (props) =>{

    const cabecera ={
        fecha: {
            id: 1,
            label:'Fecha',
            order: 'desc',
            status: false
        },
        estado: {
            id: 2,
            label:'Estado',
            order: 'desc',
            status: false
        },
        operador: {
            id: 3,
            label:'Operador',
            order: 'desc',
            status: false
        },
        comentario: {
            id: 4,
            label:'Comentario',
            order: 'desc',
            status: false
        },
    }
    const {data, loading, error} = useQuery(GET_VENDEDOR_MODIFICACIONES_KYC,{
        variables:{
            idVendedor: props.vendorId,
        }
    });
    if (data){


        return(
            <TablaDetalle
                cabecera={cabecera}
                detalles={formatModificacionesKYC(data.vendedor.modificacionesKYC)}
            />
        )

    }

    // todo: Ver funcionalidad de manejo de ordenamiento de datos
    // const handleSort = ()=>{
    //
    // }
    //
    // const handleFilter = ()=>{
    //
    // }

    return( ''
        // <TablaDetalle
        //     cabecera={cabecera}
        //     detalles={formatFacturas(props.itemList)}
        // />
    )
}

export default VendedorKycTabla;