import {SET_SIDEBAR_STATUS, TOGGLE_SIDEBAR} from "../actions/sidebar";

export default function sidebarStatus(state = {}, action){

    switch (action.type){
        case SET_SIDEBAR_STATUS:
            // console.log('checking reducer sidebar:', action)
            return action.sidebarStatus
        case TOGGLE_SIDEBAR:
            // console.log('TEST: ', action.sidebarStatus);
            return (action.sidebarStatus === 'open' ? 'closed' : 'open')
        default:
            return state
    }
}
