import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { BounceLoader } from "react-spinners";
import { override } from "../../../utils/helpers";
import { UPDATE_DATA_VENDOR } from "../../../utils/mutators/Mutators";
import { GET_VENDEDOR } from "../../../utils/queries/Queries";
import { ReactComponent as CheckIcon } from "../../../assets/images/check.svg";
import { handleToggleModal } from "../../../actions/modal";
import DatePicker from 'react-date-picker';


const FormCI = props => {

    //decalaramos las variables y inciamos con valores correspondientes
    const initialState = {
        variables: {
            idVendedor: props.idVendedor,
            fechaVencimientoCi: props.cedulaVencimiento,
        }
    }

    //inicializamos las variables
    const [state, setState] = useState(initialState);

    //hacemos la consulta al recurso correspondiente
    const [ModificarDateExpireCI, { data, loading, error, reset }] = useMutation(UPDATE_DATA_VENDOR, {
        refetchQueries: [
            GET_VENDEDOR, // DocumentNode object parsed with gql
            'Get Vendedor' // Query name
        ],
    })

    //funcion para cerrar modal
    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
        reset()
    }

    //funcion submit para mandar las variables al recurso de modificacion de fecha de vencimiento de cedula
    const handleSubmit = () => {
        ModificarDateExpireCI({
            variables: {
                idVendedor: state.variables.idVendedor,
                fechaVencimientoCi: state.variables.fechaVencimientoCi,
            }
        }).then(r => {
            console.log('Rpta Edicion vencimiento CI', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edicion vencimiento CI-> ' + reason);
            });
    }

    //onchange correspondiente al datepicker para setear la fecha
    const handleDateChange = (data) => {
        const Fecha = new Date(data).toISOString().slice(0, 10)
        setState(prevState => (
            {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    fechaVencimientoCi: new Date(Fecha).toISOString().slice(0, 10),
                }
            }
        ))
    }

    //formateo de fechas para mostrar en datepicker
    const formatDateExpire = new Date(state?.variables?.fechaVencimientoCi)
    formatDateExpire.setDate(formatDateExpire.getDate() + 1)

    return (
        <Fragment>
            {error &&
                <div className="notification is-danger">
                    <button className="delete"></button>
                    {error.message}
                </div>
            }
            {
                !loading && (
                    !data && (
                        <form id="dateEditExpires">
                            <div className="column">
                                <div className="item-column d-grid" >
                                    <label className="mb-1" htmlFor="">Fecha de vencimiento de cédula:</label>
                                    <DatePicker
                                        clearIcon={null}
                                        onChange={handleDateChange}
                                        value={formatDateExpire}
                                        className='date-input' />
                                </div>
                            </div>
                        </form>
                    )
                )
            }
            {loading && (
                <div className='my-5 py-5'>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                </div>
            )}
            {!data
                ? (
                    <footer className="modal-card-foot pb-0">

                        <button

                            onClick={() => handleSubmit()}
                            className={loading ? 'button is-normal btn-enviar is-loading' : 'button is-normal btn-enviar'}>Guardar</button>
                        <button
                            onClick={() => handleCloseModal()}
                            className="button btn-cancelar" data-bulma-modal="close">Cancelar
                        </button>
                    </footer>
                )
                : (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>Fecha de vencimiento de cedula editado correctamente</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    handleCloseModal()
                                }}
                                className='button is-normal btn-enviar'>
                                Aceptar
                            </button>
                        </footer>
                    </div>
                )
            }
        </Fragment>
    )
}


function mapStateToProps({ authedUser }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(FormCI);   