import React, { Fragment } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { DELETE_FACTURADOR } from "../../utils/mutators/Mutators";
import { GET_FACTURADORES } from "../../utils/queries/Queries";
import { connect } from "react-redux";
import { handleToggleModal } from "../../actions/modal";
import toast, { Toaster } from 'react-hot-toast';

const ActionsFacturadorList = (props) => {
    // const { pathname } = useLocation();

    // props.dataID
    const [EliminarFacturador, { loading }] = useMutation(DELETE_FACTURADOR, {
        variables: {
            facturadorId: props.dataID,
        },
        refetchQueries: [
            GET_FACTURADORES, // DocumentNode object parsed with gql
            'GetComments' // Query name
        ],
    });

    const errorDel = (reason) => toast.error('Ocurrio un error al eliminar: ' + JSON.stringify(reason.message));
    const successDel = (r) => toast.success('Facturador eliminado exitosamente.')

    const handleOnClick = (e, btn) => {
        e.preventDefault()

        if (btn === '_btnDelete') {
            props.dispatch(handleToggleModal('delete', props.dataID))
        }

        if (btn === '_btnEdit') {
            props.dispatch(handleToggleModal('edit', props.dataID));
        }

    }

    return (
        <Fragment>
            <button
                id='_btnEdit'
                className='button is-link is-inverted'
                onClick={(e) => handleOnClick(e, '_btnEdit')}>
                <FontAwesomeIcon icon={['fas', 'pen']} />
            </button>
            <button id='_btnDelete'
                className={loading ? 'button is-loading' : 'button is-danger is-inverted '}
                onClick={(e) => handleOnClick(e, '_btnDelete')}>
                <FontAwesomeIcon icon={['fas', 'times']} />
            </button>
            <Toaster position="top-right" toastOptions={{
                className: '',
                style: {
                    border: '1px solid rgba(85, 82, 217, 0.2)',
                    padding: '16px',
                    color: '#713200',
                },
            }} />
        </Fragment>
    )
}
function mapStateToProps({ modalStatus }) {
    return {
        modalStatus,
    }
}

export default connect(mapStateToProps)(ActionsFacturadorList);