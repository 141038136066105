import { useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { Button } from 'react-bulma-components';
import { handleToggleModal } from '../../../actions/modal';
import { formatDiviceData } from '../../../utils/helpers';
import { GET_VENDEDOR_DIVICE } from '../../../utils/queries/Queries';
import TablaDetalle from '../../common/Tabla/TablaDetalle';

const VendedorDivice = props => {

    const cabecera = {
        firma: {
            id: 1,
            label: 'FRIMA',
            order: 'desc',
            status: false
        },
        modelo: {
            id: 2,
            label: 'MODELO',
            order: 'desc',
            status: false
        },
        marca: {
            id: 3,
            label: 'MARCA',
            order: 'desc',
            status: false
        },
        sistem_name_and_version_os: {
            id: 4,
            label: 'SISTEMA/VERSION',
            order: 'desc',
            status: false
        },
        versioncode: {
            id: 5,
            label: 'VERSIONCODE + VERSIONDESC',
            order: 'desc',
            status: false
        },
        estado: {
            id: 6,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        fechaActualizacion: {
            id: 7,
            label: 'FECHA ACTUALIZACIÓN',
            order: 'desc',
            status: false
        }
    }

    const { data, loading, error } = useQuery(GET_VENDEDOR_DIVICE, {
        variables: {
            idVendedor: props.vendorId,
        }
    });

    const datalist = (data?.vendedor?.allDispositivos)
    const dataFormated = formatDiviceData(datalist)
    const shortList = Object.values(dataFormated).slice(0, 5)

    return (
        <TablaDetalle
            cabecera={cabecera}
            detalles={shortList}
        />
    )
}

export default VendedorDivice