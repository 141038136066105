import React, {Fragment, useState} from 'react';
import {connect} from "react-redux";
import {useMutation} from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {EDIT_ESTADO_VENDEDOR} from "../../../utils/mutators/Mutators";
import {GET_VENDEDOR} from "../../../utils/queries/Queries";
import {BounceLoader} from "react-spinners";
import {override} from "../../../utils/helpers";
import {handleToggleModal} from "../../../actions/modal";
import {ReactComponent as CheckIcon} from "../../../assets/images/check.svg";   


const FormEstadoCuenta = props =>{
    const [showConfirmation, setShowConfirmation] = useState(false);

    const initialState = {
        selectOption:{
            BLOQUEO_LOGIN: 'Bloqueo Login',
            ACTIVA: 'Activa',
            BLOQUEO_BACKOFFICE: 'Bloqueado backoffice',
            BLOQUEO_FACTURA: 'Bloqueo factura',
            INACTIVA:'Inactiva'
        },
        estadoCuenta: {
            idVendedor: props.idVendedor ? props.idVendedor : '',
            idOperador: props.authedUser.idOperador ? props.authedUser.idOperador : '',
            estado: props.estadoCuenta,
            comentario: ''
        },
        buttonDisabled: false,
        color: '#323980',
    }

    const [state, setState] = useState(initialState)

    const handleChange = e =>{
        setShowConfirmation(false);
        setState(prev=>{
            return{
                ...prev,
                estadoCuenta: {
                    ...prev.estadoCuenta,
                    [e.target.name]: e.target.value,
                }
            }
        })
    }

    const [ModificarEstadoCuentaVendedor, { data, loading, error, reset }] = useMutation(EDIT_ESTADO_VENDEDOR,{
        refetchQueries: [
            GET_VENDEDOR, // DocumentNode object parsed with gql
            'Get Vendedor' // Query name
        ],
    })

    const handleAceptarBtn = (e) => {
        e.preventDefault();
        setShowConfirmation(false);
        ModificarEstadoCuentaVendedor({
            variables:{
                estadoCuenta: {
                    ...state.estadoCuenta,
                }
            }
        }).then(r => {
            console.log('Rpta Edicion Estado', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edicion estado-> ' + reason);
            });
    };

    const handleCloseModal = ()=>{
        setShowConfirmation(false);
        props.dispatch(handleToggleModal())
        reset()
    }

    return(
        <Fragment>
            { error && (
                <div className="notification is-danger">
                    <button className="delete"> </button>
                    {error.message}
                </div>

            )}
            { !loading &&(
                !data && (
                    <form>
                        <div className='is-fullwidth'>
                            <label htmlFor="" className='mb-2'>Seleccionar estado:</label>
                            <div className="select is-fullwidth">
                                <select
                                    name='estado'
                                    value={state.estadoCuenta.estado}
                                    onChange={e=>handleChange(e)}
                                >
                                    <option value=''>Seleccion un estado</option>
                                    {Object.keys(state.selectOption).map(item=>
                                        (<option key={item} value={item}>{state.selectOption[item]}</option>)
                                    )}


                                </select>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <label htmlFor="">Agregar comentario:</label>
                            <div>
                            <textarea
                                name='comentario'
                                onChange={e=>handleChange(e)}
                                className="textarea"
                                placeholder="Agregar motivo del cambio..."
                                value={state.estadoCuenta.comentario}> </textarea>
                            </div>
                        </div>
                    </form>
                )
            )}
            { loading && (
                <div className='my-5 py-5'>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50}/>
                </div>
            )}
            {!data ? (
                showConfirmation ? (
                    <form action="" onSubmit={e => handleAceptarBtn(e)}>
                        <div className="a-center modal-delete">
                            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                            <p>¿Estás seguro de querer inactivar este vendedor? Este cambio no puede ser revertido</p>
                        </div>
                        <footer className="modal-card-foot">
                            <button className="button is-normal btn-eliminar" type="submit">
                                Confirmar
                            </button>
                            <button
                                onClick={handleCloseModal}
                                className="button btn-cancelar"
                                data-bulma-modal="close"
                            >
                                Cancelar
                            </button>
                        </footer>
                    </form>
                    ) : (
                    <footer className="modal-card-foot pb-0">
                        <button
                            onClick={e => {
                                if (state?.estadoCuenta.estado === 'INACTIVA') {
                                    setShowConfirmation(true);
                                } else {
                                    handleAceptarBtn(e);
                                }
                            }}
                            className={loading ? 'button is-normal btn-enviar is-loading' : 'button is-normal btn-enviar'}
                        >
                        Guardar
                        </button>
                        <button
                            onClick={handleCloseModal}
                            className="button btn-cancelar"
                            data-bulma-modal="close"
                        >
                        Cancelar
                        </button>
                    </footer>
                    )
                ) : (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon/>
                        </div>
                        <h6>Estado de cuenta editado correctamente</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={handleCloseModal}
                                className='button is-normal btn-enviar'
                                >
                                Aceptar
                            </button>
                        </footer>
                    </div>
                )
            }
        </Fragment>
    )
}

function mapStateToProps({modal, authedUser}){
    return{
        modal,
        authedUser,
    }
}
export default connect(mapStateToProps)(FormEstadoCuenta)