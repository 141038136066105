import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { sortData } from "../../utils/helpers";

function TableHeader(props) {

    const initialState = {

    }

    const [state, setState] = useState(initialState)

    const header = props.headers


    const handleOnClick = e => {
        e.preventDefault()

        props.onChange(e.currentTarget.id)

        // sortData(e.currentTarget.id)
    }

    /**
    *
    * */


    return (

        <thead>
            <tr className="table-header">
                {Object.keys(header).map(item => {
                    return (
                        <th className="al" key={item}>
                            <div className='is-inline-flex'>
                                <a href='/' className="btnSort" id={item} onClick={e => handleOnClick(e)}>

                                    <span className="is-uppercase">{header[item]}</span>
                                    <FontAwesomeIcon className='ml-2' icon={['fas', 'sort']} />
                                </a>
                            </div>
                        </th>)
                })}
            </tr>
        </thead>
    )
}

export default TableHeader;