import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
    mutation Login(
        $clave: String!
        $idTipoDocumento: TipoDocumentoHabilitado!
        $nroDocumento: String!
        $sistema: TipoSistema!
        $idDispositivo: String
    ) {
        login(
            clave: $clave
            idTipoDocumento: $idTipoDocumento
            nroDocumento: $nroDocumento
            sistema: $sistema
            idDispositivo: $idDispositivo
        ) {
            access
            refresh
        }
    }
`;


export const SET_SERVICIO = gql`
    mutation InsertarServicio($comision: ComisionInput!, $servicio: ServicioInput!) {
        insertarServicio(comision: $comision, servicio: $servicio)
    }
    
`;

export const EDIT_SERVICIO = gql`
    mutation EditarServicio($comision: ComisionInput!, $servicio: EditarServicioInput!, $servicioId: ID!) {
        editarServicio(comision: $comision, servicio: $servicio, servicioId: $servicioId)
    }
`;

export const DELETE_SERVICIO = gql`
    mutation EliminarServicio($servicioId: ID!) {
        eliminarServicio(servicioId: $servicioId)
    }
`;

export const SET_FACTURADOR = gql`
    mutation InsertarFacturador($facturador: FacturadorInput!) {
        insertarFacturador(facturador: $facturador)
    }
`;

export const EDIT_FACTURADOR = gql`
    mutation EditarFacturador($facturador: FacturadorInput!, $facturadorId: ID!) {
        editarFacturador(facturador: $facturador, facturadorId: $facturadorId)
    }
`;

export const DELETE_FACTURADOR = gql`
    mutation EliminarFacturador($facturadorId: ID!) {
        eliminarFacturador(facturadorId: $facturadorId)
    }
`;

export const SET_CATEGORIA = gql`
    mutation InsertarCategoria($categoria: CategoriaInput!) {
        insertarCategoria(categoria: $categoria)
    }
`;

export const EDIT_CATEGORIA = gql`
    mutation EditarCategoria($categoria: CategoriaInput!, $categoriaId: ID!) {
        editarCategoria(categoria: $categoria, categoriaId: $categoriaId)
    }
`;

export const DELETE_CATEGORIA = gql`
    mutation EliminarCategoria($categoriaId: ID!) {
        eliminarCategoria(categoriaId: $categoriaId)
    }
`;

export const EDIT_KYC_VENDEDOR = gql`
    mutation ModificarEstadoKYCVendedor($estadoKyc: EstadoKYCInput!) {
        modificarEstadoKYCVendedor(estadoKYC: $estadoKyc) {
            comentario
            estado
            extension
            fechaHora
        }
    }
`;

export const EDIT_ESTADO_VENDEDOR = gql`
    mutation ModificarEstadoCuentaVendedor($estadoCuenta: EstadoCuentaInput!) {
        modificarEstadoCuentaVendedor(estadoCuenta: $estadoCuenta) {
            comentario
            estado
            fechaHora
        }
    }
`;

export const DESVINCULAR_DISPOSITIVO = gql`
    mutation DesvincularDispositivo($idVendedor: ID!) {
        desvincularDispositivo(idVendedor: $idVendedor)
    }
`;

export const EDIT_CONTACTO_VENDEDOR = gql`
mutation ModificarPerfil($modificarPerfil: ModificarPerfilInput!) {
    modificarPerfil(modificarPerfil: $modificarPerfil) {
      contacto {
        fechaInsercion
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($token: JWT!) {
        refreshToken(token: $token)
    }
`;

export const LOG_OUT = gql`
    mutation Logout($token: JWT!) {
        logout(token: $token)
    }
`;

export const ACTUALIZAR_IMAGEN = gql`
mutation ActualizarImagenVendedor($idVendedor: ID!, $tipoArchivo: TipoArchivo!, $idOperador: ID!, $actualizarImagen: ActualizarImagen!) {
    actualizarImagenVendedor(idVendedor: $idVendedor, tipoArchivo: $tipoArchivo, idOperador: $idOperador, actualizarImagen: $actualizarImagen) {
      imagenes {
        urlImagen
        tipoImagen
        fechaActualizacion
        fechaInsercion
      }
    }
  }
`

export const INSERTAR_OPERADOR = gql`
    mutation Mutation($input: NuevoOperadorInput!) {
        insertarOperador(input: $input) {
        mensaje
        }
    }
`

export const EDITAR_OPERADOR = gql`
    mutation Mutation($input: ModificarOperadorInput!) {
        modificarOperador(input: $input) {
        exitoso
        mensaje
        }
    }
`
export const DISABLE_OPERADOR = gql`
mutation HabilitarOperador($input: HabilitarOperador!) {
    habilitarOperador(input: $input) {
      exitoso
      mensaje
    }
  }
`

export const RESET_USER_PASSWORD = gql`
mutation OlvideMiClaveOperador($nroDocumento: String!) {
    olvideMiClaveOperador(nroDocumento: $nroDocumento) {
      exitoso
      mensaje
    }
  }
`

export const CHANGE_PASSWORD_OPERADOR = gql`
mutation ChangePasswordOperador($data: ChangePasswordInput!) {
    changePasswordOperador(data: $data) {
      exitoso
      mensaje
    }
  }
`

export const UPDATE_DATA_VENDOR = gql`
    mutation ActualizarDatosVendedor(
        $idVendedor: ID!
        $fechaVencimientoCi: Date
    ) {
        actualizarDatosVendedor(
        idVendedor: $idVendedor
        fechaVencimientoCI: $fechaVencimientoCi
        ) {
        fechaVencimientoDocumento
        }
    }
`

export const UPDATE_DATA_PEP = gql`
mutation ActualizarDatosVendedor($idVendedor: ID!, $pep: Boolean) {
    actualizarDatosVendedor(idVendedor: $idVendedor, pep: $pep) {
      pep
    }
  }
`

export const INSERT_NEW_CONTENT = gql`
    mutation InsertarContenido($input: ContenidoInput!) {
        insertarContenido(input: $input)
    }
`

export const EDIT_DATA_CONTENT = gql`
    mutation ModificarContenido($input: ModificarContenidoInput!) {
        modificarContenido(input: $input)
    }
`

export const EDIT_DATA_REGLA = gql`
mutation ModificarRegla($input: ReglaInput!) {
    modificarRegla(input: $input)
  }
`


export const SEND_NOTIFICATION = gql`
mutation EnviarNotificaciones($input: NotificarInput!) {
    enviarNotificaciones(input: $input)
  }
`

export const UPLOAD_IVA = gql`
mutation SubirDeclaracionIva($idVendedor: ID!, $primeraDeclaracion: String!, $segundaDeclaracion: String!, $terceraDeclaracion: String!, $idOperador: ID) {
    subirDeclaracionIva(idVendedor: $idVendedor, primeraDeclaracion: $primeraDeclaracion, segundaDeclaracion: $segundaDeclaracion, terceraDeclaracion: $terceraDeclaracion, idOperador: $idOperador)
  }
`

export const CARGAR_CONSTANCIA_RUC = gql`
    mutation CargarConstanciaRuc(
        $idVendedor: ID!,
        $imagenConstancia: String!,
        $idOperador: String
        ) {
        cargarConstanciaRuc(
            idVendedor: $idVendedor,
            imagenConstancia: $imagenConstancia,
            idOperador: $idOperador
            ) {
            imagenes {
                tipoImagen
                urlImagen
                fechaInsercion
            }
        }
    }
`
