import React, { Fragment, useEffect, useState } from 'react'
import { sortData, takeData } from '../../utils/helpers';
import { saveAs } from 'file-saver';  // Import the file-saver library
import ExcelJS from 'exceljs';  // Import exceljs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import TableHeader from '../Vendedores/TableHeader';
import TableRow from './TableRow';
import { Pagination } from 'react-bulma-components';


const TableSearch = (props) => {

    //Declaramos variables a ser utilizadas
    const [state, setState] = useState({ sort: {}, currentPage: 1 })
    const { data, headers } = props
    const [order, setorder] = useState(1)
    let modo = 'asc'

    //ordenamos el listado mediante los filtros aplicados
    const listData = takeData(sortData(Object.keys(data).reverse().map(item => data[item]), state.sort), state.currentPage)

    //funcion para ordenar asc desc
    const onHandleChangeData = (data) => {
        if (data === 'registerFormat') {
            data = 'register'
        }
        if (data === 'modificacionFormat') {
            data = 'modificacion'
        }
        setorder(order + 1)
        if (order % 2 == 0) {
            modo = 'desc'
        } else {
            modo = 'asc'
        }

        let resetData = state.sort

        Object.keys(resetData).forEach(item => {

            resetData[item] = modo
        })
        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...resetData,
                    [data]: toogleSortData(data)
                }
            }
        })
    }

    //funcion para seatear icon de asc y desc y asignar el tipo de la misma 
    const toogleSortData = data => {
        if (state.sort[data] === 'asc') {
            return 'desc'
        } else {
            return 'asc'
        }
    }

    //funcion para cambiar de pagina y acceder a los datos de la misma
    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    useEffect(() => {
        let filtros = Object.keys(headers)

        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...filtros.reduce(function (target, key, index) {
                        target[key] = 'asc';
                        return target;
                    }, {})
                }
            }
        })
    }, [headers])

    // Function to export data to Excel
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Listado');

        const updatedHeaders = {
            id: 'id',
            ...headers,
        };

        // Add headers
        Object.keys(updatedHeaders).forEach((item, index) => {
            worksheet.getCell(1, index + 1).value = item;
        });
    
        // Add data rows with only columns from the headers
        Object.keys(props.data).forEach((item, rowIndex) => {
            Object.keys(updatedHeaders).forEach((headerKey, colIndex) => {
                const dataValue = props.data[item][headerKey]; // Get data value corresponding to the header
                worksheet.getCell(rowIndex + 2, colIndex + 1).value = dataValue;
            });
        });
    
        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Lista de vendedores.xlsx');
    };

    // para mostrar mensaje de no hay datos en caso de que no haya datos
    const lengData = listData ? listData?.length : 0

    const lengthData = listData ? listData.length : ''
    const fechaDesde = props.validateDesde ? props.validateDesde : false  // true
    const fechaHasta = props.validateHasta ? props.validateHasta : false  // false 
    const allowPagination = props?.allowPagination === true ? true : false // si es true es porque existe datos sino no
    const currentStep = props?.currentStep ? props?.currentStep : 1 // pagina actual
    const filters = props?.filters === true ? true : false

    // onchange pagination
    const handleChangeStep = data => {
        props.handleChangeStep(data)
    }

    /**
    * @param {*} allow Allow is there are available data to show
    * @param {*} currentPage Current page to show
    * @returns return the pagination steps
    * @Author Adán Vera
    */
    const renderDinamicSteps = (allow, currentPage, filters) => {

        // initialy show 3 pages 
        // if the current page is 3 then show 4 pages and so on 
        let pages = 2
        let steps = []

        for (let i = 1; i <= pages; i++) {
            steps.push(i)
        }

        // show only 2 pages from the current page initialy
        // then according to the current page show more pages +1
        // if the current page is 2 then show 2 pages and so on
        if (allow && currentPage >= 2) {
            steps = []
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                steps.push(i)
            }
        }
        // if allow is false then show the current page and 
        //not add more page only show the last 2 pages from the current page 
        if (!allow) {
            steps = []
            for (let i = currentPage - 2; i <= currentPage; i++) {
                steps.push(i)
            }
        }

        // add the prev and next values and pushed values to the steps array
        const prev = currentPage - 1
        const next = currentPage + 1
        steps.push('<')
        steps.push('>')

        // render the steps pagination
        return steps.map((item, index) => {
            if (item === '<') {
                return (
                    <>{prev > 0 &&
                        <div className="liside"><li key={prev} onClick={() => handleChangeStep(prev)} className={props.step === item ? 'is-active' : ''}><a>{'<'}</a></li> </div>
                    }{prev === 0 &&
                        <div className="liside"><li key={prev} className={props.step === item ? 'is-active' : 'notallowed'}><a>{'<'}</a></li></div>
                        }
                    </>
                )
            }
            if (item === '>') {
                return (
                    <>{allow &&
                        <div className="liside"><li key={next} onClick={() => handleChangeStep(next)} className={props.step === item ? 'is-active' : ''}><a>{'>'}</a></li></div>}
                        {!allow &&
                            <div className="liside"><li key={next} className={props.step === item ? 'is-active ' : 'notallowed'}><a>{'>'}</a></li></div>
                        }
                    </>
                )
            }
            if (item > 0) {
                return (<div className="liside"><li key={index} onClick={() => handleChangeStep(item)} className={props.step === item ? 'is-active' : ''}><a>{item}</a></li></div>)
            }
        })
    }



    return (
        <Fragment>
            {
                props?.link === '/vendedor/' &&
                <>
                    <div className="" id="itemExport">
                        <button
                            className="btnExport d-flex"
                            style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            cursor: 'pointer',
                            display: 'flex', // Add this to make the button a flex container
                            alignItems: 'center', 
                            marginLeft: '20px' // Center the content vertically
                            }}
                            onClick={exportToExcel}
                        >
                            <span
                                onMouseEnter={() => {
                                    // Change the color of both the icon and text
                                    document.getElementById('icon-export').style.color = 'black';
                                    document.getElementById('text-export').style.color = 'black';
                                }}
                                onMouseLeave={() => {
                                    // Change the color of both the icon and text back to default
                                    document.getElementById('icon-export').style.color = '#195bab';
                                    document.getElementById('text-export').style.color = '#195bab';
                                }}
                                >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        id="icon-export"
                                        style={{ fontSize: '15px', color: '#195bab', marginRight: '5px' }} // Removed marginLeft
                                    />
                                    <p
                                        id="text-export"
                                        style={{ fontSize: '16px', margin: 0, color: '#195bab' }}
                                    >
                                        Exportar
                                    </p>
                                </div>
                            </span>
                        </button>
                    </div>
                </>
            }
            <table className="table is-striped table-list has-text-left main-table">
                <TableHeader onChange={onHandleChangeData} headers={headers} />
                <tbody>
                    {
                        Object.keys(listData).map(item => {
                            return (
                                <TableRow link={props.link} key={listData[item].id} data={listData[item]} />
                            )
                        })
                    }
                </tbody>
            </table>
            {
                lengData <= 0 &&
                <div className="columns nodata">
                    <div className=' column d-f align'>
                        <p className='has-text-grey-light'>No hay datos para mostrar</p>
                    </div>
                </div>
            }
            {
                <section id='navvendorsection'>
                    <div className="columns">
                        <div className="column is-12" id='pageselection'>
                            {renderDinamicSteps(allowPagination, currentStep, filters)}
                        </div>
                    </div>
                </section>
            }
        </Fragment>
    )
}

export default TableSearch