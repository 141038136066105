import moment from "moment";
import React, { Fragment, useEffect, useState, Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { COMPROBANTE_VENTA } from "../../utils/queries/Queries";
import { BounceLoader } from "react-spinners";
import { override } from "../../utils/helpers";


const DescargaComprobanteVenta = (props) => {

    const initialState = {
        variables: {
            icono: 'add',
            nombre: ' ',
            color: '25,91,171',
            categoriaId: ' ',
        },
        loading: true,
        color: '#323980',
    }

    const [state, setState] = useState(initialState);

    const { search } = useLocation()

    const query = new URLSearchParams(search)
    // Se obtiene el identificador del detalle asociado a la transacción.
    const [detalle, setDetalle] = useState(query.get("detalle"));
    // Se obtiene el identificador del comprobante.
    const [comprobante, setComprobante] = useState(query.get("comprobante"))
    
    // Desestructuración del objeto retornado por el query.
    const {data, loading, error} = useQuery(COMPROBANTE_VENTA, {
        variables: {
            idDetalle: detalle.toString(),
            idComprobante: comprobante.toString()
        }
    })

    // Inicialización de variables que serán utilizadas en la siguiente condicion.
    // urlComprobante: guarda la url original o la url de reimpresión.
    // fechaHoraExpiracion: indica la fecha y la hora que expira el comprobante original.
    // fechaHoraActual: fecha y hora actual del sistema.
    // horasTranscurridas: Horas transcurridas entre la fechaHoraActual y la fechaHoraExpiracion
    let urlComprobante, fechaHoraExpiracion, fechaHoraActual, horasTranscurridas
  /*   if(data) {
        const {
            urlReimpresion, urlOriginal
        } = data.obtenerUrlComprobanteVenta

        if(urlOriginal != null) {
            // De la url original se obtiene la fecha de expiración.
            const cadenaInicial = urlOriginal.split('X-Goog-Date=')[1]
            // Se formatea la fecha obtenida.
            fechaHoraExpiracion = moment(cadenaInicial.substr(0, 16), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            // Se obtiene la hora actual del sistema
            fechaHoraActual = moment().format('YYYY-MM-DD HH:mm:ss')
            // Dias transcurridos   
            horasTranscurridas = moment(fechaHoraActual).diff(fechaHoraExpiracion, 'hours')
            
            if(horasTranscurridas > 24) {
                urlComprobante = urlReimpresion
            }
            else {
                urlComprobante = urlReimpresion
            }
            
            window.open(urlComprobante,"_self")
        }
        else {
            urlComprobante = urlReimpresion
        }
    } */

     useEffect(() => { 
        if(data){
            const {
                urlReimpresion, urlOriginal
            } = data.obtenerUrlComprobanteVenta
    
            if(urlOriginal != null) {
                // De la url original se obtiene la fecha de expiración.
                const cadenaInicial = urlOriginal.split('X-Goog-Date=')[1]
                // Se formatea la fecha obtenida.
                fechaHoraExpiracion = moment(cadenaInicial.substr(0, 16), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                // Se obtiene la hora actual del sistema
                fechaHoraActual = moment().format('YYYY-MM-DD HH:mm:ss')
                // Dias transcurridos   
                horasTranscurridas = moment(fechaHoraActual).diff(fechaHoraExpiracion, 'hours')
                
                if(horasTranscurridas > 24) {
                    urlComprobante = urlReimpresion
                }
                else {
                    urlComprobante = urlOriginal
                }
            }
            else {
                urlComprobante = urlReimpresion
            }
            window.open(urlComprobante,"_self")
        }
     }, [data])

    if (data) {
            return (  
                <div className='visualizarComprobante' style={{position:'absolute', width:'100%', height:'100%'}}> 
                  {/*   <object 
                        data={urlComprobante}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                    >
                    </object> */}
                </div>
            )
    } 
    else {
        return (
            <>
                <div className="data-confirm-mail">
                    <div className="Confirm-data">
                        <div className='my-5 py-5'>
                            <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                        </div>
                        <h1> Cargando...</h1>
                    </div>
                </div>

            </>
        )
    }

}

export default DescargaComprobanteVenta