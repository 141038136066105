import React, { useState } from "react";
import Navigation from "../common/navigation";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import FiltroTable from "../Servicios/FiltroTable";
import SearchTable from "../common/SearchTable";
import ServiciosTable from "./ServiciosTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalContainer from "../common/ModalContainer";
import FormInsertarServicio from "./FormInsertarServicio";
import { handleToggleModal } from "../../actions/modal";
import ProtectedComponent from "../common/ProtectedComponent";


const Servicios = (props) => {


    //decalramos las variables a ser utlizadas
    const initialState = {
        vendors: [],
        filtros: {
            categoria_name: '',
            facturador_name: '',
            red: 'TODAS',
            busqueda: '',
            servicio_name: '',
            idServicioRed: ''
        },
        loading: true,
        color: '#323980',

    }

    //inicializamos las variables declaradas
    const [state, setState] = useState(initialState);

    const handleShowModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                modalStatus: !prevState.modalStatus
            }
        })
    }

    const onHandleToggleModal = () => {
        setState(prev => ({
            modalStatus: !prev.modalStatus,
        }))
        props.dispatch(handleToggleModal())
    }

    const dashboardRef = React.createRef();

    const formServicio = () => {
        return <FormInsertarServicio handleShowModal={handleShowModal} />
    }

    //funcion para inicializar nuevamente con los valores inicales el filtro
    const onCleanFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...data,
                }
            }
        })
    }

    //funcion para setear y pasar que filtro se selecciono
    const handleFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...prevState.filtros,
                    [data.key]: data.value
                }
            }
        })
    }

    //onchange para buscar
    const handleSearch = data => {

        if (isNaN(data)) {
            setState(prev => ({
                ...prev,
                filtros: {
                    ...prev.filtros,
                    servicio_name: data,
                }
            }))
        } else {
            setState(prev => ({
                ...prev,
                filtros: {
                    ...prev.filtros,
                    idServicioRed: data,
                }
            }))

        }

    }


    const { sidebarStatus, modal } = props;

    return (
        <div className="dashboard" ref={dashboardRef}>

            {modal.modalStatus && (
                <ModalContainer
                    title='SERVICIO'
                    form={formServicio()}
                    modalStatus={state.modalStatus}
                    handleShowModal={handleShowModal} />
            )}


            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    <section
                        className={
                            sidebarStatus === 'open'
                                ? 'section content-box pd-un'
                                : 'section content-box pd-un content-box-expand'}
                        id="cont-box">
                        <div className="columns">
                            <div className="column is-3  text-al-ini">
                                <h1 className="title-details ml-5 pt-3">Lista de Servicios</h1>

                            </div>
                            <div className="column is-6">  <SearchTable
                                placeholder='Buscar un Servicio...'
                                handleChange={handleSearch}

                            /></div>
                            <div className="column is-3 t-al-end">

                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR', 'OPERADOR']}>
                                    <button
                                        className="button btn-primary"
                                        onClick={() => onHandleToggleModal()}
                                    >
                                        <FontAwesomeIcon className='mr-2' icon={['fas', 'plus']} color='#FFF' />
                                        Añadir Servicio
                                    </button>
                                </ProtectedComponent>

                            </div>
                        </div>
                        <div className="columns">
                            <div className="column py-0">
                                <hr className='mt-0' />
                            </div>
                        </div>
                        <FiltroTable onCleanFilter={onCleanFilter} getFilter={handleFilter} />
                        <div className="columns box-list">
                            <div className="column is-full">
                                <ServiciosTable filtros={state.filtros} />
                            </div>
                        </div>
                    </section>
                </div>
            </main>

        </div>
    )

}

function mapStateToProps({ authedUser, modal, sidebarStatus }) {
    return {
        authedUser,
        sidebarStatus,
        modal
    }
}

export default connect(mapStateToProps)(Servicios)
