import React, { useState } from 'react'
import Navigation from "../common/navigation";
import SearchTable from "../common/SearchTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Pagination from "../common/Pagination";
import FacturadoresTable from "./FacturadoresTable";
import ModalContainer from "../common/ModalContainer";
import FormInsertFacturador from "./FormInsertFacturador";
import { handleToggleModal } from "../../actions/modal";
import ProtectedComponent from "../common/ProtectedComponent";


const Facturadores = (props) => {

    const [state, setState] = useState({
        filtros: {

        }
    });
    const handleSearch = data => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...prev.filtros,
                nombre: data,
            }
        }))
    }


    const onHandleToggleModal = () => {
        // setState(prev=>({
        //     modalStatus: !prev.modalStatus,
        // }))
        props.dispatch(handleToggleModal())
    }

    const formFacturadores = (test) => {
        return <FormInsertFacturador test={test} />
    }

    const { sidebarStatus } = props

    return (

        <div className='facturadores-dashboard'>
            {props.modal.modalStatus && (
                <ModalContainer
                    title='FACTURADOR'
                    form={formFacturadores()}
                />
            )}

            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    <section className="section content-box pd-un">
                        <div className="columns">
                            <div className='column is-3 text-al-ini'>
                                <h1 className="title-details ml-5 pt-3">Lista de facturadores</h1>
                            </div>
                            <div className='column is-6'>
                                <SearchTable
                                    placeholder='Buscar un facturador...'
                                    handleChange={handleSearch}

                                />
                            </div>
                            <div className="column is-3 t-al-end">
                                {props.modal.modalStatus}
                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR']}>
                                    <button
                                        className="button btn-primary"
                                        onClick={() => onHandleToggleModal()}
                                    >
                                        <FontAwesomeIcon className='mr-2' icon={['fas', 'plus']} color='#FFF' />
                                        Añadir facturador
                                    </button>
                                </ProtectedComponent>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="column is-full">
                                    <FacturadoresTable filtros={state.filtros} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>

    )

}

function mapStateToProps({ authedUser, sidebarStatus, modal }) {
    return {
        authedUser,
        sidebarStatus,
        modal
    }
}
export default connect(mapStateToProps)(Facturadores);
