import React from 'react'
import {useQuery} from "@apollo/client";
import {GET_CATEGORIAS} from "../../utils/queries/Queries";
import {formatDataCategory} from "../../utils/helpers";

const SelectCategoria = (props) =>{
    
    const { loading, data, error } = useQuery(GET_CATEGORIAS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    const categoryList = formatDataCategory(data.categorias)
    // console.log(categoryList)
    return(
        <div className="field">
            <label htmlFor="">Categoría:</label>
            <div className="control">
                <div className='select is-fullwidth'>
                    {/*<input className="input pl-2" type="text" placeholder="Seleccione categoria para el facturador" />*/}
                    <select
                        name="categoriaId"
                        onChange={(e)=>props.handleChange(e)}
                        value={props.defaultValue ? props.defaultValue : ''}
                    >
                        <option value='' disabled> Seleccione una categoría </option>
                        {Object.keys(categoryList).map(item=>{
                            return(
                                <option key={item} value={categoryList[item].id}>{categoryList[item].nombre} - {categoryList[item].id} </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default SelectCategoria;