import React, { Fragment, useEffect, useState } from 'react'
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import { BounceLoader } from "react-spinners";
import SelectFacturador from "../common/Selects/SelectFacturador";
import { EDIT_SERVICIO, SET_SERVICIO, DELETE_SERVICIO } from "../../utils/mutators/Mutators";
import { GET_SERVICIOS } from "../../utils/queries/Queries";
import { connect } from "react-redux";
import SelectServicioRed from "../common/Selects/SelectServicioRed";
import { override } from "../../utils/helpers";
import { handleToggleModal } from "../../actions/modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';

const FormInsertarServicio = (props) => {

    // todo: Al cambiar de RED se debe poner a vacio el campo idServicioRed
    // todo: Los selects deben tener estado default value
    const initialState = {
        variables: {
            servicio: {
                nombre: "",
                operadorId: props.authedUser.idOperador,
                red: "PE",
                idServicioRed: "",
                facturadorId: "",
                diligenciaAmpliada: false,
                habilitado: true
            },
            comision: {
                empresa: 0,
                vendedor: 100
            }
        },
        predefinido: '',
        servicioId: '',
        categoria_name: '',
        loading: true,
        color: '#323980',
        modalType: '',
    };

    const [errorExist, setErrorExist] = useState(false)
    const [validateName, setvalidateName] = useState(false)
    const [validateRed, setvalidateRed] = useState(false)
    const [validateFact, setvalidateFact] = useState(false)
    const [editMade, seteditMade] = useState(false)


    // Variables: Necesarios para la insercción de la mutación de Servicios
    const [state, setState] = useState(initialState);

    // todo: Considerar deprecar esta constante
    /*const options = {
        variables: {
            ...state.variables,
            comision: {
                empresa: parseFloat(state.variables.comision.empresa),
                vendedor: parseFloat(state.variables.comision.vendedor)
            }
        }
    }*/

    const handleChange = (e) => {

        console.log("target", e.target.name)

        if (e.target.name === "nombre") setvalidateName(true)

        if (e.target.name === 'vendedor' || e.target.name === 'empresa') {

            const propName = e.target.name
            const propVal = e.target.value
            const auxName = propName === 'empresa' ? 'vendedor' : 'empresa'
            if (propVal <= 100 && propVal >= 0) {
                setState(prevState => {
                    const updatedValues = {
                        ...prevState,
                        variables: {
                            ...prevState.variables,
                            comision: {
                                [propName]: propVal,
                                [auxName]: 100 - propVal
                            },
                        }
                    }
                    // console.log('check variables state: ', updatedValues)
                    return { ...updatedValues };
                });
            }


        } else {
            setState(prevState => {
                const updatedValues = {
                    ...prevState,
                    variables: {
                        ...prevState.variables,
                        servicio: {
                            ...prevState.variables.servicio,
                            [e.target.name]: e.target.value,
                        },
                    }
                }
                // console.log('check variables state: ', updatedValues)
                return { ...updatedValues };
            });

        }

    }
    const onHandleChangeDeligencia = (e) => {
        e.preventDefault()
        setState(state => (

            {
                ...state,
                variables: {
                    ...state.variables,
                    servicio: {
                        ...state.variables.servicio,
                        diligenciaAmpliada: e.target.value
                    }

                }
            }
        ))

    }

    const onHandleChangeHabilitado = (e) => {
        e.preventDefault()
        setState(state => (

            {
                ...state,
                variables: {
                    ...state.variables,
                    servicio: {
                        ...state.variables.servicio,
                        habilitado: e.target.value
                    }

                }
            }
        ))

    }

    const handleChangeSelect = (data) => {

        //todo: validar aqui si es que viene del facturador o del selector de servicio de la red
        if (data.__typename === 'ServicioRed') {
            setvalidateRed(true)
            setState(prevState => {
                const updatedValues = {
                    ...prevState,
                    variables: {
                        ...prevState.variables,
                        servicio: {
                            ...prevState.variables.servicio,
                            idServicioRed: data.id,
                        }
                    },
                }
                console.log('check variables state: ', updatedValues)
                return { ...updatedValues };
            });
        } else {
            setState(prevState => {
                setvalidateFact(true)
                console.log('check handle. ', data)
                const updatedValues = {
                    ...prevState,
                    variables: {
                        ...prevState.variables,
                        servicio: {
                            ...prevState.variables.servicio,
                            facturadorId: data.id,
                        }
                    },
                    categoria_name: data.categoria,
                }
                console.log('check variables state: ', updatedValues)
                return { ...updatedValues };
            });
        }

    }

    const handleAceptarBtn = (e) => {
        e.preventDefault()
        setState(() => {
            return { ...initialState }
        })
        props.dispatch(handleToggleModal())
        resetInsert()
        resetDelete()
    }

    const reFetch = {
        refetchQueries: [
            GET_SERVICIOS, // DocumentNode object parsed with gql
            'Servicios' // Query name
        ],
    }

    const client = useApolloClient()

    const handleEditMode = () => {
        seteditMade(true);
        const servicioData = client.readFragment({
            id: `Servicio:${props.modal.data}`, // The value of the to-do item's cache ID
            fragment: gql`
                fragment servicio on Servicio {
                    id
                    nombre
                    habilitado
                    idServicioRed
                    red
                    habilitado  
                    diligenciaAmpliada
                    facturador {
                        id
                        nombre
                        categoria {
                            id
                            nombre
                            icono
                        }
                    }
                    comision {
                        id
                        empresa
                        vendedor
                    }
                }
            `,
        });

        setState(prev => {

            return {
                ...prev,
                variables: {
                    ...prev.variables,
                    servicio: {
                        ...prev.variables.servicio,
                        nombre: servicioData.nombre,
                        idServicioRed: servicioData.idServicioRed,
                        red: servicioData.red,
                        facturadorId: servicioData.facturador.id,
                        diligenciaAmpliada: servicioData.diligenciaAmpliada ? "true" : "false",
                        habilitado: servicioData.habilitado ? "true" : "false"
                    },
                    comision: {
                        empresa: servicioData.comision ? parseFloat(servicioData.comision.empresa) : '',
                        vendedor: servicioData.comision ? parseFloat(servicioData.comision.vendedor) : '',
                    },
                },
                servicioId: servicioData.id,
                categoria_name: servicioData.facturador.categoria.nombre,
            }
        })
    }

    useEffect(() => {
        if (props.modal.type === 'edit') {
            handleEditMode()
        }
        else if (props.modal.type === 'delete') {
            setState(prevState => {
                return {
                    ...prevState,
                    modalType: 'delete'

                }
            })
        }
        // eslint-disable-next-line
    }, [])

    const [InsertarServicio, {
        data: dataInsert,
        loading: loadingInsert,
        error: errorInsert,
        reset: resetInsert
    }] = useMutation(SET_SERVICIO, reFetch);

    const [EditarServicio, {
        data: dataEdit,
        loading: loadingEdit,
        // error: errorEdit,
        // reset: resetEdit
    }] = useMutation(EDIT_SERVICIO, reFetch);

    const [EliminarServicio, {
        data: dataDelete,
        loading: loadingDelete,
        error: errorDelete,
        reset: resetDelete
    }] = useMutation(DELETE_SERVICIO, reFetch);

    // Funcion para encapsular logica
    const handleInsert = (e) => {
        console.log('checking options before insert ', { ...state.variables })
        InsertarServicio({
            variables: {
                comision: {
                    empresa: parseFloat(state.variables.comision.empresa),
                    vendedor: parseFloat(state.variables.comision.vendedor)
                },
                servicio: {
                    nombre: state.variables.servicio.nombre,
                    facturadorId: state.variables.servicio.facturadorId,
                    predefinido: false,
                    idServicioRed: state.variables.servicio.idServicioRed,
                    red: state.variables.servicio.red,
                    operadorId: state.variables.servicio.operadorId,
                    diligenciaAmpliada: state.variables.servicio.diligenciaAmpliada == "true" ? true : false
                },
            }
        }).then(r => {
            console.log('capturando respuesta de insercion servicios', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error Insertando Servicios-> ' + reason);
                setErrorExist(true)
            });
    }

    const handleEdit = (e) => {
        //todo: parse float comisiones
        EditarServicio({
            variables: {
                comision: {
                    empresa: parseFloat(state.variables.comision.empresa),
                    vendedor: parseFloat(state.variables.comision.vendedor)
                },
                servicio: {
                    nombre: state.variables.servicio.nombre,
                    facturadorId: state.variables.servicio.facturadorId,
                    predefinido: false,
                    habilitado: state.variables.servicio.habilitado == "true" ? true : false,
                    diligenciaAmpliada: state.variables.servicio.diligenciaAmpliada == "true" ? true : false
                },
                servicioId: state.servicioId
            }
        }).then(r => {
            console.log('capturando respuesta de insercion servicios', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error editando Servicios-> ' + reason);
            });
        
    }

    // toats for case of success or error
    const errorDel = (reason) => toast.error('Ocurrio un error al eliminar: ' + JSON.stringify(reason.message));
    const successDel = (r) => toast.success('Servicio eliminado exitosamente.')

    const handleDelete = (e) => {
        props.dispatch(handleToggleModal())
        EliminarServicio({
            variables: {
                servicioId: props.modal.data,
            }
        }).then(r => {
            console.log('capturando respuesta de borrado de  servicio', r)
            resetDelete()
            successDel(r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                errorDel(reason)
                console.error('Error al eliminar servicio -> ' + reason);
            });
    }

    //Dependiendo del tipo de modal entra en la concional
    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.modal.type === 'edit') {
            handleEdit()
        }
        if (props.modal.type === 'delete') {
            handleDelete()
        }
        else if (props.modal.type !== 'edit') {
            handleInsert()
        }
    }

    const data = !!(dataInsert || dataEdit || dataDelete)

    const loading = !!(loadingInsert || loadingEdit || loadingDelete)
    if (state.modalType !== "delete") {
        return (
            <Fragment>
                {(errorInsert && errorExist) ? (
                    <div className="notification is-danger">
                        <button className="delete" onClick={() => setErrorExist(false)}> </button><strong>{errorInsert.message}</strong>
                    </div>
                ) : ("")}
                {loading && (
                    <div className="my-5 py-5">
                        <BounceLoader
                            color={state.color}
                            loading={state.loading}
                            css={override}
                            size={50}
                        />
                    </div>
                )}
                {!loading && !data && (
                    <form
                        action=""
                        onSubmit={(e) => {
                            handleSubmit(e)
                        }}
                    >
                        <div className="field">
                            <label className="label">
                                Denominación del servicio:
                            </label>
                            <div className="control has-icons-left has-icons-right">
                                <input
                                    name="nombre"
                                    className="input pl-2"
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    value={state.variables.servicio.nombre}
                                    placeholder="Ingresar descripción..."
                                />
                                <small className="comment-text">
                                    Nombre que se verá en la aplicación.
                                </small>
                            </div>
                        </div>

                        <SelectFacturador
                            handleChange={handleChangeSelect}
                            facturador={state.variables.servicio.facturadorId}
                        />

                        <div className="field">
                            <label className="label">
                                Categoría del Facturador:
                            </label>
                            <div className="control has-icons-left has-icons-right">
                                <input
                                    disabled={true}
                                    name="categoria"
                                    className="input pl-2"
                                    type="text"
                                    value={state.categoria_name}
                                />
                                <small className="comment-text">
                                    Categoria al que pertenece el facturador
                                </small>
                            </div>
                        </div>


                        <div className="columns">
                            <div className="column is-one-third">
                                <div className="field">
                                    <label className="label">Red:</label>
                                    <div className="control has-icons-left has-icons-right">
                                        <div className="select">
                                            <select
                                                defaultValue={
                                                    state.variables.servicio.red
                                                }
                                                name="red"
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            >
                                                <option value="">
                                                    Seleccione Red
                                                </option>
                                                <option value="PE">
                                                    Pago Express
                                                </option>
                                                <option value="AP">
                                                    Aquí Pago
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <SelectServicioRed
                                    red={state.variables.servicio.red}
                                    idServicioRed={
                                        state.variables.servicio.idServicioRed
                                    }
                                    handleChange={handleChangeSelect}
                                />
                            </div>
                        </div>
                        {props.modal.type === "edit" ? (
                            <>
                                <div className="columns">
                                    <div className="column">
                                        <h5 className="subtitle is-bold mb-2">
                                            Comisiones
                                        </h5>
                                        <hr className="my-0" />
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                Red Móvil:
                                            </label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input
                                                    name="empresa"
                                                    disabled={true}
                                                    className="input pl-2"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    value={
                                                        state.variables.comision
                                                            .empresa
                                                    }
                                                    placeholder="Ingresar monto..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Vendedor:</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input
                                                    name="vendedor"
                                                    disabled={true}
                                                    className="input pl-2"
                                                    type="number"
                                                    max="100"
                                                    min="0"
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    value={
                                                        state.variables.comision
                                                            .vendedor
                                                    }
                                                    placeholder="Ingresar monto..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <label className="label">
                                            Control de umbrales
                                        </label>
                                        <div className="control has-icons-left has-icons-right">
                                            <div className="select">
                                                <select
                                                    value={state.variables.servicio.diligenciaAmpliada}
                                                    onChange={(e) =>
                                                        onHandleChangeDeligencia(e)
                                                    }
                                                    name="diligenciaAmpliada"
                                                >
                                                    <option disabled>
                                                        Seleccione ...
                                                    </option>
                                                    <option value={true}>
                                                        SI
                                                    </option>
                                                    <option value={false}>
                                                        NO
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <label className="label">
                                            Estado de servicio
                                        </label>
                                        <div className="control has-icons-left has-icons-right">
                                            <div className="select">
                                                <select
                                                    value={state.variables.servicio.habilitado}
                                                    onChange={(e) =>
                                                        onHandleChangeHabilitado(e)
                                                    }
                                                    name="habilitado"
                                                >
                                                    <option disabled>
                                                        Seleccione ...
                                                    </option>
                                                    <option value={true}>
                                                        ACTIVO
                                                    </option>
                                                    <option value={false}>
                                                        INACTIVO
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="columns">
                                    <div className="column">
                                        <h5 className="subtitle is-bold mb-2">
                                            Comisiones
                                        </h5>
                                        <hr className="my-0" />
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                Bomo:
                                            </label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input
                                                    disabled="true"
                                                    name="empresa"
                                                    className="input pl-2"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    value={
                                                        state.variables.comision
                                                            .empresa
                                                    }
                                                    placeholder="Ingresar monto..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Vendedor:</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input
                                                    disabled="true"
                                                    name="vendedor"
                                                    className="input pl-2"
                                                    type="number"
                                                    max="100"
                                                    min="0"
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    value={
                                                        state.variables.comision
                                                            .vendedor
                                                    }
                                                    placeholder="Ingresar monto..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                        <label className="label">
                                            Control de umbrales
                                        </label>
                                        <div className="control has-icons-left has-icons-right">
                                            <div className="select">
                                                <select
                                                    value={state.variables.servicio.diligenciaAmpliada}
                                                    onChange={(e) =>
                                                        onHandleChangeDeligencia(e)
                                                    }
                                                    name="diligenciaAmpliada"
                                                >
                                                    <option disabled>
                                                        Seleccione ...
                                                    </option>
                                                    <option value={true}>
                                                        SI
                                                    </option>
                                                    <option value={false}>
                                                        NO
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                            </>
                        )}

                        <footer className="modal-card-foot">
                            <button className={`button is-normal ${!((validateFact && validateName && validateRed) || editMade) ? 'btn-disable' : 'btn-enviar'}`} disabled={!((validateFact && validateName && validateRed) || editMade)}>Guardar</button>

                            <button onClick={(e) => handleAceptarBtn(e)} className="button btn-cancelar" data-bulma-modal="close" >Cancelar</button>
                        </footer>
                    </form>
                )}
                {data && (
                    <div className="has-text-centered">
                        <div>
                            <CheckIcon />
                        </div>
                        {props.modal.type === "edit" ? (<h6>Servicio modificado exitosamente</h6>) : (<h6>Servicio creado exitosamente</h6>)}
                        <button
                            onClick={(e) => handleAceptarBtn(e)}
                            className="mt-5 button is-normal btn-enviar"
                            data-bulma-modal="close"
                        >
                            Aceptar
                        </button>
                    </div>
                )}
            </Fragment>
        )
    } else {
        return (
            <>
                <Fragment>
                    {errorDelete ? (
                        <div className="notification is-danger">
                            <button className="delete"> </button>
                            <strong>{errorDelete.message}</strong>
                        </div>
                    ) : (
                        ""
                    )}

                    {loading && (
                        <div className="my-5 py-5">
                            <BounceLoader
                                color={state.color}
                                loading={state.loading}
                                css={override}
                                size={50}
                            />
                        </div>
                    )}

                    {!loading && !data && (
                        <form
                            action=""
                            onSubmit={(e) => {
                                handleSubmit(e)
                            }}
                        >
                            <div className="a-center modal-delete">
                                <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                                <p>¿Estas seguro de eliminar?</p>
                            </div>
                            <footer className="modal-card-foot">
                                <button className="button is-normal btn-eliminar">
                                    Eliminar
                                </button>
                                <button
                                    onClick={(e) => handleAceptarBtn(e)}
                                    className="button btn-cancelar"
                                    data-bulma-modal="close"
                                >
                                    Cancelar
                                </button>
                            </footer>
                        </form>
                    )}
                    {data && (
                        <div className="has-text-centered">
                            <div>
                                <CheckIcon />
                            </div>
                            <h6>Se elimino correctamente</h6>
                            <button
                                onClick={(e) => handleAceptarBtn(e)}
                                className="mt-5 button is-normal btn-enviar"
                                data-bulma-modal="close"
                            >
                                Aceptar
                            </button>
                        </div>
                    )}
                </Fragment>
            </>
        )
    }
}

function mapStateToProps({ authedUser, modal }) {
    return {
        authedUser,
        modal
    }
}

export default connect(mapStateToProps)(FormInsertarServicio);

