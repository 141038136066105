import './App.css'
import 'bulma/css/bulma.css'
import './assets/css/custom.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import SyncLoader from "react-spinners/SyncLoader";

import React, { Fragment, useEffect, useState } from "react";
import { handleInitialData } from './actions/shared'
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from "./Components/Login/Login"
import VerificarCorreoVendedor from "./Components/VerificarCorreoVendedor/VerificarCorreoVendedor"
import Vendedores from "./Components/Vendedores/Vendedores"
import Usuarios from './Components/Usuarios/Usuarios'

import { connect } from 'react-redux'
import Categorias from "./Components/Categorias/Categorias";
import Facturadores from "./Components/Facturadores/Facturadores";
import Servicios from "./Components/Servicios/Servicios";
import Sidebar from "./Components/common/Sidebar";
import VendedorDetalle from "./Components/VendedorDetalle/VendedorDetalle";
import ProtectedRoute from "./Components/common/ProtectedRoute";
import NoRoute from "./Components/common/NoRoute";
import { override } from './utils/helpers'
import Notificaciones from './Components/Notificaciones/Notificaciones'
import DescargaComprobanteVenta from './Components/Comprobante/DescargaComprobanteVenta'


import ResetPassword from './Components/Login/ResetPassword'
import ChangePass from './Components/Usuarios/ChangePass'
import ResetPasswordToLogin from './Components/Login/ResetPasswordToLogin'
import VendedorDetalleInforme from './Components/VendedorDetalle/VendedorDetalleInforme'

const App = (props) => {

    const [state, setState] = useState({})
    const { sidebarStatus, authedUser } = props
    const { pathname } = useLocation();

    const color = '#323980'
    const loading = true

    //capturamos el rol del usuario logueado
    const userAuthedRol = props?.authedUser?.rol

    useEffect(() => {
        props.dispatch(handleInitialData())

        setState(prev => ({
            authedUser: authedUser ? authedUser : JSON.parse(localStorage.getItem('payload')),
        }))
        // eslint-disable-next-line
    }, [])

    library.add(fab, fas)

    function routesByRol() {
        if (userAuthedRol === 'ADMINISTRADOR') {
            return (
                <>
                    <Route path="dashboard" element={<Vendedores />} />
                    <Route exact path='servicios' element={<Servicios />} />
                    <Route exact path='categorias' element={<Categorias />} />
                    <Route exact path='facturadores' element={<Facturadores />} />
                    <Route path="notificaciones" element={<Notificaciones />} />
                    <Route exact path='/vendedor/:id' element={<VendedorDetalle />} />

                </>
            )
        } else if ((userAuthedRol === 'CUMPLIMIENTO') || (userAuthedRol === 'OPERADOR')) {
            return (
                <>
                    <Route path="dashboard" element={<Vendedores />} />
                    <Route exact path='/vendedor/:id' element={<VendedorDetalle />} />
                </>
            )
        }
        if ((userAuthedRol === 'SEGURIDAD')) {
            return (<Route path="usuarios" element={<Usuarios />} />)
        }
        if ((userAuthedRol === 'MARKETING')) {
            return (<Route path="notificaciones" element={<Notificaciones />} />)
        }
    }


    return (

        <div className="App">
            {
                !['/verificacion/correo', '/login', '/ResetPassword', '/comprobante_venta/c', '/ResetPasswordToLogin'].includes(pathname) ? (
                    <Sidebar sidebarStatus={sidebarStatus} />
                ) : ''
            }
            <Routes>
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/ResetPassword' element={<ResetPassword />} />
                <Route exact path='/verificacion/correo' element={<VerificarCorreoVendedor />} />
                <Route exact path='/comprobante_venta/c' element={<DescargaComprobanteVenta />} />
                <Route exact path='/ResetPasswordToLogin' element={<ResetPasswordToLogin />} />
                <Route exact path='/Ajustes' element={<ChangePass />} />
                <Route exact path='/vendedor/:id/informe/' element={<VendedorDetalleInforme />} />

                <Route element={<ProtectedRoute user={state.authedUser} />}>
                    <Fragment>
                        {routesByRol()}
                    </Fragment>
                </Route>
                <Route exact path='/' element={<NoRoute />} />
                <Route
                    path="*"
                    element={
                        <main className={sidebarStatus}>
                            <div id='cont-box' className={
                                (sidebarStatus === 'open')
                                    ? 'container is-fluid main-content'
                                    : 'container is-fluid main-content main-content-expand'
                            }>
                                <section className={sidebarStatus === 'open' ? 'section content-box pd-un' : 'section content-box pd-un content-box-expand'} id="cont-box">
                                    <div className='main-inde-c'></div>
                                </section>
                            </div>
                        </main>
                    }
                />
            </Routes>
        </div>
    );
}


function mapStateToProps({ authedUser, sidebarStatus }) {
    return {
        loading: authedUser === null,
        authedUser,
        sidebarStatus,
    }
}

export default connect(mapStateToProps)(App)
