import React, {Fragment} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMutation} from "@apollo/client";
import { DELETE_SERVICIO } from "../../utils/mutators/Mutators";
import { GET_SERVICIOS } from "../../utils/queries/Queries";
import {connect} from "react-redux";
import {handleToggleModal} from "../../actions/modal";
import toast, { Toaster } from 'react-hot-toast';

const ActionsServiciosList = (props)=>{

    // props.dataID
    const [EliminarServicio, { loading }] = useMutation(DELETE_SERVICIO, {
        variables: {
            servicioId: props.dataID,
        },
        refetchQueries: [
            GET_SERVICIOS, // DocumentNode object parsed with gql
            'Categorias' // Query name
        ],
    });


    const handleOnClick = (e, btn)=>{
        e.preventDefault()

        if(btn === '_btnDelete'){
            // se carga el obj options con los datos obtenidos del form

            props.dispatch(handleToggleModal('delete', props.dataID))

            /**Se hace la funcion de eliminar el servicio en FormInsertarServicio */

           /*  EliminarServicio().then(r =>{
                successDel(r)
                console.log('capturando respuesta de borrado de  servicio', r)
            }).catch(
                // Registrar la razón del rechazo
                function(reason) {
                    errorDel(reason)
                    console.error('Error login-> '+reason);
                }); */
        }
        if(btn === '_btnEdit') {
            props.dispatch(handleToggleModal('edit', props.dataID));
        }
    }

    return(
        <Fragment>
            <button id='_btnEdit' className='button is-link is-inverted' onClick={(e)=>handleOnClick(e, '_btnEdit')}>
                <FontAwesomeIcon icon={['fas', 'pen']}   />
            </button>
            <button id='_btnDelete'
                    className={loading ? 'button is-loading' : 'button is-danger is-inverted '}
                    onClick={(e)=>handleOnClick(e, '_btnDelete')}>
                <FontAwesomeIcon icon={['fas', 'times']}   />
            </button>
            <Toaster position="top-right" toastOptions={{
                className: '',
                style: {
                    border: '1px solid rgba(85, 82, 217, 0.2)',
                    padding: '16px',
                    color: '#713200',
                },
            }} />
        </Fragment>
    )
}

function mapStateToProps({modal}){
    return{
        modal,
    }
}

export default connect(mapStateToProps)(ActionsServiciosList)