import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from "@apollo/client";
import { GET_FACTURADORES } from "../../utils/queries/Queries";
import {
    filterDataList,
    filterDataListServicio,
    formatDataCategory,
    formatDataFacturadores,
    override, takeData, takeDataSearch
} from "../../utils/helpers";
import Table from "../common/Table";
import { PuffLoader } from "react-spinners";
import { Pagination } from 'react-bulma-components';
import TableSearch from '../common/TableSearch';


const FacturadoresTable = (props) => {

    //declaramos las variables que utilizaremos
    const [state, setState] = useState(
        {
            filtros: {
                nombre: '',
            },
            headers: {
            },
            loading: true,
            color: '#323980',
            currentPage: 1,
        }
    );
    // Headers de la tabla
    const headers = {
        facturador: 'Facturadores',
        categoria: 'Categorias',
        icon: 'Icono',
        actions: 'Acciones',
    }

    //seteamos los filtros a ser utlizamos
    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))
    }, [props.filtros])

    const { loading, error, data } = useQuery(GET_FACTURADORES);

    if (error) {
        console.log('Testing ERROR: ', typeof error)
        return `Error! ${error.message}`
    }

    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    return (
        <div>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {data && (
                <Fragment>
                    {
                        props?.filtros?.nombre
                            ?
                            <>
                                <TableSearch headers={headers} data={filterDataList(formatDataFacturadores(data), state.filtros)} />

                                {/* <Table headers={headers} data={takeData(filterDataList(formatDataFacturadores(data), state.filtros), state.currentPage)} /> */}
                            </> :
                            <>
                                <Table headers={headers} data={takeDataSearch(filterDataList(formatDataFacturadores(data), state.filtros), state.currentPage)} />
                            </>
                    }
                </Fragment>
            )}
        </div>
    )
}

export default FacturadoresTable;