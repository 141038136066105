import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { handleToggleModal } from "../../../actions/modal";
import { GET_VENDEDOR } from "../../../utils/queries/Queries";

const VistaIvaDos = props => {

    const { id } = useParams();

    /**consumimos recurso para acceder a datos del vendedor */
    const { data, loading, error } = useQuery(GET_VENDEDOR, {
        variables: {
            idVendedor: id,
        }
    })

    /**funcion para cerrar modal */
    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
    }

    /**array para guardar imagenes/doc de ivas */
    const a_Iva = data?.vendedor?.imagenes?.filter(imagen => imagen?.tipoImagen === 'DECLARACION_IVA')

    //funcion para previsualizar pdf/imagen en adjuntas
    const previsualizarIva = (dataPdf) => {
        /**declaramos extensiones imagenes */
        let imageExtensions = /(.jpg|.jpeg|.png|.gif)$/i;

        /** rendedriazado condicional, nos aseguramos que sea imagen y si no es archivo */
        if (imageExtensions.exec(dataPdf)) {
            return (

                <div className=''>
                    <div className='mb-3 a-end'>
                        <a href={dataPdf} target="_blank" download><span>Ampliar imagen <ion-icon name="expand-outline"></ion-icon></span> </a>
                    </div>
                    <div className='mb-3'>
                        <img className='imgIva' src={dataPdf} />
                    </div>
                </div>
            )
        }

        return (
            <div className=''>
                <object className='viewerDocConstancia' data={dataPdf} type="application/pdf"  >
                    <embed src={dataPdf} type="application/pdf" />
                </object>
            </div>
        )
    }

    return (
        <>
            <Fragment>
                <form>
                    <div className='is-fullwidth'>
                        <div className="d-f ">
                            {
                                a_Iva?.map((item, index) => {
                                    if (index === 1) {
                                        return (
                                            <div className="ivaview">
                                                <div className="ml-4 d-f ">
                                                    {previsualizarIva(item?.urlImagen)}
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return
                                    }
                                })
                            }
                        </div>
                    </div>
                </form>
                <footer className="modal-card-foot pb-0">
                    <button
                        onClick={() => handleCloseModal()}
                        className="button btn-cancelar" data-bulma-modal="close">Cancelar
                    </button>
                </footer>
            </Fragment>
        </>
    )
}

function mapStateToProps({ authedUser }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(VistaIvaDos);