import React, { Fragment } from 'react'
import TablaDetalle from "../../common/Tabla/TablaDetalle";
import { formatFacturas } from "../../../utils/helpers";
import { useQuery } from "@apollo/client";
import { GET_VENDEDOR_FACTURAS } from "../../../utils/queries/Queries";

const FacturaTablaEnviadas = (props) =>{

    const cabecera = {
        periodo: {
            id: 1,
            label: 'Periodo',
            order: 'desc',
            status: false
        },
        fecha: {
            id: 2,
            label: 'FECHA',
            order: 'desc',
            status: false
        },
        estado: {
            id: 3,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        monto: {
            id: 4,
            label: 'MONTO',
            order: 'desc',
            status: false
        },
    }
    // todo: Ver funcionalidad de manejo de ordenamiento de datos
    // const handleSort = ()=>{
    //
    // }
    //
    // const handleFilter = ()=>{
    //
    // }

    const { data, loading, error } = useQuery(GET_VENDEDOR_FACTURAS, {
        variables: {
            idVendedor: props?.vendorID,
        }
    })


    //Declaramos un array de objetos para almacenar los valores correspondientes de las facturas
    const facturaList = data?.vendedor?.facturas
    //almacenamos en aPendientes las facturas de estado PENDIENTE
    const aEnviadas = facturaList?.filter(item => item.estadoActual === 'ENTREGA' || item.estadoActual === 'VERIFICACION' || item.estadoActual === 'ENTREGADA' )
    console.log(aEnviadas);
    const aFinal = aEnviadas?.sort((a, b) => new Date(a.periodoFacturacion).getTime() - new Date(b.periodoFacturacion).getTime())

    return (
        <Fragment>
            {error && (
                'Error cargando facturas del vendedor'
            )}

            {loading && (
                'Cargando facturas del vendedor...'
            )}
            {data && (
                <TablaDetalle
                    cabecera={cabecera}
                    detalles={formatFacturas(aFinal)}
                />
            )}
        </Fragment>
    )
}

export default FacturaTablaEnviadas