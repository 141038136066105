import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Navigation = (props) =>{
    const {nombre, apellido} = props;
    return(
        <nav
            className={props.sidebarStatus === 'open' ? 'navbar' :'navbar nav-full'}
            role="navigation" aria-label="main navigation"
            id="navbar">
            <div className="navbar-brand">
                <button id="burger-icon" className="navbar-burger" aria-label="menu"
                        aria-expanded="false" data-target="navbarBasicExample">
                </button>
            </div>
            <div className="is-hide" id="mobile-toggle">
                <div className="buttons showoptions">
                    <button  className="">
                        Juan Perez!
                    </button>
                </div>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">
                    <Link to="/"  type='link' className="btn-link navbar-item m-2 ml-6">
                        <h5 className="font-600">Vendedores</h5>
                    </Link>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <Link to="/" className="">
                                {/*<i className="fas fa-sign-out-alt mr-5"></i>*/}
                                <FontAwesomeIcon className='mr-2 has-text-danger' icon={['fas', 'sign-out-alt']} />
                                <span className='has-text-black'>{nombre} {apellido}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
function mapStateToProps({authedUser}){
    return{
        ...authedUser
    }
}
export default connect(mapStateToProps)(Navigation);