import { useQuery } from '@apollo/client';
import React from 'react'
import { formatDataColorCategory } from '../../../utils/helpers';
import { GET_COLORS } from '../../../utils/queries/Queries';

const SelectColor = (props) => {

    //Consulta graphql donde consumimos el recurso para mostrar 
    //el listado de colores desde la base de datos
    const { data, loading, error } = useQuery(GET_COLORS)

    //formateo del listado de colores
    const formatedList = formatDataColorCategory(data)

    return (
        <div className="field">
            <label className="label" htmlFor="">Color de la categoría:</label>
            <div className="control">
                <div className='select is-fullwidth'>
                    {loading && (<button className="button select is-loading is-fullwidth"> </button>)}

                    {data && (
                        <select
                            name="color"
                            onChange={(e) => props.handleChange(e)}
                            value={props.defaultValue ? props.defaultValue : ''}
                        >
                            <option value='' disabled>Seleccione un color</option>
                            {Object.keys(formatedList).map(item => {
                                return (
                                    <option  key={item} value={formatedList[item].rgba}>
                                        {formatedList[item].descripcion}
                                    </option>
                                )
                            })}
                        </select>
                    )}

                </div>
            </div>
        </div>
    )
}

export default SelectColor