import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux"
import { BounceLoader, PropagateLoader } from "react-spinners";
import { override } from "../../utils/helpers";
import { CHANGE_PASSWORD_OPERADOR } from "../../utils/mutators/Mutators";
import { GET_OPERATORS } from "../../utils/queries/Queries";
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import { useNavigate } from "react-router-dom";


const ResetPasswordToLogin = (props) => {

    const dashboardRef = React.createRef();
    const { sidebarStatus, modal } = props;

    const dataAuth = { props }

    const initialState = {
        nroDocumento: props?.authedUser?.id,
        id: props.authedUser?.idOperador,
        sistema: "BACKOFFICE",
        variables: {
            oldPassword: "",
            newPassword: "",
            repeatNewPassword: "",

        }
    }
    const [errorExist, setErrorExist] = useState(false)


    const [state, setState] = useState(initialState)


    // Mutator for delete a operator
    const [changePasswordOperador, { data, loading, error }] = useMutation(CHANGE_PASSWORD_OPERADOR, {
        refetchQueries: [
            GET_OPERATORS, // DocumentNode object parsed with gql
            'Operadores' // Query name
        ],
    });

    const handleSubmit = (e) => {
        e.preventDefault()

        changePasswordOperador({
            variables: {
                data: {
                    sistema: state?.sistema,
                    id: state?.id,
                    oldPassword: state.variables.oldPassword,
                    newPassword: state.variables.newPassword,
                    nroDocumento: state.nroDocumento
                }
            }
        }).then(r => {
            console.log('capturando respuesta', r)
            // successDel()
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                // errorDel()
                console.error('Error al resetear contraseña -> ' + reason);
            }
        );

        localStorage.clear();
    }

    //onchange de los inputs
    const handleChange = (e) => {

        setState(prevState => {
            const updatedValues = {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    [e.target.name]: e.target.value,
                }
            }
            return { ...updatedValues };
        });
    }


    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    // Initialize a boolean state
    const [passwordShownNew, setPasswordShownNew] = useState(false);

    const toggleNewPassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShownNew(!passwordShownNew);
    }

    // Initialize a boolean state
    const [passwordShownNewRepeat, setPasswordShownNewRepeat] = useState(false);

    const toggleNewPasswordRepeat = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShownNewRepeat(!passwordShownNewRepeat);
    }

    // React router for redirect to dashboard
    let navigate = useNavigate();

    let identificadorTiempoDeEspera;

    function timeToReload() {
        identificadorTiempoDeEspera = setTimeout(funcionConRetraso, 2500);
    }

    function funcionConRetraso() {
        navigate('/')
        localStorage.clear();
    }

    return (
        <div className="container is-fluid container-fix-login resetpass">
            <section className='' id="cont-box">
                <div className="box-change-pass" >
                    {error && (
                        <div className="notification is-danger">
                            <button className="delete"> </button>
                             <strong>{error.message}</strong>
                        </div>
                    )}
                    {(loading) && (
                        <div>
                            <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                        </div>
                    )}
                    {(!loading) &&
                        (!data) &&
                        <>
                            <div className="data-password password" id="resetPassLogin">
                                <h1>CAMBIAR CONTRASEÑA</h1>
                                <form onSubmit={e => handleSubmit(e)}>
                                    <div className="item-column has-text-left d-grid newpass">
                                        <label>Contraseña temporal:</label>
                                        <div>
                                            <input
                                                required
                                                id="password"
                                                className="passrest"
                                                name='oldPassword'
                                                type={passwordShown ? "text" : "password"}
                                                onChange={e => handleChange(e)}
                                                label='Contraseña actual'
                                                value={state.variables.oldPassword}
                                                placeholder='Ingresar contraseña temporal' />
                                            <span className="togglepassword" onClick={togglePassword}><FontAwesomeIcon icon="fa-solid fa-eye" /></span>
                                        </div>
                                    </div>
                                    <div className="item-column has-text-left d-grid newpass">
                                        <label>Nueva contraseña:</label>
                                        <div>
                                            <input
                                                required
                                                id="newPassword"
                                                className="passrest"
                                                name='newPassword'
                                                type={passwordShownNew ? "text" : "password"}
                                                onChange={e => handleChange(e)}
                                                label='Contraseña actual'
                                                value={state.variables.newPassword}
                                                placeholder='Ingresar nueva contraseña' />
                                            <span className="togglepassword" onClick={toggleNewPassword}><FontAwesomeIcon icon="fa-solid fa-eye" /></span>
                                        </div>
                                    </div>
                                    <div className="item-column has-text-left d-grid newpass">
                                        <label>Repetir nueva contraseña:</label>
                                        <div>
                                            <input
                                                required
                                                id="repeatNewPassword"
                                                className="passrest"
                                                name='repeatNewPassword'
                                                type={passwordShownNewRepeat ? "text" : "password"}
                                                onChange={e => handleChange(e)}
                                                label='Contraseña actual'
                                                value={state.variables.repeatNewPassword}
                                                placeholder='Repetir nueva contraseña' />
                                            <span className="togglepassword" onClick={toggleNewPasswordRepeat}><FontAwesomeIcon icon="fa-solid fa-eye" /></span>
                                        </div>
                                    </div>

                                    {
                                        (state?.variables?.newPassword !== state.variables?.repeatNewPassword) ?
                                            <div className="noigual">
                                                <span>Las nuevas contraseñas no coinciden</span>
                                                <button disabled type='submit' className={'button is-normal btn-login pass mt-5'}>Cambiar contraseña</button>
                                            </div>
                                            :
                                            <button type='submit' className={'button is-normal btn-login pass mt-5'}>Cambiar contraseña</button>
                                    }
                                </form>
                            </div>
                        </>
                    }
                    {
                        data && (
                            <div className="data-confirm">
                                <div className="confirm-msg-password">
                                    <div className="Confirm-data">
                                        <span className="icon-mail"><ion-icon name="checkmark-circle-outline"></ion-icon></span>
                                        <p className="mt-3">Se modifico exitosamente su contraseña</p>
                                        <div className="mt-5">
                                            <PropagateLoader className="loader" color={state.color} loading={state.loading} css={override} size={5} />
                                        </div>
                                        <div className="mt-4" id="redict">
                                            <span className="redirecting">Redirigiendo pagina...</span>
                                        </div>
                                    </div>
                                    {timeToReload()}
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
        </div>
    )
}

function mapStateToProps({ authedUser, modal, sidebarStatus }) {
    return {
        authedUser,
        sidebarStatus,
        modal
    }
}

export default connect(mapStateToProps)(ResetPasswordToLogin)

