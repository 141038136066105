import moment from 'moment';
import React, { Fragment, useState } from 'react'
import { formatCurrency } from '../../utils/helpers';

const VendedorDatos = (props) => {

    const { vendedor } = props
    
    // guardamos en un array el campo correspondiente de contactos y
    //filtramos el nro de teléfono y correo que estan verificados y habilitados
    const vContacto = props?.vendedor?.contactos?.filter((item) => { return item?.verificado && item?.habilitado})

    //funcion para obtener el numero de teléfono del vendedor
    const obtNumero = vContacto?.map(element => {
        if (element?.tipo === "TELEFONO") {
            return (element?.valor?.numero);
        }
    });
    
    //función para obtnener el correo del vendedor
    const obtCorreo = vContacto?.map(element => {
        if (element?.tipo === "CORREO") {
            return (element?.valor?.direccion);
        }
    });

    //funcion para dar estilo dato del vendedor segun estado de kyc
    const stateKyc = (dataKyc) => {
        if (dataKyc === "BASICO") {
            return (<p className='box-reg'>{dataKyc}</p>)
        }
        if (dataKyc === "PENDIENTE") {
            return (<p className='box-pending'>{dataKyc}</p>)
        }
        if (dataKyc === "MEDIO") {
            return (<p className='box-medio'>{dataKyc}</p>)
        }
        if (dataKyc === "AVANZADO") {
            return (<p className='box-avanzado'>{dataKyc}</p>)
        }
        if (dataKyc === "RECHAZO_PERM") {
            return (<p className='box-bloq-permanent'>{dataKyc}</p>)
        }
        if (dataKyc === "RECHAZO_TEMP") {
            return (<p className='box-bloq-temp'>{dataKyc}</p>)
        }
    }


    const stateAccount = (dataAccount) => {

        if (dataAccount === "ACTIVA") {
            return (<p className='box-reg'>{dataAccount}</p>)
        }
        if (dataAccount === "BLOQUEO_LOGIN") {
            return (<p className='bloq-login'>{dataAccount}</p>)
        }
        if (dataAccount === "BLOQUEO_FACTURA") {
            return (<p className='bloq_factura'>{dataAccount}</p>)
        }
        if (dataAccount === "BLOQUEO_BACKOFFICE") {
            return (<p className='bloq-bo'>{dataAccount}</p>)
        }
        if (dataAccount === "INACTIVA") {
            return (<p className='box-inactiva'>{dataAccount}</p>)
        }
    }

    //funcion para poner si es pep en SI o NO dependiendo del campo
    //pep si es true or false
    const isPep = (dataPep) => {
        if (dataPep === true) {
            return (<p className='box-reg pep'>SI</p>)
        } else {
            return (<p className='box-pending pep'>NO</p>)
        }
    }

    return (
        <Fragment>
            <div className="column is-3">

                <div className="column">
                    <label htmlFor="">Nombre:</label>
                    <p>{vendedor?.nombres}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Apellido:</label>
                    <p>{vendedor?.apellidos}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Tipo de documento:</label>
                    <p>{vendedor?.tipoDocumento.descripcion}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Fecha de nacimiento:</label>
                    <p> {moment(vendedor?.fechaNacimiento).format('DD-MM-YYYY')}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Nacionalidad:</label>
                    <p>{vendedor?.paisNacimiento.descripcion} </p>
                </div>
                <div className="column">
                    <label htmlFor="">Correo:</label>
                    <p>{obtCorreo ? obtCorreo : "-"} </p>
                </div>
                <div className="column">
                    <label htmlFor="">Codigo de receptora asociada:</label>
                    <p>{vendedor?.receptora}</p>
                </div>
            </div>
            <div className="column is-3">

                <div className="column">
                    <label htmlFor="">Estado de KYC:</label>
                    <p>{stateKyc(vendedor?.estadoKYC)}</p>
                </div>

                <div className="column">
                    <label htmlFor=""> Número de cédula:</label>
                    <p>{vendedor?.nroDocumento} </p>
                </div>

                <div className="column">
                    <label htmlFor="">R.U.C:</label>
                    <p>{vendedor?.ruc} </p>
                </div>

                <div className="column">
                    <label htmlFor="">Actividad económica:</label>
                    <p>{vendedor?.actividad.descripcion} </p>
                </div>

                <div className="column">
                    <label htmlFor="">Vencimiento Cédula:</label>
                    <p>{moment(vendedor?.fechaVencimientoDocumento).format('DD-MM-YYYY')}</p>
                </div>
                <div className="column">
                    <label htmlFor="">Teléfono:</label>
                    <p>{obtNumero ? obtNumero : "-"} </p>
                </div>

                <div className="column">
                    <label htmlFor="">PEP:</label>
                    <p>{isPep(vendedor?.pep)}</p>
                </div>
                
            </div>
            <div className="column is-3">

                <div className="column">
                    <label htmlFor="">Estado de la cuenta:</label>
                    <p>{stateAccount(vendedor?.estadoCuenta)}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Fecha de última modificación:</label>
                    <p>{moment(vendedor?.fechaActualizacion).format('DD-MM-YYYY, h:mm:ss a') === "Invalid date" ? '-' : moment(vendedor?.fechaActualizacion).format('DD-MM-YYYY, h:mm:ss a')}</p>
                </div>

                <div className="column address-pa">
                    <label htmlFor="">Dirección particular:</label>
                    <p>{vendedor?.direccionParticular} </p>
                </div>

                <div className="column localte-pa">
                    <label htmlFor="">Ubicación:</label>
                    <p>{vendedor?.ubicacion?.departamento?.pais?.descripcion} / {vendedor?.ubicacion?.departamento?.descripcion} / {vendedor?.ubicacion?.descripcion}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Fecha de registro:</label>
                    <p>{moment(vendedor?.fechaInsercion).format('DD-MM-YYYY, h:mm:ss a')}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Saldo disponible:</label>
                    <p>{formatCurrency(vendedor?.saldo)}</p>
                </div>
            </div>
        </Fragment>
    )
}

export default VendedorDatos;