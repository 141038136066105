import {
    _getVendors,

} from './_DATA.js'

export function getInitialData () {
    return Promise.all([
        _getVendors(),
    ]).then(([users]) => ({
        users
    }))
}

export function getVendors (info) {
    return _getVendors(info)
}

export function getLoginCredentials(info){
    return ''
}