import { css } from "@emotion/react";


export const formatDataCategory = (data) => {

    let obData = {}

    data.forEach(item => {
        obData = {
            ...obData,
            [item.id]: {
                id: item.id,
                nombre: item.nombre,
                icono: <span className="box-category"><ion-icon name={item.icono}></ion-icon></span>,
                actions: 'x x'
            }
        }
    })
    return obData
}

export const formatDataServices = (data) => {
    // console.log('CHECKING SERVICIOS =>', data.servicios)
    let obData = {}
    data.servicios.forEach(item => {
        obData = {
            ...obData,
            [item.id]: {
                id: item.id,
                servicio_name: item.nombre,
                categoria_name: item.facturador.categoria.nombre,
                facturador_name: item.facturador.nombre,
                red: item.red,
                diligenciaAmpliada: formatPep(item.diligenciaAmpliada),
                habilitado: formatEstado(item.habilitado),
                actions: 'x x',
                idServicioRed: item.idServicioRed

            }
        }
    })

    return obData
}

export const formatDataUsers = (data) => {

    let obData = {}
    data?.forEach((item) => {
        obData = {
            ...obData,
            [item.id]: {
                id: item.id,
                nombre: item.nombres + ' ' + item.apellidos,
                documento: item.documento,
                fecha: (item.fechaInsercion).substring(0, 10),
                rol: item.rol,
                habilitado: customStatus(item.habilitado),
                correo: item.correo,
                actions: 'x x'
            }
        }
    })

    return obData
}

export const formatDataNotification = (data) => {

    let obData = {}
    data?.forEach((item) => {
        obData = {
            ...obData,
            [item.idContenido]: {
                id: item.idContenido,
                titulo: item.titulo,
                contenido: item.contenido,
                idPrioridad: item.idPrioridad,
                estado: item.estado,
                actions: 'x x',
                idRegla: item.idRegla,
                fechaInsercion: item.fechaInsercion
            }
        }
    })

    return obData
}

export const customStatus = (dataStatus) => {
    if (dataStatus === true) {
        return ('ACTIVA')
    } else if (dataStatus === false) {
        return ('BLOQUEADA')
    }
}

export const formatDataFacturadores = (data) => {
    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    // console.log("Checking Facturadores: ", data)
    if (data) {
        data.facturadores.forEach(item => {

            obData = {
                ...obData,
                [item.id]: {
                    id: item.id,
                    nombre: item.nombre,
                    categoria: item.categoria.nombre,
                    icono: <span className="box-category"><ion-icon name={item.categoria.icono}></ion-icon></span>,
                    actions: 'x x'
                }
            }


        })
    }

    // console.log('Formated Facturadores: ', obData)
    return obData
}
export const formatAntecedentesVendedores = (data) => {

    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}
    // console.log("Checking Facturadores: ", data)
    if (data) {
        if (data.obtenerInformeDetallado.informe.data.Antecedentes) {
            data.obtenerInformeDetallado.informe.data.Antecedentes.forEach(item => {
                obData = {
                    ...obData,
                    [item.fecha_inicio + item.numero_causa]: {
                        fechaInicio: item.fecha_inicio,
                        nroCausa: item.numero_causa,
                        caratula: item.caratula,
                        estado: item.estado_procesal,
                        fechaEstado: item.fecha_estado,
                        nombreJuzgado: item.nombre_juzgado,
                        localidad: item.localidad,
                        fechaHecho: item.fecha_hecho,
                        idTipoPenal: item.id_tipo_penal,
                        actions: 'x x'
                    }
                }
            })
        }

    }
    return obData
}

export const formatDemandasVendedores = (data) => {

    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}
    // console.log("Checking Facturadores: ", data)
    if (data) {
        if (data.obtenerInformeDetallado.informe.data.Demandas) {
            data.obtenerInformeDetallado.informe.data.Demandas.forEach(item => {
                obData = {
                    ...obData,
                    [item.fecha_oficio + item.numero_causa]: {
                        fechaOficio: item.fecha_oficio,
                        nroCausa: item.numero_causa,
                        caratula: item.caratula,
                        estado: item.estado_procesal,
                        demandante: item.demandante,
                        monto: formatCurrency(item.monto),
                        nombreJuzgado: item.nombre_juzgado,
                        secretaria: item.secretaria,
                        folio: item.folio,
                        actions: 'x x'
                    }
                }

            })
        }

    }
    return obData
}


export const formatPublicacionesVendedores = (data) => {

    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    // console.log("Checking Facturadores: ", data)
    if (data) {
        if (data.obtenerInformeDetallado.informe.data.Links) {
            data.obtenerInformeDetallado.informe.data.Links.forEach(item => {

                obData = {
                    ...obData,
                    [item.enlace]: {
                        fecha: item.fecha_publicacion,
                        medio: item.medio_publicado,
                        enlace: item.enlace,
                        actions: 'x x'
                    }
                }

            })

        }


    }

    return obData
}

export const formatRelacionesVendedores = (data) => {

    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}
    console.log("data vinculadas", data)
    // console.log("Checking Facturadores: ", data)
    if (data) {
        data.obtenerInformeDetallado.informe.data.Vinculadas.forEach(item => {

            obData = {
                ...obData,
                [item.cedula]: {
                    id: item.cedula,
                    vinculo: item.vinculo,
                    nroDocumento: item.cedula,
                    nombre: item.nombre + " " + item.apellido,
                    actions: 'x x'
                }
            }


        })
    }

    // console.log('Formated Facturadores: ', obData)
    return obData
}



export const formatDataBasico = (data) => {

    console.log("data", data)
    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    // console.log("Checking Facturadores: ", data)
    if (data) {
        data.obtenerInformeDetallado.informe.data.Vinculadas.forEach(item => {

            obData = {
                ...obData,
                [item.id]: {
                    id: item.id,
                    caratula: item.nombre,
                    fechaInicio: item.apellido,
                    nroCausa: 'NRO CAUSA',
                    caratula: 'CARATULA',
                    estado: 'ESTADO PROCESAL',
                    fechaEstado: 'FECHA ESTADO',
                    nombreJuzgado: 'NOMBRE JUZGADO',
                    localidad: 'LOCALIDAD',
                    fechaHecho: 'FECHA HECHO',
                    idTipoPenal: 'ID TIPO PENAL',
                    actions: 'x x'
                }
            }


        })
    }

    // console.log('Formated Facturadores: ', obData)
    return obData
}

export const formatDataFacturadoresSelect = (data) => {
    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    // console.log("Checking Facturadores: ", data)
    if (data) {
        data.facturadores.forEach(item => {

            obData = {
                ...obData,
                [item.id]: {
                    id: item.id,
                    nombre: item.nombre,
                    categoria: item.categoria.nombre,
                    categoriaID: item.categoria.id,

                }
            }


        })
    }

    // console.log('Formated Facturadores: ', obData)
    return obData
}

export const formatDataVendedores = (data) => {

    let obData = {}

    if (data) {
        data.vendedores.forEach(item => {
            obData = {
                ...obData,
                [item.id]: {
                    id: item.id,
                    nombre: item.nombres + ' ' + item.apellidos,
                    documento: item.nroDocumento,
                    registerFormat: formatoDate(item.fechaInsercion),
                    kyc: item.estadoKYC,
                    status: item.estadoCuenta,
                    // city: item.ubicacion.descripcion,
                    register: (item.fechaInsercion).substring(0, 10),
                    modificacion: (item.fechaActualizacion).substring(0, 10),
                    modificacionFormat: formatoDate(item.fechaActualizacion),
                    pep: formatPep(item.pep),
                    actions: 'x x',
                }
            }
        })
    }

    return obData
}

export const formatDataServicioRedes = (data) => {
    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    // console.log("Checking Servicios Red: ", data)
    if (data) {
        data.serviciosRedes.forEach(item => {

            obData = {
                ...obData,
                [item.id]: {
                    id: item.id,
                    nombre: item.nombre,
                    __typename: item.__typename,
                }
            }


        })
    }

    return obData
}

export const formatDataIcons = (data) => {
    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    if (data) {
        data?.iconos?.forEach(item => {
            obData = {
                ...obData,
                [item.id]: {
                    id: item.id,
                    figura: item.figura,
                    __typename: item.__typename,
                }
            }
        })
    }

    return obData
}

export const formatDataColorCategory = (data) => {
    // Se recibe el array de objetos del query del graphQL
    // Esta función sirve para formatear los objetos con una estructura tiniendo al ID como key del objeto.
    // Retornando una colleccion de objetos en un objeto.
    let obData = {}

    if (data) {
        data?.colores?.forEach(item => {
            obData = {
                ...obData,
                [item.rgba]: {
                    id: item.id,
                    descripcion: item.descripcion,
                    rgba: item.rgba,
                    __typename: item.__typename,
                }
            }
        })
    }

    return obData
}

export const override =
    css`
      display: block;
      margin: auto;
    `;

export const formatComisiones = (data) => {

    // todo: fechaAprobacion ahora no retorna nada. Y luego cambiara de nombre
    let aux = {}

    data.forEach(item => {
        aux = {
            ...aux,
            [item.id]: {
                fecha: (item?.fechaSolicitud)?.substring(0, 10),
                concepto: 'Comisión del ' + item.servicio.nombre,
                estado: item.estado,
                acreditadoNetel: formatAcreditation(item.acreditadoNetel),
                acreditadoCabecera: formatAcreditation(item.acreditadoCabecera),
                monto: formatCurrency(item.monto),
                fechaFilter: formatoDate(item?.fechaSolicitud),
            }
        }
    })
    return aux
}

export const formatAcreditation = (data) => {
    if (data === true) {
        return ('ACREDITADA')
    } else if (data === false) {
        return ('PENDIENTE')
    }
}

export const formatFacturas = (data) => {
    // todo: fechaAprobacion ahora no retorna nada. Y luego cambiara de nombre
    let aux = {}

    data.forEach(item => {
        aux = {
            ...aux,
            [item.periodoFacturacion]: {
                fecha: formatDateTable(item.fechaInsercion),
                periodo: formatDateTable(item.periodoFacturacion),
                estado: item.estadoActual,
                monto: formatCurrency(item.monto),
            }
        }
    })

    return aux
}

export const formatVentas = (data) => {
    // console.log('Ventas ->', data)
    // todo: fechaAprobacion ahora no retorna nada. Y luego cambiara de nombre
    let aux = {}

    data?.forEach(item => {
        const auxName = item?.servicio?.facturador?.nombre + ' - ' + item?.servicio?.nombre !== 'undefined - undefined' ? item?.servicio?.facturador?.nombre + ' - ' + item?.servicio?.nombre : 'RECARGA BOMO'
        aux = {
            ...aux,
            [item?.id]: {
                fecha: (item?.fechaSolicitud)?.substring(0, 10),
                concepto: auxName,
                estado: item?.estado,
                monto: formatCurrency(item?.monto),
                actividad: item?.actividad,
                fechaFilter: formatoDate(item?.fechaSolicitud),
            }
        }

    })
    return aux
}

export const DataExport = (data) => {
    // console.log('Ventas ->', data)
    // todo: fechaAprobacion ahora no retorna nada. Y luego cambiara de nombre
    let aux = {}

    data?.forEach(item => {
        const auxName = item?.servicio?.facturador?.nombre + ' - ' + item?.servicio?.nombre !== 'undefined - undefined' ? item?.servicio?.facturador?.nombre + ' - ' + item?.servicio?.nombre : 'RECARGA BOMO'
        aux = {
            ...aux,
            [item?.fechaSolicitud]: {
                fecha: (item?.fechaSolicitud),
                concepto: auxName,
                estado: item?.estado,
                monto: (item?.monto),
                actividad: item?.actividad,
                fechaFilter: formatoDate(item?.fechaSolicitud),
            }
        }

    })
    return aux
}

export const formatDateTable = (fecha) => {

    const newFecha = new Date(fecha)
    // console.log('old fecha: ', newFecha.toLocaleDateString())
    return newFecha.toLocaleDateString()
}


// formatea la fecha recibida en dd/mm/aaaa h:m
//puede recibir null en el campo de fecha.actualizacion
export const formatoDate = (fecha) => {

    if (fecha != null) {
        const date = new Date(fecha)
        const dateFormat = date.getDate().toString().padStart(2, '0') + '/' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
            date.getFullYear()
            + ' ' + date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0') + 'hs'

        return dateFormat.toString()

    }
    return null


}

export const formatCurrency = (data) => {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PYG',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(data);
}

export const formatModificacionesKYC = data => {

    let aux = {}
    Object.keys(data).map(item => {
        aux = {
            ...aux,
            [item]: {
                fecha: formatDateTable(data[item].fechaHora),
                estado: data[item].estado,
                operador: data[item].operador ? data[item].operador?.correo : '',
                comentario: data[item].comentario
            }

        }

    })

    return aux
}

export const filterDataList_old = (vendors, filter) => {
    let filteredVendors = vendors;

    // Se elimina los filtros vacios del objeto
    for (let key in filter) {
        if (filter[key] === '') {
            delete filter[key]
        }
    }
    /**
     * Una vez que se limpio el objeto de filtros se procede a iterar por cada filtro para
     * para dejar a la lista de vendedores mas acotada
     **/
    Object.keys(filter).forEach(filtro => {
        // console.log('checking filtros vuelta', filtro)
        /**
         * @Estado de Cuenta
         * Se verifica que coincida con el filtro estado de cuenta y que no sean todas para eliminar los datos
         * que no correspondendel objeto
         **/
        if (filtro === 'cuenta' && filter[filtro] !== 'TODAS') {
            filteredVendors =
                // se obtiene array de keys para poder iterar el objeto
                Object.keys(filteredVendors)
                    // Se verifica si el objeto del vendedor cumple con el filtro seleccionado
                    .filter(item => (filteredVendors[item].status === filter[filtro]))
                    // se devuelve el objeto del vendedor que cumplio con el filtro
                    .map(id => filteredVendors[id])
        }

        /**
         * @Estado de registro
         * IDEM comentario de bloque anterior pero para filtro de Estado de registro+*/
        if (filtro === 'registro' && filter[filtro] !== 'TODAS') {
            filteredVendors =
                Object.keys(filteredVendors).filter(item => (filteredVendors[item].kyc === filter[filtro])).map(id => filteredVendors[id])
        }


        if (filtro === 'desde') {
            filteredVendors = Object.keys(filteredVendors).filter(item => {
                const fechaVendedor = new Date(filteredVendors[item].register)
                const fechaFiltro = filter[filtro]
                return fechaVendedor.getTime() >= fechaFiltro.getTime()
            }).map(id => filteredVendors[id])
        }


        if (filtro === 'hasta') {
            filteredVendors = Object.keys(filteredVendors).filter(item => {
                const fechaVendedor = new Date(filteredVendors[item].register)
                const fechaFiltro = filter[filtro]
                return fechaVendedor.getTime() <= fechaFiltro.getTime()
            }).map(id => filteredVendors[id])

        }
    })


    return filteredVendors

}

/**
 * @param dataList Lista de Objetos con datos a ser filtrados
 * @param filter Objeto con los filtros que debe ser iterado y filtrado el dataList
* */
export const filterDataList = (dataList, filter) => {
    /**
     * Se asigna a la variables @filteredData para poder ir modificando
     * */
    let filteredData = dataList;

    /**
     * Se elimina los filtros vacios del objeto
     * Dejando un objeto solo con los atributos con valores asignados.
     * */
    for (let key in filter) {
        if (filter[key] === '') {
            delete filter[key]
        }
    }

    Object.keys(filter).forEach(filtro => {
        /**
         * Si el Filtro es distinta al valor TODAS procedera a filtrar, ya que TODAS implica que debe devolver el objeto
         * tal cual se mando inicialmente
         * */
        (filter[filtro] !== 'TODAS') && (
            filteredData =
            // se obtiene array de keys para poder iterar el objeto
            Object.keys(filteredData)
                // Se verifica si el objeto del vendedor cumple con el filtro seleccionado
                .filter(item => {

                    if (filtro === 'hasta') {
                        const fechaVendedor = new Date(filteredData[item].register)
                        const fechaFiltro = filter[filtro]
                        return fechaVendedor.toISOString().substring(0, 10) <= fechaFiltro.toISOString().substring(0, 10)

                    }

                    if (filtro === 'desde') {
                        const fechaVendedor = new Date(filteredData[item].register)
                        const fechaFiltro = filter[filtro]
                        return fechaVendedor.toISOString().substring(0, 10) >= fechaFiltro.toISOString().substring(0, 10)
                    }

                    if (filtro === 'idServicioRed') {
                        return (filteredData[item][filtro] == (filter[filtro]))

                    }

                    if (filtro === 'documento') {
                        return (filteredData[item][filtro].includes((filter[filtro])))
                    }

                    return (filteredData[item][filtro].toLowerCase().includes(filter[filtro].toLowerCase()))
                })
                // se devuelve el objeto del vendedor que cumplio con el filtro
                .map(id => filteredData[id])
        )

    })

    // Finalmente se retorna los datos filtrados
    return filteredData

}

/**
 * @param dataList Lista de Objetos con datos a ser filtrados
 * @param filter Objeto con los filtros que debe ser iterado y filtrado el dataList
* */
export const filterDataListNotification = (dataList, filter) => {
    /**
     * Se asigna a la variables @filteredData para poder ir modificando
     * */
    let filteredData = dataList;

    /**
     * Se elimina los filtros vacios del objeto
     * Dejando un objeto solo con los atributos con valores asignados.
     * */
    for (let key in filter) {
        if (filter[key] === '') {
            delete filter[key]
        }
    }

    Object.keys(filter).forEach(filtro => {
        /**
         * Si el Filtro es distinta al valor TODAS procedera a filtrar, ya que TODAS implica que debe devolver el objeto
         * tal cual se mando inicialmente
         * */
        (filter[filtro] !== 'TODAS') && (
            filteredData =
            // se obtiene array de keys para poder iterar el objeto
            Object.keys(filteredData)
                // Se verifica si el objeto del vendedor cumple con el filtro seleccionado
                .filter(item => {

                    if (filtro === 'hasta') {
                        const fechaNotificacion = new Date(filteredData[item].fechaInsercion)
                        const fechaFiltro = filter[filtro]
                        return fechaNotificacion?.toISOString().substring(0, 10) <= fechaFiltro?.toISOString().substring(0, 10)
                    }

                    if (filtro === 'desde') {
                        const fechaNotificacion = new Date(filteredData[item].fechaInsercion)
                        const fechaFiltro = filter[filtro]
                        return fechaNotificacion?.toISOString().substring(0, 10) >= fechaFiltro?.toISOString().substring(0, 10)
                    }
                    return (filteredData[item][filtro].toLowerCase().includes(filter[filtro].toLowerCase()))
                })
                // se devuelve el objeto del vendedor que cumplio con el filtro
                .map(id => filteredData[id])
        )

    })

    // Finalmente se retorna los datos filtrados
    return filteredData

}


/**
 * @param dataList Lista de Objetos con datos a ser filtrados
 * @param filter Objeto con los filtros que debe ser iterado y filtrado el dataList
* */
export const filterMovimientoList = (dataList, filter) => {

    /**
     * Se asigna a la variables @filteredDataMovimiento para poder ir modificando
     * */
    let filteredDataMovimiento = dataList;

    /**
     * Se elimina los filtros vacios del objeto
     * Dejando un objeto solo con los atributos con valores asignados.
     * */
    for (let key in filter) {
        if (filter[key] === '') {
            delete filter[key]
        }
    }

    Object?.keys(filter)?.forEach(filtro => {
        /**
         * Si el Filtro es distinta al valor TODAS procedera a filtrar, ya que TODAS implica que debe devolver el objeto
         * tal cual se mando inicialmente
         * */
        (filter[filtro] !== 'TODAS') && (
            filteredDataMovimiento =
            // se obtiene array de keys para poder iterar el objeto
            Object.keys(filteredDataMovimiento)
                // Se verifica si el objeto del vendedor cumple con el filtro seleccionado
                .filter(item => {

                    if (filtro === 'hasta') {
                        const fechaMovimiento = new Date(filteredDataMovimiento[item].fecha)
                        const fechaFiltro = filter[filtro]
                        return fechaMovimiento.toISOString().substring(0, 10) <= fechaFiltro.toISOString().substring(0, 10)
                    }

                    if (filtro === 'desde') {
                        const fechaMovimiento = new Date(filteredDataMovimiento[item].fecha)
                        const fechaFiltro = filter[filtro]
                        return fechaMovimiento.toISOString().substring(0, 10) >= fechaFiltro.toISOString().substring(0, 10)

                    }

                    return (filteredDataMovimiento[item][filtro]?.toLowerCase().includes(filter[filtro].toLowerCase()))
                })
                // se devuelve el objeto del vendedor que cumplio con el filtro
                .map(id => filteredDataMovimiento[id])
        )

    })

    // Finalmente se retorna los datos filtrados
    return (filteredDataMovimiento)

}


/**
 * @param dataList Lista de Objetos con datos a ser filtrados
 * @param filter Objeto con los filtros que debe ser iterado y filtrado el dataList
* */
export const filterComisiones = (dataList, filter) => {
    /**
     * Se asigna a la variables @filteredDataComision para poder ir modificando
     * */
    let filteredDataComision = dataList;

    /**
     * Se elimina los filtros vacios del objeto
     * Dejando un objeto solo con los atributos con valores asignados.
     * */
    for (let key in filter) {
        if (filter[key] === '') {
            delete filter[key]
        }
    }

    Object.keys(filter).forEach(filtro => {
        /**
         * Si el Filtro es distinta al valor TODAS procedera a filtrar, ya que TODAS implica que debe devolver el objeto
         * tal cual se mando inicialmente
         * */
        (filter[filtro] !== 'TODAS') && (
            filteredDataComision =
            // se obtiene array de keys para poder iterar el objeto
            Object.keys(filteredDataComision)
                // Se verifica si el objeto del vendedor cumple con el filtro seleccionado
                .filter(item => {

                    if (filtro === 'hasta') {
                        const fechaComision = new Date(filteredDataComision[item].fecha)
                        const fechaFiltro = filter[filtro]
                        return fechaComision.toISOString().substring(0, 10) <= fechaFiltro.toISOString().substring(0, 10)
                    }

                    if (filtro === 'desde') {
                        const fechaComision = new Date(filteredDataComision[item].fecha)
                        const fechaFiltro = filter[filtro]
                        return fechaComision.toISOString().substring(0, 10) >= fechaFiltro.toISOString().substring(0, 10)
                    }

                    return (filteredDataComision[item][filtro]?.toLowerCase().includes(filter[filtro].toLowerCase()))
                })
                // se devuelve el objeto del vendedor que cumplio con el filtro
                .map(id => filteredDataComision[id])
        )

    })

    // Finalmente se retorna los datos filtrados
    return filteredDataComision
}

export const formatFiltro = (fecha) => {
    let a = fecha.substring(6)
    let m = fecha.substring(3, 5)
    let d = fecha.substring(0, 2)
    let newFecha = a + '-' + m + '-' + d

    return newFecha

}

/**
 * Función utilizada para dividir en partes iguales las listas de registros
 * y retorne el slice correspondiente a una paginación
 *
 * @param data Lista de registros completa
 * @param page representa el número de página del componente paginador, que retorna la parte
 * del slice que representa a esa paginación
 * */
export const takeData = (data, page) => {

    const initialRange = (page === 1 ? 0 : ((page - 1) * 20));
    const FinalRange = 20 * page;

    return Object.keys(data).filter(item => {
        return Object.keys(data).slice(initialRange, FinalRange).includes(item)
    }).map(item => data[item])

}

export const dinamicTake = (data, page, takeLimit) => {
    const initialRange = (page === 1 ? 0 : ((page - 1) * takeLimit));
    const FinalRange = takeLimit * page;
    return Object.keys(data).filter(item => {
        return Object.keys(data).slice(initialRange, FinalRange).includes(item)
    }).map(item => data[item])
}

export const takeDataSearch = (data, page) => {
    const initialRange = (page === 1 ? 0 : ((page - 1) * 10));
    const FinalRange = 1000000;

    return Object.keys(data).filter(item => {
        return Object.keys(data).slice(initialRange, FinalRange).includes(item)
    }).map(item => data[item])

}

export const sortData = (data, order) => {

    let modo = ''
    // let campo =''

    Object.keys(order).forEach(item => {
        if (order[item].includes('asc')) {
            modo = 'asc'
        } else {
            modo = 'des'
        }

    })

    if (modo === 'asc') {
        Object.keys(order).forEach(item => {
            if (order[item].includes('desc')) {

                data.sort(function (a, b) {

                    if (a[item] > b[item]) {
                        return 1;
                    }
                    if (a[item] < b[item]) {
                        return -1;
                    }

                    return 0;
                });
            }
        })

    } else {
        Object.keys(order).forEach(item => {

            if (order[item].includes('asc')) {
                data.sort(function (b, a) {
                    if (a[item] > b[item]) {
                        return 1;
                    }
                    if (a[item] < b[item]) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
            }

        })
    }
    return data

}

export const formatPep = (data) => {
    let pep = ''
    if (data === false) {
        pep = 'NO'
    } else {
        pep = 'SI'
    }
    return pep
}

export const formatEstado = (data) => {
    if (data === false) {
        return 'INACTIVO'
    } else {
        return 'ACTIVO'
    }
}

export const formatModificacion = (data) => {

    if (data === null) {
        return null
    } else {
        return data.substring(0, 10)
    }

}

export const formatDiviceData = (data) => {

    let obData = {}

    data?.forEach(item => {
        obData = {
            ...obData,
            [item?.id]: {
                firma: item.firma,
                modelo: parseData(item.descripcion, "modelo"),
                marca: parseData(item.descripcion, "marca"),
                sistem_name_and_version_os: parseData(item.descripcion, "infosistem"),
                versioncode: parseData(item.descripcion, "version_code"),
                activo: item.activo === false ? <div className="box-pending">INACTIVO</div> : parseData(item.activo, "estado"),
                fechaActualizacion: dateUpdate(item.fechaActualizacion, item?.activo),
                estado: item.activo
            }
        }
    })

    return obData
}

export const dateUpdate = (data, activo) => {

    //formatear T00:00:00.000Z a dd/mm/yyyy hh:mm:ss AM/PM
    let fecha = data.substring(0, 10)
    let hora = data.substring(11, 19)
    let horaFormat = hora.substring(0, 2)
    let minuto = hora.substring(3, 5)
    let segundo = hora.substring(6, 8)
    let fechaFormat = fecha.substring(8, 10) + '/' + fecha.substring(5, 7) + '/' + fecha.substring(0, 4)
    let horaFormato = horaFormat + ':' + minuto + ':' + segundo + ' hs.'

    if (activo === false) {
        return fechaFormat + ' ' + horaFormato
    }

    else if (activo === true) {
        return " - "
    }

}

export const parseData = (data, datatype) => {
    if (data) {
        const dataDivice = data ? JSON.parse(data) : ''

        if (datatype === "marca") {
            return (
                dataDivice?.marca
            )
        }
        if (datatype === "modelo") {
            return (
                dataDivice?.modelo
            )
        }
        if (datatype === "infosistem") {
            return (
                dataDivice?.nombreSistema + " " + dataDivice?.versionSistema
            )
        }
        if (datatype === "estado" && dataDivice === true) {
            return <div className="box-reg">ACTIVO</div>
        }
        if (datatype === "version_code") {
            return dataDivice.versionCode + " - " + dataDivice.versionDesc
        }
    }
}



