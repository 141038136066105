import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { VALIDAR_CORREO_VENDEDOR } from "../../utils/queries/Queries";
import { BounceLoader } from "react-spinners";
import { override } from "../../utils/helpers";

const ValidarCorreoVendedor = (props) => {

    const initialState = {
        variables: {
            icono: 'add',
            nombre: ' ',
            color: '25,91,171',
            categoriaId: ' ',
        },
        loading: true,
        color: '#323980',
    }

    const [state, setState] = useState(initialState);

    const { search } = useLocation()
    const query = new URLSearchParams(search)

    const [id, setId] = useState(query.get("id"));
    const [token, setToken] = useState(query.get("token"));

    const { data, loading, error } = useQuery(VALIDAR_CORREO_VENDEDOR, {
        variables: {
            id: id,
            token: token
        }
    });

    useEffect(() => { }, [data])
    if (data) {
        return (
            <div className="data-confirm-mail">

                {data.validarCorreoVendedor ?
                    <div className="Confirm-data">
                        <span className="icon-mail"><ion-icon name="checkmark-circle-outline"></ion-icon></span>
                        <p className="mt-3"> Su correo ha sido confirmado</p>
                    </div>
                    :
                    <div className="Confirm-data">
                        <span className="icon-mail-error"><ion-icon name="alert-circle-outline"></ion-icon></span>
                        <p className="mt-3"> No se pudo confirmar su correo</p>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <>
                <div className="data-confirm-mail">
                    <div className="Confirm-data">
                        <div className='my-5 py-5'>
                            <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                        </div>
                        <h1> Cargando...</h1>
                    </div>
                </div>

            </>
        )
    }

}

export default ValidarCorreoVendedor