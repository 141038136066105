import { useQuery } from "@apollo/client";
import { Fragment, useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { filterDataListNotification, formatDataNotification, override, takeDataSearch } from "../../utils/helpers";
import { GET_NOTIFICATIONS } from "../../utils/queries/Queries";
import Table from "../common/Table";
import TableSearch from "../common/TableSearch";

const TableNotifications = (props) => {

    const initialState = {
        filtros: {
            titulo: '',
            estado: '',
            rol: '',
        },

        variables: {
            producto: 'BOMO'
        },

        headers: {
            titulo: 'Titulo',
            contenido: 'Contenido',
            idPrioridad: 'Prioridad',
            estado: 'Estado',
            actions: 'Acciones',
        },
    }

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))
    }, [props.filtros])

    const [state, setState] = useState(initialState);

    const { data, loading, error } = useQuery(GET_NOTIFICATIONS,
        {
            variables: { producto: state?.variables?.producto }
        }
    )

    //foramteamos los datos obtenidos
    const notificactionDataFormat = formatDataNotification(data?.getContenidos)

    return (
        <Fragment>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {error && (
                <h6>
                    {`Error! ${error.message}`}
                </h6>
            )}
            {data && (
                <Fragment>
                    {props?.filtros?.titulo ?
                        <TableSearch headers={state.headers} data={filterDataListNotification(notificactionDataFormat, state.filtros)} />
                        :
                        < Table headers={state.headers} data={takeDataSearch(filterDataListNotification(notificactionDataFormat, state.filtros), state.currentPage)} currentPage={state.currentPage} />
                    }
                </Fragment>
            )}
        </Fragment>
    )
}

export default TableNotifications;