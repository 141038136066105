import React, { Fragment, useState } from 'react'
import { filterComisiones, formatComisiones, override, takeDataSearch } from "../../../utils/helpers";
import { useQuery } from "@apollo/client";
import { BounceLoader } from 'react-spinners';
import { GET_VENDEDOR_COMISIONES, GET_VENDEDOR_MOVIMIENTOS } from '../../../utils/queries/Queries';
import TablaDetalle from '../../common/Tabla/TablaDetalle';
import SearchTable from '../../common/SearchTable';
import FiltroComisiones from '../Tablas/FiltroComisiones';
import TabSeachComis from './TabSeachComis';
import DatePicker from 'react-date-picker';


const Comisiones = (props) => {

    //fecha auxiliar para fecha de inicio
    const fechaAux = new Date()
    //fecha auxiliar para fecha fin
    const dateData = new Date();
    //dateData.setDate(dateData.getDate());
    dateData.setDate(dateData.getDate());

    //declarampos los valores iniciales para nuestro filtro
    const initialState = {
        filtros: {
            estado: '',
            desde: '',
            hasta: '',
        },
        variables: {
            idVendedor: props.vendorID,
            actividad: 'COMIS',
            fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            fechaFin: dateData.toISOString().slice(0, 10)
        },
        color: '#323980',
    }

    //inicalizamos los valores de los filtros
    const [state, setState] = useState(initialState);

    //declaramos la cabecera de nuestra tabla en cuestion
    const cabecera = {
        fecha: {
            id: 1,
            label: 'FECHA',
            order: 'desc',
            status: false
        },
        concepto: {
            id: 2,
            label: 'CONCEPTO',
            order: 'desc',
            status: false
        },
        estado: {
            id: 3,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        acreditadoNetel: {
            id: 4,
            label: 'ESTADO DE PAGO NETEL',
            order: 'desc',
            status: false
        },
        acreditadoCabecera: {
            id: 5,
            label: 'ESTADO DE PAGO BOMO',
            order: 'desc',
            status: false
        }
        ,
        monto: {
            id: 6,
            label: 'MONTO',
            order: 'desc',
            status: false
        },
    }

    //consultamos los datos correspondientes a comisiones del vendedor
    const { data, loading, error } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            fechaInicio: state?.variables?.fechaInicio,
            fechaFin: state?.variables?.fechaFin,
            actividad: state.variables?.actividad
        }
    });

    // todo: ver funcionamiento de manejo de filtro y ordenamiento
    // const handleSort = ()=>{
    //
    // }
    //
    // const handleFilter = ()=>{
    //
    // }

    //guardamos los datos de las comisiones en un arrelgo de objetos
    const aComisiones = data?.movimientos ? data?.movimientos : []

    //para mandar los valores al filtro actual y que el helper filtre por la misma
    const handleFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...prevState.filtros,
                    [data.key]: data.value
                }
            }
        })
    }

    //onchange del componente de busqueda
    const handleSearch = data => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...prev.filtros,
                concepto: data,
            }
        }))
    }

    //para limpiar los valores de los filtros
    const onCleanFilter = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...data,
                },
                variables: {
                    ...prevState.variables,
                    fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
                    fechaFin: dateData.toISOString().slice(0, 10)
                }
            }
        })
    }

    //formateamos el listado de comisiones
    const formatedData = formatComisiones(aComisiones)

    //formateo de fechas para mostrar en datepicker
    const formatDateFin = new Date(state.variables.fechaFin)
    const formatDateInicio = new Date(state.variables.fechaInicio)

    formatDateFin.setDate(formatDateFin.getDate() + 1)
    formatDateInicio.setDate(formatDateInicio.getDate() + 1)

    //funcion para setea fecha fin y setear en el formato correspondiente
    const handleDateChange = (data) => {

        const Fecha = new Date(data).toISOString().slice(0, 10)

        setState(prevState => (
            {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    fechaFin: new Date(Fecha).toISOString().slice(0, 10),
                }
            }
        ))
    }

    //funcion para setea fecha inicio y setear en el formato correspondiente
    const handleDateHastaChange = (data) => {
        const Fecha = new Date(data).toISOString().slice(0, 10)
        setState(prevState => (
            {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    fechaInicio: new Date(Fecha).toISOString().slice(0, 10),
                }
            }
        ))
    }

    //capturando el error de la consulta y se convierte el mensaje para mostrar
    const errorMessageComision = JSON.stringify(error?.message)

    return (
        <Fragment>

            <div className="columns">
                <div className="column is-6" id="searchMov">
                    <SearchTable
                        placeholder='Buscar concepto...'
                        handleChange={handleSearch}
                    />
                </div>
            </div>
            {loading &&
                'cargando...'
            }
            <div className="columns">
                <div className='column is-5 d-flex'>
                    <div className="item-column dateTime">
                        <label className="mb-1" htmlFor="">Fecha inicio:</label>
                        <DatePicker
                            clearIcon={null}
                            onChange={handleDateHastaChange}
                            value={formatDateInicio}
                            className='date-input' />
                    </div>
                    <div className="item-column dateTime">
                        <label className="mb-1" htmlFor="">Fecha fin:</label>
                        <DatePicker
                            clearIcon={null}
                            onChange={handleDateChange}
                            value={formatDateFin}
                            className='date-input' />
                    </div>
                </div>
                <div className='column is-7'>
                    <FiltroComisiones onCleanFilter={onCleanFilter} getFilter={handleFilter} />
                </div>
            </div>
            <BounceLoader color={state.color} loading={loading} css={override} size={50} />
            {error && (
                <div className='errorMsgg'>
                    {errorMessageComision}
                </div>
            )}
            {data && aComisiones?.length > 0 &&
                <Fragment>
                    {
                        state?.filtros?.concepto ?
                            <TabSeachComis
                                cabecera={cabecera}
                                detalles={(filterComisiones(formatedData, state.filtros))} />
                            :
                            <TablaDetalle
                                filtros={state?.filtros}
                                cabecera={cabecera}
                                detalles={takeDataSearch(filterComisiones(formatedData, state.filtros), state.currentPage)}
                                currentPage={state.currentPage}
                            />
                    }
                </Fragment>
            }
            {data && aComisiones?.length === 0 && (
                <div className='Columns' id='nodatamov'>
                    <div className='Column'>
                        <p className='mt-5 mb-5'>El vendedor no posee comisiones</p>
                    </div>
                </div>
            )}


        </Fragment>

    )
}

export default Comisiones