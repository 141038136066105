import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from "@apollo/client";
import {
    filterDataList,
    filterDataListServicio,
    formatDataCategory,
    formatDataFacturadores,
    override, takeData, takeDataSearch
} from "../../../utils/helpers";
import Table from "../../common/Table";
import { PuffLoader } from "react-spinners";
import { Pagination } from 'react-bulma-components';
import TableSearch from '../../common/TableSearch';
import { formatDemandasVendedores } from '../../../utils/helpers';
import { GET_INFORME_DETALLADO_BY_ID } from '../../../utils/queries/Queries';



const DemandasTable = (props) => {

    //declaramos las variables que utilizaremos
    const [state, setState] = useState(
        {
            filtros: {
                nombre: '',
            },
            headers: {
            },
            loading: true,
            color: '#323980',
            currentPage: 1,
        }
    );
    

    // Headers de la tabla
    const headers = {
        fechaOficio: 'FECHA OFICIO',
        nroCausa: 'NRO CAUSA',
        caratula: 'CARATULA',
        estado: 'ESTADO PROCESAL',
        demandante: 'DEMANDANTE',
        monto: 'MONTO',
        nombreJuzgado: 'NOMBRE JUZGADO',
        secretaria: 'SECRETARIA',
        folio: 'FOLIO'
    }

    //seteamos los filtros a ser utlizamos
    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))
    }, [props.filtros])

    const { data, loading, error } = useQuery(GET_INFORME_DETALLADO_BY_ID, {
        variables: {
            idVendedor: props?.vendedorID,
        }
    })

    if (error) {
        console.log('Testing ERROR: ', typeof error)
        return `Error! ${error.message}`
    }

    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    /**accedmos a listado antecedentes y contamos cantidad */
    const lengthDemandas = data ? data?.obtenerInformeDetallado?.informe?.data?.Demandas?.length : ''
    const prueba = data ? data?.obtenerInformeDetallado?.informe?.data?.Demandas : ''
    console.log(prueba)

    return (
        <div>
            <div className="columns">
                <div className='column is-3 text-al-ini'>
                    <h1 className="title-details ml-5 pt-3">Demandas ({lengthDemandas})</h1>
                </div>
            </div>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {data && lengthDemandas > 0 && (
                <Table headers={headers} data={takeDataSearch(filterDataList(formatDemandasVendedores(data), state.filtros), state.currentPage)} />
            )}
            {data && lengthDemandas <= 0 && (
                <div className='Columns' id='nodatamov'>
                    <div className='Column'>
                        <p className='mt-5'>El vendedor no posee demandas</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DemandasTable;