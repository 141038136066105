import React, {useState} from 'react'
import Navigation from "../common/navigation";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SearchTable from "../common/SearchTable";
import CategoriaTable from "./CategoriaTable";
import ModalContainer from "../common/ModalContainer";
import FormInsertarCategoria from "./FormInsertarCategoria";
import {handleToggleModal} from "../../actions/modal";
import ProtectedComponent from "../common/ProtectedComponent";


const Categorias = (props)=>{

    const [state, setState] = useState({
        modalStatus: false,
    });

    const handleShowModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                modalStatus: !prevState.modalStatus
            }
        })
    }

    const onHandleToggleModal = ()=>{
        setState(prev=>({
            modalStatus: !prev.modalStatus,
        }))
        props.dispatch(handleToggleModal())
    }

    const handleSearch = data =>{
        setState(prev=>({
            ...prev,
            filtros:{
                ...prev.filtros,
                nombre: data,
            }
        }))
    }



    const formCategoria =()=>{
        return <FormInsertarCategoria handleShowModal={handleShowModal}/>
    }

    const {sidebarStatus} = props
    return (
        <div className='categorias-dashboard'>

            {props.modal.modalStatus &&(
                <ModalContainer
                    title='CATEGORIA'
                    form={formCategoria()}
                    modalStatus={state.modalStatus}
                    handleShowModal={handleShowModal}
                />
            )}


            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    

                    <section className="section content-box pd-un">
                        <div className="columns">
                            <div className="column is-3 text-al-ini"><h1 className="title-details ml-5 pt-3">Lista de categorías</h1></div>
                            <div className='column is-6'>
                                <SearchTable
                                placeholder='Buscar una categoría...'
                                handleChange={handleSearch} />
                            </div>
                            <div className="column is-3">
                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR']}>
                                    <button
                                        className="button btn-primary"
                                        onClick={ ()=>onHandleToggleModal()}>
                                        <FontAwesomeIcon className="mr-2" icon={['fas', 'plus']} color='#FFF'  />
                                         Añadir categoría
                                    </button>
                                </ProtectedComponent>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="column is-full">

                                    <CategoriaTable filtros={state.filtros} />

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </main>

        </div>

    )

}

function mapStateToProps({authedUser, sidebarStatus, modal}){
    return{
        authedUser,
        sidebarStatus,
        modal
    }
}
export default connect(mapStateToProps)(Categorias);