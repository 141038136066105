import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import ModalContainer from "../common/ModalContainer";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_VENDEDOR } from "../../utils/queries/Queries";
import VendedorDatos from "./VendedorDatos";
import ComisionTabla from "./Tablas/ComisionTabla"
import TransaccionTabla from "./Tablas/TransaccionTabla";
import FacturaTabla from "./Tablas/FacturaTabla";
import FormKYC from "./Forms/FormKYC"
import { handleToggleModal } from "../../actions/modal";
import FormEstadoCuenta from "./Forms/FormEstadoCuenta";
import FormDatosContactos from "./Forms/FormDatosContactos";
import VendedorKycTabla from "./Tablas/VendedorKycTabla";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BounceLoader } from "react-spinners";
import { override } from "../../utils/helpers";
import FormUpdate from "./Forms/FormUpdate";
import VistaImagen from './Forms/VistaImagen';
import VistaImagenDorso from './Forms/VistaImagenDorso';
import VistaSelfie from './Forms/VistaSelfie';
import { Button, Dropdown } from 'react-bulma-components';
import Movimientos from './Forms/Movimientos';
import Comisiones from './Forms/Comisiones';
import FacturaTablaEnviadas from './Tablas/FacturaTablaEnviadas';
import ProtectedComponent from '../common/ProtectedComponent';
import VistaConstancia from './Forms/VistaConstancia';
import FormPEP from './Forms/FormPEP';
import FormCI from './Forms/FormCI';
import DatosVendedorBasico from './Tablas/DatosVendedorBasico';
import FacturadoresTable from '../Facturadores/FacturadoresTable';
import VendedorRelaciones from './VendedorRelaciones';
import AntecedentesTable from './Tablas/AntecedentesTable';
import PublicacionesTable from './Tablas/PublicacionesTable';
import DemandasTable from './Tablas/DemandasTable';


const VendedorDetalleInforme = props => {

    const idOperador = props?.authedUser?.idOperador

    //declaramos las variables
    // const initialState = {
    //     tab: {
    //         comisiones: true,
    //         transacciones: false,
    //         facturas: false,
    //         adjuntas: false,
    //         kyc: false,
    //     },
    //     form: 'kyc',
    //     color: '#323980',
    //     boton: 'PENDIENTES'
    // }
    const [statef, setStateF] = useState({
        filtros: {

        }
    });
    const initialState = {
        tab: {
            basicos: true,
            relacionesPersonales: false,
        },
        form: 'kyc',
        color: '#323980',
        boton: 'PENDIENTES'
    }

    //incializamos
    const [state, setState] = useState(initialState)
    const [informe, setInforme] = useState(false)


    // todo: Comentado mientras no se dinamiza la carga de id
    const { id } = useParams();

    const { sidebarStatus } = props;



    //obtenemos los vendedores
    const { data, loading, error } = useQuery(GET_VENDEDOR, {
        variables: {
            idVendedor: id,
        }
    })

    // funcion para cambiar el state y actualizar la vista de tabs
    const onHandleClick = e => {

        e.preventDefault()
        setState(prev => {
            const updated = {}
            Object.keys(prev.tab).forEach(item => {
                updated[item] = false
            })
            updated[e.target.id] = true

            return {
                tab: {
                    ...updated,
                }

            }
        })
    }

    const handleModalForm = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('edit'))
    }

    const handleView = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('view'))
    }

    const handleUpdate = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('update'))
    }

    // para disparar el modal correspondiente al listado de los movimientos
    const handleFormView = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('ver'))
    }

    // para disparar el modal correspondiente al listado de las comisiones
    const handleFormViewComisiones = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form
            }
        })
        props.dispatch(handleToggleModal('ver'))
    }

    const handleViewInforme = (form) => {
        // setState(prev => {
        //     return {
        //         ...prev,
        //         form: form
        //     }
        // })
        // props.dispatch(handleToggleModal('ver'))
    }

    //guardamos el nombre y apellido del vendedor para utilizar a la hora de exportar
    const vendorDataName = data?.vendedor?.nombres + " " + data?.vendedor?.apellidos

    const pickForm = () => {
        switch (state.form) {
            case 'kyc':
                return <FormKYC idVendedor={data?.vendedor?.id} estadoKYC={data?.vendedor?.estadoKYC} />
            case 'cuenta':
                return <FormEstadoCuenta idVendedor={data?.vendedor?.id} estadoCuenta={data?.vendedor?.estadoCuenta} />
            case 'datos':
                return <FormDatosContactos idVendedor={data?.vendedor?.id} contactos={data?.vendedor?.contactos} />
            case 'update':
                return <FormUpdate idVendedor={data?.vendedor?.id} idOperador={idOperador} />
            case 'FRENTE':
                return <VistaImagen idVendedor={data?.vendedor?.id} />
            case 'DORSO':
                return <VistaImagenDorso idVendedor={data?.vendedor?.id} />
            case 'SELFIE':
                return <VistaSelfie idVendedor={data?.vendedor?.id} />
            case 'MOVIMIENTOS':
                return <Movimientos vendorID={id} vendorData={vendorDataName} />
            case 'COMISIONES':
                return <Comisiones vendorID={id} />
            case 'CONSTANCIA':
                return <VistaConstancia vendorID={id} />
            case 'PEP':
                return <FormPEP idVendedor={data?.vendedor?.id} estadoPEP={data?.vendedor?.pep} cedulaVencimiento={data?.vendedor.fechaVencimientoDocumento} />
            case 'CI':
                return <FormCI idVendedor={data?.vendedor?.id} estadoPEP={data?.vendedor?.pep} cedulaVencimiento={data?.vendedor.fechaVencimientoDocumento} />
            default:
                return ''
        }
    }

    const [botonTab, setBotonTab] = useState({ btnPendiente: true, btnEnviadas: false })

    //funcion para previsualizar pdf en adjuntas
    const previsualizarPdf = (dataPdf) => {
        return (
            <div className=''>
                <object className='viewerDoc' data={dataPdf} type="application/pdf" width="280" height="150" >
                    <embed src={dataPdf} type="application/pdf" />
                </object>
            </div>
        )
    }

    return (

        // <div></div>
        <div className="vendedor-page">
            {props.modal.modalStatus && (
                <ModalContainer title={state.form} form={pickForm()} />
            )}
            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    {!loading ? (
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li>
                                    <Link to="/Dashboard">Vendedores</Link>
                                </li>
                                <li className="is-active">
                                    <Link to="/" aria-current="page">
                                        {data?.vendedor?.nombres} {data?.vendedor?.apellidos}
                                    </Link>
                                </li>
                                <li className="is-active">
                                    <Link to="/" aria-current="page">
                                        Informe detallado
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    ) : 'Cargando...'}

                    <section className="hero mt-4 mb-5">
                        <div className="hero-foot">
                            <nav className="tabs is-boxed is-fullwidth">
                                <div className="">
                                    <ul>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'OPERADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.basicos ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='basicos' onClick={e => onHandleClick(e)}>BASICOS</a>
                                            </li>
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'OPERADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.relacionesPersonales ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='relacionesPersonales' onClick={e => onHandleClick(e)}>RELACIONES PERSONALES</a>
                                            </li>
                                        </ProtectedComponent>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className=" section">
                            <div id="Basicos" className={state.tab.basicos ? 'content-tab' : 'content-tab is-hidden'}>
                                {state.tab.basicos &&
                                    <>
                                        <DatosVendedorBasico vendorID={id} />
                                    </>
                                }
                            </div>
                            <div id="RelacionesPersonales" className={state.tab.relacionesPersonales ? 'content-tab' : 'content-tab is-hidden'} >
                                {state.tab.relacionesPersonales &&
                                    <>
                                        <VendedorRelaciones filtros={state.filtros} vendedorID={id} />
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                    <section className="section content-box pd-un mb-5">
                        <div className="columns">
                            <div className="column">
                                <div className="column is-full">
                                    <AntecedentesTable filtros={state.filtros} vendedorID={id} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section content-box pd-un mb-5">
                        <div className="columns">
                            <div className="column">
                                <div className="column is-full">
                                    <PublicacionesTable filtros={state.filtros} vendedorID={id} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section content-box pd-un mb-5">
                        <div className="columns">
                            <div className="column">
                                <div className="column is-full">
                                    <DemandasTable filtros={state.filtros} vendedorID={id} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    )
}

function mapStateToProps({ sidebarStatus, modal, authedUser }) {
    return {
        sidebarStatus,
        modal,
        authedUser
    }
}

export default connect(mapStateToProps)(VendedorDetalleInforme)