import {getInitialData} from '../utils/api'
// import {receiveUsers} from './users'
import {setAuthedUser} from './authedUser'
import {setSidebarStatus} from "./sidebar";
import {setModalStatus} from "./modal";

// import { showLoading, hideLoading } from 'react-redux-loading'

const AUTHED_ID = ''
const INITIAL_SIDEBAR = 'open'
const INITIAL_MODAL = false

const userInitialData = localStorage.getItem('payload') ? JSON.parse(localStorage.getItem('payload')) :{
    id: AUTHED_ID,
    token: '',
    rol: '',
    nombre: '',
    apellido: '',
    correo: '',
    idOperador: '',
    fechaActualizacion:''
}


export function handleInitialData() {
    return (dispatch) => {
        // dispatch(showLoading())
        return getInitialData()
            .then(({users, tweets}) => {
                dispatch(setSidebarStatus(INITIAL_SIDEBAR))
                dispatch(setModalStatus(INITIAL_MODAL))
                //dispatch(receiveUsers(users))
                dispatch(setAuthedUser(userInitialData))
                // dispatch(hideLoading())
            })
    }
}