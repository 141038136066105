import React, { Fragment, useState } from 'react'
import { useMutation } from "@apollo/client";
import { EDIT_KYC_VENDEDOR } from "../../../utils/mutators/Mutators";
import { connect } from "react-redux";
import { handleToggleModal } from "../../../actions/modal";
import { ReactComponent as CheckIcon } from "../../../assets/images/check.svg";
import { GET_VENDEDOR } from "../../../utils/queries/Queries";
import { BounceLoader } from "react-spinners";
import { override } from "../../../utils/helpers";
import NotificationError from '../../common/NotificationError';

const FormKYC = props => {

    const initialState = {
        selectOption: {
            AVANZADO: 'Avanzado',
            BASICO: 'Basico',
            MEDIO: 'Medio',
            PENDIENTE: 'Pendiente',
            RECHAZO_PERM: 'Rechazo Permanente',
            RECHAZO_TEMP: 'Rechazo Temporal'
        },
        kyc: props.estadoKYC ? props.estadoKYC : '',
        comentario: '',
        idOperador: props.authedUser.idOperador ? props.authedUser.idOperador : '',
        idVendedor: props.idVendedor,
        buttonDisabled: false,
        color: '#323980',
    }

    const [state, setState] = useState(initialState)
    const [hide, setHide] = useState(false)

    const handleChange = e => {
        setState(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const [ModificarEstadoKYCVendedor, { data, loading, error, reset }] = useMutation(EDIT_KYC_VENDEDOR, {
        refetchQueries: [
            GET_VENDEDOR, // DocumentNode object parsed with gql
            'Get Vendedor' // Query name
        ],
    })

    const handleSubmit = () => {
        ModificarEstadoKYCVendedor({
            variables: {
                estadoKyc: {
                    estado: state.kyc,
                    comentario: state.comentario,
                    idVendedor: state.idVendedor,
                    idOperador: state.idOperador
                }
            }
        }).then(r => {
            console.log('Rpta Edicion KYC', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edicion KYC-> ' + reason);
            });
    }

    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
        reset()
    }

    const handleHide = () =>{
        setHide(true)
    }

    return (
        <Fragment>
            {error && (
                <NotificationError message={error?.message} handleHide={handleHide} />
            )}
            {!loading && (
                !data && (
                    <form>
                        <div className='is-fullwidth'>
                            <label htmlFor="" className='mb-2'>Seleccionar KYC:</label>
                            <div className="select is-fullwidth">
                                <select
                                    name='kyc'
                                    value={state.kyc}
                                    onChange={e => handleChange(e)}
                                >
                                    <option value=''>Seleccion un estado</option>
                                    {Object.keys(state.selectOption).map(item =>
                                        (<option key={item} value={item}>{state.selectOption[item]}</option>)
                                    )}


                                </select>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <label htmlFor="">Agregar comentario:</label>
                            <div>
                                <textarea
                                    name='comentario'
                                    onChange={e => handleChange(e)}
                                    className="textarea"
                                    placeholder="Agregar motivo del cambio"
                                    value={state.comentario}> </textarea>
                            </div>
                        </div>
                    </form>
                )
            )}
            {loading && (
                <div className='my-5 py-5'>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                </div>
            )}
            {!data
                ? (
                    <footer className="modal-card-foot pb-0">

                        <button

                            onClick={() => handleSubmit()}
                            className={loading ? 'button is-normal btn-enviar is-loading' : 'button is-normal btn-enviar'}>Guardar</button>
                        <button
                            onClick={() => handleCloseModal()}
                            className="button btn-cancelar" data-bulma-modal="close">Cancelar
                        </button>
                    </footer>
                )
                : (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>KYC editado correctamente</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    handleCloseModal()
                                }}
                                className='button is-normal btn-enviar'>
                                Aceptar
                            </button>
                        </footer>
                    </div>

                )
            }

        </Fragment>
    )
}

function mapStateToProps({ authedUser }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(FormKYC);