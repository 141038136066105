import jwt from 'jwt-decode';

export const SET_AUTHED_USER = 'SET_AUTHED_USER'

    export function setAuthedUser (loggedUser){

    return{
        type: SET_AUTHED_USER,
        authedUser: {
            ...loggedUser,
        }
    }
}


export function handleSetAuthedUser(id, token, refresh){
    const user = jwt(localStorage.getItem('token') ? localStorage.getItem('token') : token); // decode your token here
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('payload', JSON.stringify({
        ...user.payload,
        id: id,
        token: token,
    }));

    // console.log('check sethAthUser', JSON.parse(localStorage.getItem('payload')))
    // console.log('ENTRA AQUI AL RECARGAR?????')

    return (dispatch) =>{
        dispatch(setAuthedUser(
            {
                id: id,
                token: localStorage.getItem('token') ? localStorage.getItem('token') : token,
                ...user.payload,
            }))
    }
}

// export function handleAddPoll (newPoll) {
//     return (dispatch) => {
//         const { authedUser, optionOne, optionTwo } = newPoll
//
//         //dispatch(showLoading())
//
//
//
//
//         return saveQuestion({
//             author: authedUser,
//             optionOneText: optionOne,
//             optionTwoText: optionTwo,
//         })
//             .then((poll) => {
//                 dispatch(addPoll(poll))
//                 dispatch(addPollToUser(authedUser, poll.id))
//             })
//         // .then(() => dispatch(hideLoading()))
//     }
// }