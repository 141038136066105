import React, { Fragment, useState } from 'react'
import { GET_VENDEDOR_MOVIMIENTOS } from '../../../utils/queries/Queries';
import { DataExport, filterMovimientoList, formatVentas, override, takeData, takeDataSearch } from '../../../utils/helpers';
import { useQuery } from '@apollo/client';
import FiltroMovimientos from '../Tablas/FiltroMovimientos';
import SearchTable from '../../common/SearchTable';
import { PuffLoader } from 'react-spinners';
import TabModalMovimientos from '../../common/Tabla/TabModalMovimientos';
import DatePicker from 'react-date-picker';
import toast, { Toaster } from 'react-hot-toast';
import TableSearchMov from '../Tablas/TableSearchMov';

const Movimientos = (props) => {

    //declarmos y asignamos variables a props recibidos
    const dataName = props?.vendorData
    const dataId = props?.vendorID

    //fecha auxiliar para fecha de inicio
    const fechaAux = new Date()
    //fecha auxiliar para fecha fin
    const dateData = new Date();
    //dateData.setDate(dateData.getDate());
    dateData.setDate(dateData.getDate());

    //inicializamos los valores de los filtros y variables
    const initialState = {

        filtros: {
            estado: '',
            desde: '',
            hasta: '',
            actividad: '',
            concepto: '',
        },
        variables: {
            fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            fechaFin: dateData.toISOString().slice(0, 10)
        },
        currentPage: 1,
    }

    //inicalizamos nuestras variables
    const [state, setState] = useState(initialState);

    //para mandar los valores al filtro actual y que el helper filtre por la misma
    const handleFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...prevState.filtros,
                    [data.key]: data.value
                }
            }
        })
    }

    //la cabecera de nuestra tabla en cuestion
    const cabecera = {
        fecha: {
            id: 1,
            label: 'FECHA',
            order: 'desc',
            status: false
        },
        concepto: {
            id: 2,
            label: 'SERVICIO',
            order: 'desc',
            status: false
        },
        estado: {
            id: 3,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        monto: {
            id: 4,
            label: 'MONTO',
            order: 'desc',
            status: false
        },
        actividad: {
            id: 5,
            label: 'TIPO',
            order: 'desc',
            status: false
        },
    }

    //consultamos recurso de movimeintos para obtener movimientos tipo VENTA
    const { data: dataVenta, loading: loadingVenta, error: errorVenta } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            fechaInicio: state?.variables?.fechaInicio,
            fechaFin: state?.variables?.fechaFin,
            actividad: 'VENTA'
        }
    });
    //declaramos un arreglo de objetos para almacenar el listado de movimientos tipo venta
    const aVentas = dataVenta?.movimientos

    //consultamos recurso de movimientos para obtener movimientos tipo CARGA
    const { data: dataCarga, loading: loadingCarga, error: errorCarga } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            fechaInicio: state?.variables?.fechaInicio,
            fechaFin: state?.variables?.fechaFin,
            actividad: 'CARGA'
        }
    });
    //declaramos un arreglo de objetos para almacenar el listado de movimientos tipo venta
    const aCargas = dataCarga?.movimientos

    //consultamos recurso de movimientos para obtener movimientos tipo WALLY
    const { data: dataWally, loading: loadingWally, error: errorWally } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            fechaInicio: state?.variables?.fechaInicio,
            fechaFin: state?.variables?.fechaFin,
            actividad: 'WALLY'
        }
    });
    //declaramos un arreglo de objetos para almacenar el listado de movimientos tipo venta
    const aWally = dataWally?.movimientos
    //concatenamos todos los movimientos
    const aGeneral = aVentas?.concat(aCargas, aWally)
    // declaramos un array final y ordenamos por fecha para que sea descndente de mayor a menor fecha segun la fecha de transaccion
    const aFinal = aGeneral?.sort((a, b) => new Date(a.fechaSolicitud).getTime() - new Date(b.fechaSolicitud).getTime()).reverse()

    // todo: Agregar funcionalidad para manejo de ordenamiento de datos
    /*const handleSort = ()=>{

    }*/

    // todo: agregar funciona para manejador de filtros. Ver de deprecar tb
    /*const handleFilter = ()=>{

    }*/

    //onchange del componente de busqueda de servicios en la tabla
    const handleSearch = data => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...prev.filtros,
                concepto: data,
            },
            currentPage: 1,
        }))
    }

    //para limpiar los valores de los filtros
    const onCleanFilter = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...data,
                },
                variables: {
                    ...prevState.variables,
                    fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
                    fechaFin: dateData.toISOString().slice(0, 10)
                }
            }
        })
    }

    //formateamos los datos a mostrar en la tabla
    const formatedData = formatVentas(aFinal)
    //foramteo correspondiente a mostrar a la hora de exportar
    const formatedDataExport = DataExport(aFinal)

    //funcion para setea fecha fin y setear en el formato correspondiente
    const handleDateChange = (data) => {

        const Fecha = new Date(data).toISOString().slice(0, 10)

        setState(prevState => (
            {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    fechaFin: new Date(Fecha).toISOString().slice(0, 10),
                }
            }
        ))
    }

    //funcion para setea fecha inicio y setear en el formato correspondiente
    const handleDateHastaChange = (data) => {
        const Fecha = new Date(data).toISOString().slice(0, 10)
        setState(prevState => (
            {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    fechaInicio: new Date(Fecha).toISOString().slice(0, 10),
                }
            }
        ))
    }

    //formateo de fechas para mostrar en datepicker
    const formatDateFin = new Date(state?.variables?.fechaFin)
    const formatDateInicio = new Date(state?.variables?.fechaInicio)

    formatDateFin.setDate(formatDateFin.getDate()+1)
    formatDateInicio.setDate(formatDateInicio.getDate()+1)
    
    //capturando el error de la consulta y se convierte el mensaje para mostrar
    const errorMessageVenta = JSON.stringify(errorVenta?.message)

    return (
        <Fragment>
            <div className="columns">
                <div className="column is-6" id="searchMov">
                    <SearchTable
                        placeholder='Buscar servicio...'
                        handleChange={handleSearch}

                    />
                </div>
            </div>
            <div className="columns">
                <div className='column is-5 d-flex'>
                    <div className="item-column dateTime">
                        <label className="mb-1" htmlFor="">Fecha inicio:</label>
                        <DatePicker
                            clearIcon={null}
                            onChange={handleDateHastaChange}
                            value={formatDateInicio}
                            className='date-input' />
                    </div>
                    <div className="item-column dateTime">
                        <label className="mb-1" htmlFor="">Fecha fin:</label>
                        <DatePicker
                            clearIcon={null}
                            onChange={handleDateChange}
                            value={formatDateFin}
                            className='date-input' />
                    </div>
                </div>
                <div className='column is-7'>
                    <FiltroMovimientos onCleanFilter={onCleanFilter} getFilter={handleFilter} />
                </div>
            </div>
            {loadingVenta && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <p className='mt-4'>Cargando datos...</p>
                    </div>
                </div>
            )}
            {errorVenta && (
                <div className='errorMsgg'>
                    {errorMessageVenta}
                </div>
            )}
            {dataVenta && dataVenta?.movimientos?.length > 0 && (
                <Fragment>
                    {
                        state?.filtros?.concepto ?
                            <TableSearchMov
                                cabecera={cabecera} detalles={filterMovimientoList(formatedData, state.filtros)}
                                dataExport={(filterMovimientoList(formatedDataExport, state.filtros))}
                                dataName={dataName}
                                dataId={dataId}
                            />
                            :
                            <TabModalMovimientos
                                filtros={state?.filtros}
                                cabecera={cabecera}
                                detalles={takeDataSearch(filterMovimientoList(formatedData, state.filtros), state.currentPage)}
                                dataExport={takeDataSearch(filterMovimientoList(formatedDataExport, state.filtros), state.currentPage)}
                                currentPage={state.currentPage}
                                dataName={dataName}
                                dataId={dataId}
                            />
                    }
                </Fragment>
            )}
            {dataVenta && dataVenta?.movimientos?.length === 0 && (
                <div className='Columns' id='nodatamov'>
                    <div className='Column'>
                        <p className='mt-5 mb-5'>El vendedor no posee movimientos</p>
                    </div>
                </div>
            )}
        </Fragment>

    )
}

export default Movimientos