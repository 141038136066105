import React, { Fragment, useEffect, useState } from "react";
import FiltroTable from "./FiltroTable";
import SearchTable from "../common/SearchTable";
import { connect } from "react-redux";
import TableVendedores from "../common/TableVendedores";
import { formatDataVendedores, override} from "../../utils/helpers";
import { PuffLoader } from "react-spinners";
import TableSearch from "../common/TableSearch";
import { VENDEDORES } from "../../utils/queries/Queries";
import { useQuery, useLazyQuery} from "@apollo/client";

const Vendedores = (props) => {
    //declaramos los valores inicales a ser utilizadas
    const initialState = {
        vendors: [],
        filtros: {
            kyc: '',
            status: '',
            desde: '',
            hasta: '',
            nombre: '',
            documento: '',
        },
        headers: {
            nombre: 'Nombre y Apellido',
            documento: 'Nro. de CI',
            register: 'Fecha de Registro',
            kyc: 'Estado de Registro',
            status: 'Estado de cuenta',
            // city: 'Ubicación',
            modificacion: 'ÚLTIMA MODIFICACIÓN',
            pep: 'PEP',
            // modificacionFormat: 'Modificacion Format'
            //registerFormat:'format'
        },
        loading: true,
        color: '#323980',
        currentPage: 1,
        paginacion: {
            order: "DESC",
            orderBy: "FECHA_REGISTRO",
            step: 1,
            take: 10
        }
    }

    //inicializamos las variables
    const [state, setState] = useState(initialState);
    const dashboardRef = React.createRef();

    //onchange correspondiente para hacer la busqueda 
    const handleSearch = data => {

        if (data === '') {
            setState(prev => ({
                ...prev,
                filtros: {
                    ...prev.filtros,
                    nombre: '',
                    documento: '',
                },
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }))
        }
        if (isNaN(data)) {
            setState(prev => ({
                ...prev,
                filtros: {
                    ...prev.filtros,
                    nombre: data.toUpperCase(),
                },
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }))
        } else {
            setState(prev => ({
                ...prev,
                filtros: {
                    ...prev.filtros,
                    documento: data,
                },
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }))
        }
    }

    //setea el filtro correspondiente seleccioando
    const handleFilter = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...prevState.filtros,
                    [data.key]: data.value
                },
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }
        })
    }

    //funcion para setear a las variables a sus valores inicales
    const onCleanFilter = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...data,
                },
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }
        })
    }

    const { sidebarStatus } = props;

    // convert Tue Mar 28 2023 00:00:00 GMT-0400 (Paraguay Standard Time)
    // 2021-03-28
    const convertDate = (date) => {
        if (date) {
            const dateConvert = new Date(date);
            const year = dateConvert.getFullYear();
            const month = dateConvert.getMonth() + 1;
            const day = dateConvert.getDate();
            const upMonth = month < 10 ? '0' + month : month;
            const upDay = day < 10 ? '0' + day : day;
            const dateFormated = `${year}-${upMonth}-${upDay}`;
            return dateFormated;
        } else if (date === '') {
            return null;
        }
    }

    const verifyTextLibre = (nombre, documento) => {
        //state.filtros.nombre === '' || state.filtros.documento  === '' ? null : state.filtros.nombre || state.filtros.documento,
        if (documento && documento !== '') {
            return documento;
        } else if (nombre && nombre !== '') {
            return nombre;
        } else {
            return null;
        }
    }

    /**
     * Query para consumir datos de lista de vendedores
     * */
    const { data, loading, error } = useQuery(VENDEDORES,
        {
            variables:
            {
                filtros: {
                    estadoCuenta: state.filtros.status === '' || state.filtros.status === 'TODAS' ? null : state.filtros.status,
                    estadoKyc: state.filtros.kyc === '' || state.filtros.kyc === 'TODAS' ? null : state.filtros.kyc,
                    fechaFinRegistro: state.filtros.hasta === '' ? null : convertDate(state.filtros.hasta) === '' ? null : convertDate(state.filtros.hasta),
                    fechaInicioRegistro: state.filtros.desde === '' ? null : convertDate(state.filtros.desde) === '' ? null : convertDate(state.filtros.desde),
                    textoLibre: verifyTextLibre(state?.filtros?.nombre, state?.filtros?.documento)
                },
                paginacion: {
                    order: state?.paginacion?.order,
                    orderBy: state?.paginacion?.orderBy,
                    step: (state?.paginacion?.step),
                    take: +(state?.paginacion?.take)
                }
            }
        }
    )

    const verifyFilter = (data) => {
        // si los valores de data son diferentes a '' o null, se setean a true
        const kyc = data.kyc === '' || data.kyc === null || data.kyc === 'TODAS' ? false : true
        const status = data.status === '' || data.status === null || data.status === 'TODAS' ? false : true
        const desde = data.desde !== '' && data.desde !== null ? true : false
        const hasta = data.hasta !== '' && data.hasta !== null ? true : false
        if (kyc || status || desde || hasta) {
            return true
        } else {
            return false
        }
    }

    const validationFilters = verifyFilter(state.filtros)

    //formateo de datos de vendedores
    const formatedData = formatDataVendedores(data)

    const handleTake = data => {
        setState(prev => ({
            ...prev,
            paginacion: {
                ...prev.paginacion,
                take: data
            }
        }))
    }

    const handleStep = data => {
        setState(prev => ({
            ...prev,
            paginacion: {
                ...prev.paginacion,
                step: data
            }
        }))
    }

    // constante para habilitar u deshabilitar la paginacion dependiendo de la existencia de datos
    const allowPagination = data?.vendedores?.length > 0 ? true : false
    const currentStep = state?.paginacion?.step ? state?.paginacion?.step : 0

    const addCurrentPage = (filter, currentPage) => {
        if (filter) {
            return 1
        } else {
            return currentPage
        }
    }

    const validateDesde = state.filtros.desde !== '' && state.filtros.desde !== null ? true : false
    const validateHasta = state.filtros.hasta !== '' && state.filtros.hasta !== null ? true : false

    const account = state.filtros.status === '' || state.filtros.status === 'TODAS' ? false : true
    const kycstate = state.filtros.kyc === '' || state.filtros.kyc === 'TODAS' ? false : true

    // calculo de la cantidad de dias entre dos fechas
    const calculateDays = (date1, dat) => {
        const date = new Date(date1)
        const date2 = new Date(dat)
        // el numero mayor es de un mes 
        const date1Convert = convertDate(date)
        const date2Convert = convertDate(date2)
        const fecha1 = new Date(date1Convert);
        const fecha2 = new Date(date2Convert);
        // Calculamos la diferencia en milisegundos
        const diferenciaEnMilisegundos = Math.abs(fecha2.getTime() - fecha1.getTime());
        // Convertimos la diferencia a días
        const diff = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
        return diff;
    }

    const diff = (validateDesde && validateHasta) ? calculateDays(state.filtros.desde, state.filtros.hasta) : 0

    return (
        <div className="dashboard" ref={dashboardRef} >
            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    <section className={sidebarStatus === 'open' ? 'section content-box pd-un' : 'section content-box pd-un content-box-expand'} id="cont-box">
                        <div className="columns">
                            <div className="column is-3 text-al-ini"><h1 className="title-details ml-5 pt-3">Lista de vendedores</h1></div>
                            <div className="column is-6">
                                <SearchTable
                                    placeholder='Buscar un Vendedor...'
                                    handleChange={handleSearch}
                                />
                            </div>
                            <div className="column is-3"></div>
                        </div>
                        <FiltroTable onCleanFilter={onCleanFilter} getFilter={handleFilter} />
                        <div className="columns box-list">
                            <div className="column is-full">
                                {loading && (
                                    <div className='my-5 py-5'>
                                        <div>
                                            <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                                            <h6 className='mt-4'>Cargando datos...</h6>
                                        </div>
                                    </div>
                                )}
                                {(((diff > 30) || (state.filtros.desde > state.filtros.hasta)) &&  !loading && validateDesde && validateHasta) && (
                                        <Fragment>
                                            <div className="errordataload">
                                                {
                                                    (( (diff > 30) && (state.filtros.desde <= state.filtros.hasta)) &&
                                                        (state.filtros.nombre !== ('' || null) || state.filtros.documento !== ('' || null))) ?
                                                        'Rango de fecha debe ser menor o igual a 1 mes'
                                                        : ''
                                                }
                                                {
                                                    ( (state.filtros.desde > state.filtros.hasta)) &&
                                                        (state.filtros.nombre !== ('' || null) || state.filtros.documento !== ('' || null)) ?
                                                        'La fecha desde debe ser menor a la fecha hasta'
                                                        : ''
                                                }
                                            </div>
                                        </Fragment>
                                    )
                                }
                                {(error !== undefined) && (
                                    <Fragment>
                                        <div className="errordataload">
                                            {
                                                (
                                                    ((!validateDesde || !validateHasta)) &&
                                                    (state.filtros.nombre || state.filtros.documento)
                                                ) ?
                                                    'Debe ingresar un rango de fecha desde y hasta' :
                                                    ''
                                            }
                                            {
                                                (!validateDesde && !validateHasta && !state.filtros.nombre && !state.filtros.documento) ?
                                                    'Error cargando datos de vendedores' :
                                                    ''
                                            }
                                            {
                                                (validateDesde && (account || kycstate)) ?
                                                    'Debe ingresar un rango de fecha desde y hasta' :
                                                    ''
                                            }
                                        </div>
                                        {console.log('TRACKING ERROR', error)}
                                    </Fragment>
                                )}
                                {(data && diff <= 30 && (!validateHasta ? true: (state.filtros.desde <= state.filtros.hasta))) && (
                                    <Fragment>
                                        {
                                            (state?.filtros?.nombre) ?
                                                <TableSearch
                                                    link='/vendedor/'
                                                    take={state.paginacion.take}
                                                    step={state.paginacion.step}
                                                    headers={state.headers}
                                                    handleChangeTake={handleTake}
                                                    handleChangeStep={handleStep}
                                                    data={formatedData}
                                                    currentPage={addCurrentPage(validationFilters, state.currentPage)}
                                                    allowPagination={allowPagination}
                                                    currentStep={currentStep}
                                                    filters={validationFilters}
                                                    validateDesde={validateDesde}
                                                    validateHasta={validateHasta}
                                                />
                                                :
                                                <TableVendedores
                                                    link='/vendedor/'
                                                    take={state.paginacion.take}
                                                    step={state.paginacion.step}
                                                    headers={state.headers}
                                                    handleChangeTake={handleTake}
                                                    handleChangeStep={handleStep}
                                                    data={formatedData}
                                                    currentPage={addCurrentPage(validationFilters, state.currentPage)}
                                                    allowPagination={allowPagination}
                                                    currentStep={currentStep}
                                                    filters={validationFilters}
                                                    filtros = {state}
                                                    validateDesde={validateDesde}
                                                    validateHasta={validateHasta}
                                                />
                                        }
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </main>

        </div>
    )

}
function mapStateToProps({ authedUser, sidebarStatus }) {
    return {
        authedUser,
        sidebarStatus,
    }
}

export default connect(mapStateToProps)(Vendedores)
