import React, { useState } from "react";
import Select from "../../common/Select"
import DatePicker from 'react-date-picker'

const FiltroComisiones = props => {

    const initialState = {
        desde: '',
        hasta: '',
        estado: 'TODAS',
        comisionAcreditada: 'TODAS'
    }

    const [state, setState] = useState(initialState);
    /**
     *
     * **/
    const setSelectedValue = (data) => {
        // Si hay cambios en los selects se guarda en el state del componente
        // Y se envia al componte padre para aplicar luego el filtro a la tabla
        props.getFilter(data)

        setState(prevState => (
            {
                ...prevState,
                [data.key]: data.value,
            }
        ))
    }

    const handleCleanFilter = () => {
        setState(initialState)
        props.onCleanFilter(initialState)
    }

    // Datos de Prueba para Select de estado de comisiones
    const estadoAcreditación = {
        slice: 'estadoAcreditacion',
        label: 'Estado de PAGO',
        default: '',
        disabled: 'Seleccionar estado',
        options: {
            TODAS: 'Todas',
            APROBADA: 'ACREDITADA',
            PENDIENTE: 'PENDIENTE',
        }
    }

    // Datos de Prueba para Select de estado de comisiones
    const estadoComision = {
        slice: 'estado',
        label: 'Estado de comisión',
        default: '',
        disabled: 'Seleccionar estado',
        options: {
            TODAS: 'Todas',
            RECHAZADO: 'RECHAZADO',
            APROBADO: 'APROBADO',
        }
    }

    return (
        <div className="columns">

            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.estado}
                        optionList={estadoComision}
                        setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.comisionAcreditada}
                        optionList={estadoAcreditación}
                        setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column align">
                <button className="button btn-other mt-5" onClick={() => handleCleanFilter()}>Limpiar</button>
            </div>
        </div>
    )

}

export default FiltroComisiones;