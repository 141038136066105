import React, {Fragment, useState} from 'react'
import {connect} from "react-redux";
import {useMutation} from "@apollo/client";
import {DESVINCULAR_DISPOSITIVO} from "../../../utils/mutators/Mutators";
import {GET_VENDEDOR} from "../../../utils/queries/Queries";
import {handleToggleModal} from "../../../actions/modal";
import {BounceLoader} from "react-spinners";
import {override} from "../../../utils/helpers";
import {ReactComponent as CheckIcon} from "../../../assets/images/check.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FormEstadoDispositivo = props =>{

    const initialState = {
        
        idVendedor: props.idVendedor ? props.idVendedor : '',
        color: '#323980',
    }

    const [state, setState] = useState(initialState)

    const [DesvincularDispositivo, { data, loading, error, reset }] = useMutation(DESVINCULAR_DISPOSITIVO,{
        refetchQueries: [
            GET_VENDEDOR, // DocumentNode object parsed with gql
            'Get Vendedor' // Query name
        ],
    })

    const handleSubmit = ()=>{
        DesvincularDispositivo({
            variables:{
                idVendedor: props.idVendedor
            }
        }).then(r => {
            console.log('Rpta desvinculacion dispositivo', r)
        }).catch(
            function (reason) {
                console.error('Error desvinculacion dispositivo-> ' + reason);
            });
            console.log('hola')
    }

    const handleCloseModal = ()=>{
        props.dispatch(handleToggleModal())
        reset()
    }

    return(
        <Fragment   >
            { error && (
                <div className="notification is-danger">
                    <button className="delete"> </button>
                    {error.message}
                </div>

            )}
            { loading && (
                <div className='my-5 py-5'>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50}/>
                </div>
            )}
            { !loading &&(
                !data
                    ? (
                        <form action="" onSubmit={handleSubmit}>
                        <div className="a-center modal-delete">
                            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                            <p>¿Estas seguro de realizar esta acción? El dispositivo asociado a la cuenta será desvinculado.</p>
                        </div>
                        <footer className="modal-card-foot">
                            <button className="button is-normal btn-eliminar" type="submit">
                                Aceptar
                            </button>
                            <button
                                onClick={handleCloseModal}
                                className="button btn-cancelar"
                                data-bulma-modal="close"
                            >
                                Cancelar
                            </button>
                        </footer>
                    </form>
                    )
                    : (
                        <div className='has-text-centered'>
                            <div>
                                <CheckIcon/>
                            </div>
                            <h6>Dispositivo desvinculado correctamente</h6>
                            <footer className="modal-card-foot pb-0">
                                <button
                                    disabled={loading}
                                    onClick={()=> {
                                        handleCloseModal()
                                    }}
                                    className='button is-normal btn-enviar'>
                                    Aceptar
                                </button>
                            </footer>
                        </div>

                    )
                )
            }

        </Fragment>
    )
}

function mapStateToProps(authedUser){
    return{
        authedUser
    }
}
export default connect(mapStateToProps)(FormEstadoDispositivo);