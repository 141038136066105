import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleToggleModal } from "../../actions/modal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { EDIT_DATA_CONTENT, EDIT_DATA_REGLA, INSERT_NEW_CONTENT, SEND_NOTIFICATION } from "../../utils/mutators/Mutators";
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_BY_ID, GET_REGLA_BY_ID, GET_REGLA_BY_PRODUCTO } from "../../utils/queries/Queries";
import DatePicker from 'react-date-picker';
import { BounceLoader } from "react-spinners";
import { override } from "../../utils/helpers";
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import SelectReglaByProducto from "../common/Selects/SelectReglaByProducto";
import { useNavigate } from "react-router-dom";

const FormInsertarNotificacion = (props) => {

    //declaramos las variables
    const initialState = {
        producto: "BOMO",
        fechaInicio: null,
        fechaFin: null,
        variables: {
            modalType: '',
            titulo: '',
            contenido: '',
            idPrioridad: '',
            idReglaPredefinida: '',
            observacion: '',
            regla: '',
            idRegla: '',
            idContenido: '',
            idMultimedia: null,
            estado: 'PENDIENTE',
            jSRegla: ''
        }
    }

    // Variables: Necesarios para la inserción de la mutación de Servicios
    const [state, setState] = useState(initialState);
    const [errorExist, setErrorExist] = useState(false)

    const [getRegla, result] = useLazyQuery(GET_REGLA_BY_ID)
    const [loadingRegla, setloadingRegla] = useState(false)
    const [getDataNotificacion, resultNotificacion] = useLazyQuery(GET_NOTIFICATIONS_BY_ID)
    const [loadingNotificacion, setloadingNotificacion] = useState(false)

    const [EnviarNotificaciones, { data: dataPlay, loading: loadingPlay, error: errorPlay }] = useMutation(SEND_NOTIFICATION)
    // Mutation for inserting a notification
    const [insertNotification, { data: dataInsert, loading: loadingInsert, error: errorInsert, reset: resetInsert }] = useMutation(INSERT_NEW_CONTENT, {
        refetchQueries: [
            GET_NOTIFICATIONS, // DocumentNode object parsed with gql
            'getContenidos' // Query name
        ],
    });
    // Mutator for Editing a notification
    const [EditarNotificacion, { data: dataEdit, loading: loadingEdit, error: errorEdit }] = useMutation(EDIT_DATA_CONTENT, {
        refetchQueries: [
            GET_NOTIFICATIONS, 'getContenidos',  //se refresca para visualizar la actualizacion el la pantalla al instante
            GET_NOTIFICATIONS_BY_ID, 'getContenidoById' // se refresca para tener actualizado el modal
        ],
    });

    /**metodo para enviar consulta de consumo de recurso para edición de 
     * un contenido (notificación)
     */
    const [EditarRegla, { data: dataReglaEdit, loading: loadingReglaEdit, error: errorReglaEdit }] = useMutation(EDIT_DATA_REGLA,
        {
            refetchQueries: [
                GET_REGLA_BY_ID,
                {
                    variables:
                    {
                        idRegla: props?.modal?.data
                    }
                },
                GET_NOTIFICATIONS, 'getContenidos',  //se refresca para visualizar la actualizacion el la pantalla al instante
            ],
        }
    )

    //seteamos los modales segun su tipo
    useEffect(() => {

        if (props.modal.type === 'edit') { handleEditMode() }

        if (props.modal.type === 'code') { handleEditCodeMode() }

        else if (props.modal.type === 'delete') {
            setState(prevState => {
                return {
                    ...prevState,
                    modalType: 'delete'

                }
            })
        }
    }, [])

    //Realiza la consulta para obtener los datos de la regla mediante el idRegla
    const showRegla = idRegla => {
        const dataR = getRegla({ variables: { idRegla: idRegla } })
        return dataR //Retorna la data para realizar el seteo
    }

    //Realiza la consulta para obtener los datos de la notificacion mediante el idContenido
    const showDatNotificacion = id => {
        const dataN = getDataNotificacion({ variables: { idContenido: id } })
        return dataN //Retorna la data para realizar el seteo
    }

    //funcion onchange para los inputs
    const handleChange = (e) => {

        setState(prevState => {
            const updatedValues = {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    [e.target.name]: e.target.value,
                }
            }
            return { ...updatedValues };
        });
    }

    
    // React router for redirect to notificaciones
    let navigate = useNavigate();

    //para cerrar modal
    const handleAceptarBtn = (e) => {

        e.preventDefault()
        setState(() => {
            return { ...initialState }
        })
        props.dispatch(handleToggleModal())
    }

    const handleAceptarBtnFinal = (e)=>{
        e.preventDefault()
        setState(() => {
            return { ...initialState }
        })
        props.dispatch(handleToggleModal())
        navigate('/notificaciones')
        window.location.reload();
    }

    /**onchange correspondiente a seleccion 
     * y seteo de fecha para fecha inicio */
    const handleDateChange = (data) => {

        const Fecha = new Date(data)
        setState(prevState => (
            {
                ...prevState,
                fechaInicio: new Date(Fecha),
            }
        ))

    }

    /**onchange correspondiente a seleccion 
         * y seteo de fecha para fecha fin */
    const handleDateHastaChange = (data) => {

        const Fecha = new Date(data)
        setState(prevState => (
            {
                ...prevState,
                fechaFin: new Date(Fecha),
            }
        ))

    }

    //accion correspondiente para insertar una notificación y hacemos el llamado de la funcion de inserción
    const handleInsert = () => {
        // se carga el obj options con los datos obtenidos del form
        insertNotification({
            variables: {
                input: {
                    titulo: state.variables.titulo,
                    idReglaPredefinida: state.variables.idReglaPredefinida,
                    idPrioridad: state.variables.idPrioridad,
                    idMultimedia: "TEXTO",
                    idProducto: "BOMO",
                    estado: state.variables.estado,
                    fechaInicio: (state.fechaInicio) ? state.fechaInicio.toISOString().slice(0, 10) : null,
                    fechaFin: (state.fechaFin) ? state.fechaFin.toISOString().slice(0, 10) : null,
                    contenido: state.variables.contenido
                }
            },
        }).then(r => {
            console.log('capturando respuesta de insercion de notificaciones', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error insercion-> ' + reason);
                setErrorExist(true)

            }
        );
    }

    /** */
    const { data: dataRegla, loading: loadingReg, error: errorRegla } = useQuery(GET_REGLA_BY_ID,
        {
            variables: { idRegla: props?.modal?.data ? props?.modal?.data : '' }
        })

    /**funcion para pasear y acceder al dato que requeriomos*/
    const parseData = (dataToParse) => {
        if (dataToParse) {
            const dataToReturn = dataToParse ? JSON.parse(dataToParse) : ''
            return (
                dataToReturn?._id
            )
        }
    }

    /**un lazy querie para setear datos respecto a una
     * regla asociada a un contenido
     */
    const [idReglaPredefinida, getResultIdPredefinida] = useLazyQuery(GET_REGLA_BY_ID)

    //Realiza la consulta para obtener los datos de la regla mediante el idRegla
    const fillIdRegla = idRegla => {
        const dataR = idReglaPredefinida({ variables: { idRegla: idRegla } })
        return dataR //Retorna la data para realizar el seteo
    }

    //Consulta graphql donde consumimos el recurso para mostrar 
    //el listado de reglas desde la base de datos
    const reglaByProducto = useQuery(
        GET_REGLA_BY_PRODUCTO,
        { variables: { idProducto: "BOMO" } }
    )

    // Desestructura los datos a utilizar.
    const { data: dataJs, error, loading: loadingReglas } = reglaByProducto
    //Filtramos la la observacion de la regla seleccionada mediante su _id para la modificacion de 
    //la regla en caso de ser requerido
    const filterRegla = dataJs?.getReglaByProducto?.filter(item => item._id === state?.variables?.idReglaPredefinida)
    const dataToJsRegla = filterRegla ? filterRegla[0] : ''



    //funcion para precargar los valores de la notificacion en el modal 
    const handleEditMode = async () => {


        if (props.modal.type === 'edit') {
            setloadingNotificacion(true)

            const resp = await showDatNotificacion(props?.modal?.data)
            setloadingNotificacion(false)

            const dataNotification = resp.data.getContenidoById
            const fechaIni = new Date(dataNotification.fechaInicio)
            const fechaFin = new Date(dataNotification.fechaFin)

            // compensa la diferencia provocada por la zona horaria que provoca diferencia de fechas
            fechaIni.setMinutes(fechaIni.getMinutes() + fechaIni.getTimezoneOffset())
            fechaFin.setMinutes(fechaFin.getMinutes() + fechaFin.getTimezoneOffset())


            /**llamamos consulta a recurso y aguardamos hasta conseguir el dato
             * que requerimos
             */
            const respdATA = await fillIdRegla(dataNotification?.idRegla)
            /**guardamos y llamamos a la funcion de parseo de dato
             * para acceder al dato requerido
             */

            const dataToSet = parseData(respdATA.data?.getReglaById?.observacion)

            setState(prev => {
                return {
                    ...prev,
                    producto: "BOMO",
                    fechaInicio: dataNotification.fechaInicio ? fechaIni : null,
                    fechaFin: dataNotification.fechaFin ? fechaFin : null,
                    variables: {
                        idContenido: dataNotification?.idContenido,
                        titulo: dataNotification?.titulo,
                        contenido: dataNotification?.contenido,
                        idPrioridad: dataNotification?.idPrioridad,
                        estado: dataNotification?.estado,
                        idMultimedia: dataNotification?.idMultimedia,
                        idReglaPredefinida: dataNotification ? dataToSet : '',
                        jSRegla: dataToJsRegla ? dataToJsRegla : '',
                        idRegla: dataNotification.idRegla
                    },
                }
            })
        }
    }

    //funcion para enviar los nuevos valores de la notificacion a modificar
    const handleEdit = () => {

        EditarNotificacion({
            variables: {
                input: {
                    idContenido: state.variables.idContenido,
                    titulo: state.variables.titulo,
                    contenido: state.variables.contenido,
                    idPrioridad: state.variables.idPrioridad,
                    idMultimedia: state.variables.idMultimedia,
                    idProducto: "BOMO",
                    estado: state.variables.estado,
                    idMultimedia: state?.variables?.idMultimedia,
                    fechaInicio: (state?.fechaInicio) === null ? null : state.fechaInicio.toISOString().slice(0, 10),
                    fechaFin: (state.fechaFin) === null ? null : state.fechaFin.toISOString().slice(0, 10),
                    idRegla: state.variables.idRegla,
                    jSRegla: dataToJsRegla
                }
            }
        }).then(r => {
            console.log('capturando respuesta de edicion de operador', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edit-> ' + reason);
                setErrorExist(true)

            }
        );
    }

    //para setear respectivamente los datos de una regla para cargar en ella
    //nuevos valores 
    const handleEditCodeMode = async () => {
        if (props.modal.type === 'code') {
            setloadingRegla(true)
            const resp = await showRegla(props.modal.data)
            setloadingRegla(false)
            setState(prev => {
                return {
                    ...prev,
                    variables: {
                        idRegla: props.modal.data,
                        observacion: resp.data.getReglaById.observacion,
                        consulta: resp.data.getReglaById.consulta
                    },
                }
            })
        }
    }

    //funcion para mandar al recurso de edición de una regla 
    const handleCode = () => {
        EditarRegla({
            variables: {
                input: {
                    idRegla: props.modal.data,
                    observacion: state.variables.observacion,
                    consulta: state.variables.consulta
                }
            }
        }).then(r => {
            console.log('capturando respuesta de edicion de regla', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edit-> ' + reason);
                setErrorExist(true)
            }
        );
    }

    const handlePlay = () => {
        EnviarNotificaciones({
            variables: {
                input: {
                    idContenido: props.modal.data,
                    idProducto: 'BOMO'

                }
            }
        }).then(r => {
            console.log('capturando respuesta de edicion de envio de notificacion', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edit-> ' + reason);
                setErrorExist(true)

            }
        );
    }

    //Dependiendo del tipo de modal entra en la concional
    const handleSubmit = (e) => {

        e.preventDefault()

        if ((props.modal.type === 'edit')) {
            handleEdit()
        }
        if (props.modal.type === 'code') {
            handleCode()
        }
        if ((props.modal.type !== 'edit') && (props.modal.type !== 'code') && (props.modal.type !== 'playModal')) {
            handleInsert()
        }
        if (props.modal.type === 'playModal') {
            handlePlay()
        }
    }


    const data = (dataInsert || dataEdit || dataReglaEdit || dataPlay)
    const loading = (loadingInsert || loadingEdit || loadingReglaEdit || loadingPlay || loadingNotificacion || loadingRegla || loadingReg)






    if (props.modal.type === 'playModal') {
        return (
            <Fragment>
                {(errorPlay && errorExist) ? (
                    <div className="notification is-danger">
                        <button className="delete" onClick={() => setErrorExist(false)}> </button><strong>{errorPlay.message}</strong>
                    </div>
                ) : ("")}
                {loading &&
                    <div className="my-5 py-5">
                        <BounceLoader
                            color={state.color}
                            loading={state.loading}
                            css={override}
                            size={50}
                        />
                    </div>
                }
                {!loading && !data && (
                    <form
                        action=""
                        onSubmit={(e) => {
                            handleSubmit(e)
                        }}
                    >
                        <div className="column">
                            ¿Esta seguro de enviar esta notificación?

                        </div>

                        <footer className="modal-card-foot">
                            <button className="button is-normal btn-enviar"> Aceptar </button>
                            <button onClick={(e) => handleAceptarBtn(e)} className="button btn-cancelar" data-bulma-modal="close" > Cancelar </button>
                        </footer>
                    </form>
                )}
                {data && (
                    <div className="has-text-centered">
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>Envio exitoso</h6>
                        <button
                            onClick={(e) => handleAceptarBtn(e)}
                            className="mt-5 button is-normal btn-enviar"
                            data-bulma-modal="close"
                        >
                            Aceptar
                        </button>
                    </div>
                )}


            </Fragment>
        )
    }
    if (props?.modal?.type === 'code') {
        return (
            <Fragment>
                {(errorReglaEdit && errorExist) ? (
                    <div className="notification is-danger">
                        <button className="delete" onClick={() => setErrorExist(false)}> </button><strong>{errorReglaEdit.message}</strong>
                    </div>
                ) : ("")}
                {loading &&
                    <div className="my-5 py-5">
                        <BounceLoader
                            color={state.color}
                            loading={state.loading}
                            css={override}
                            size={50}
                        />
                    </div>
                }
                {!loading && !data && (
                    <form
                        action=""
                        onSubmit={(e) => {
                            handleSubmit(e)
                        }}
                    >
                        <div className="column">
                            <div className="field">
                                <label className="label">Observación:</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        name="observacion"
                                        className="input pl-2"
                                        type="text"
                                        onChange={(e) => handleChange(e)} value={state.variables.observacion}
                                        placeholder="Ingresar observación..."
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">Sentencia SQL:</label>
                                <div className="control has-icons-left has-icons-right">
                                    <textarea
                                        name="consulta"
                                        className="input pl-2"
                                        type="textarea"
                                        onChange={(e) => handleChange(e)} value={state.variables.consulta}
                                        placeholder="Ingresar sentencia S..."
                                    />
                                </div>
                            </div>
                        </div>
                        <footer className="modal-card-foot">
                            <button className="button is-normal btn-enviar"> Aceptar </button>
                            <button onClick={(e) => handleAceptarBtn(e)} className="button btn-cancelar" data-bulma-modal="close" > Cancelar </button>
                        </footer>
                    </form>
                )}
                {data && (
                    <div className="has-text-centered">
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>Regla añadida exitosamente</h6>
                        <button
                            onClick={(e) => handleAceptarBtn(e)}
                            className="mt-5 button is-normal btn-enviar"
                            data-bulma-modal="close"
                        >
                            Aceptar
                        </button>
                    </div>
                )}

            </Fragment>
        )
    }

    else return (
        <>
            {(errorInsert && errorExist) ? (
                <div className="notification is-danger">
                    <button className="delete" onClick={() => setErrorExist(false)}> </button><strong>{errorInsert.message}</strong>
                </div>
            ) : ("")}
            {loading && (
                <div className="my-5 py-5">
                    <BounceLoader
                        color={state.color}
                        loading={state.loading}
                        css={override}
                        size={50}
                    />
                </div>
            )}
            {!loading && !data && (
                <form
                    action=""
                    onSubmit={(e) => {
                        handleSubmit(e)
                    }}
                >
                    <div className="column">
                        <div className="field">
                            <label className="label">Titulo:</label>
                            <div className="control has-icons-left has-icons-right">
                                <input
                                    name="titulo"
                                    className="input pl-2"
                                    type="text"
                                    onChange={(e) => handleChange(e)} value={state.variables.titulo}
                                    placeholder="Ingresar titulo..."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">Contenido:</label>
                            <div className="control has-icons-left has-icons-right">
                                <input
                                    name="contenido"
                                    className="input pl-2"
                                    type="text"
                                    onChange={(e) => handleChange(e)} value={state.variables.contenido}
                                    placeholder="Ingresar contenido..."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">Prioridad:</label>
                            <div className='select is-fullwidth'>
                                <select name="idPrioridad" value={state.variables.idPrioridad} onChange={(e) => handleChange(e)}>
                                    <option value="" disabled >Seleccionar prioridad</option>
                                    <option value="BAJA">BAJA</option>
                                    <option value="MEDIA">MEDIA</option>
                                    <option value="ALTA">ALTA</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='column is-5 d-flex'>
                        <div className="colum dateTime">
                            <label className="mb-1" htmlFor="">Fecha inicio:</label>
                            <DatePicker
                                clearIcon={null}
                                onChange={handleDateChange}
                                value={state.fechaInicio}
                                className='date-input' />
                        </div>
                        <div className="colum pl-2">
                            <label className="mb-1" htmlFor="">Fecha fin:</label>
                            <DatePicker
                                clearIcon={null}
                                onChange={handleDateHastaChange}
                                value={state.fechaFin}
                                className='date-input' />
                        </div>
                    </div>
                    {
                        dataRegla
                        &&
                        <SelectReglaByProducto name="idReglaPredefinida" defaultValue={state?.variables?.idReglaPredefinida} handleChange={handleChange} />

                    }
                    <div className="column">
                        <div className="field">
                            <label className="label">Estado:</label>
                            <div className='select is-fullwidth'>
                                <select name='estado' value={state.variables.estado} onChange={(e) => handleChange(e)}>
                                    <option value="PENDIENTE">PENDIENTE</option>
                                    <option value="LISTO">LISTO</option>
                                    {/* <option value="BAJA">BAJA</option> */}
                                </select>
                            </div>
                        </div>
                    </div>

                    <footer className="modal-card-foot">
                        <button className="button is-normal btn-enviar"> Aceptar </button>
                        <button onClick={(e) => handleAceptarBtn(e)} className="button btn-cancelar" data-bulma-modal="close" > Cancelar </button>
                    </footer>

                </form>
            )}
            {data && (
                <div className="has-text-centered">
                    <div>
                        <CheckIcon />
                    </div>
                    {props.modal.type === "edit" ? (<h6>Notificación modificada exitosamente</h6>) : (<h6>Notificación creada exitosamente</h6>)}
                    <button
                        onClick={(e) => handleAceptarBtnFinal(e)}
                        className="mt-5 button is-normal btn-enviar"
                        data-bulma-modal="close"
                    >
                        Aceptar
                    </button>
                </div>
            )}

        </>

    )

}

function mapStateToProps({ authedUser, modal }) {
    return {
        authedUser,
        modal
    }
}


export default connect(mapStateToProps)(FormInsertarNotificacion);

