import TableHeader from "../Vendedores/TableHeader";
import React, { useEffect, useState, Fragment } from "react";
import TableRow from "./TableRow";
import { sortData, takeData, } from "../../utils/helpers";
import { Pagination } from "react-bulma-components";

export default function Table(props) {
    const [state, setState] = useState({ sort: {}, currentPage: 1, step: 1 })
    const { data, headers} = props;
    const [order, setOrder] = useState(1);
    let modo = 'asc'
    const allowPagination = props?.allowPagination === true ? true : false

    //ordenamos la lista que obtenemos
    const listData = takeData(sortData(Object.keys(data).reverse().map(item => data[item]), state.sort), state.currentPage)

    //funcion para pordenar desc asc y setear datos consultados
    const onHandleChangeData = (data) => {

        if (data === 'registerFormat') {
            data = 'register'
        }
        if (data === 'modificacionFormat') {
            data = 'modificacion'
        }
        setOrder(order + 1)
        if (order % 2 === 0) {
            modo = 'desc'
        } else {
            modo = 'asc'
        }

        let resetData = state.sort

        Object.keys(resetData).forEach(item => {

            resetData[item] = modo
        })
        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...resetData,
                    [data]: toogleSortData(data)
                }
            }
        })
    }
    //seteamos tipo de sort al dar click en ella
    const toogleSortData = data => {

        if (state.sort[data] === 'asc') {
            return 'desc'
        } else {
            return 'asc'
        }
    }

    //funcion para setear el nro de pagina actual y cambiar en la misma
    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    useEffect(() => {
        let filtros = Object.keys(headers)

        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...filtros.reduce(function (target, key, index) {
                        target[key] = 'asc';
                        return target;
                    }, {})
                }
            }
        })
    }, [headers])


    return (
        <Fragment>
            <table className="table is-striped table-list has-text-left main-table">
                <TableHeader onChange={onHandleChangeData} headers={headers} />
                <tbody>
                    {
                        Object.keys(listData).map(itemkey => {
                            const item = listData[itemkey];
                            return (
                                <TableRow link={props?.link} key={item.id} data={item} showww={allowPagination} />
                            )
                        })
                    }
                </tbody>
            </table>
            {
                <div className='pt-5'>
                    <Pagination
                        current={state.currentPage}
                        next={'>'}
                        previous={'<'}
                        total={Object.keys(data).length / 10 % 1 == 0 ? Math.floor(Object.keys(data).length / 10) : Math.floor(Object.keys(data).length / 10) + 1}
                        onChange={handleCurrentPage}
                    />
                </div>
            }
        </Fragment>
    )
    
}