import React, { Fragment, useEffect, useState } from 'react'
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { EDIT_CATEGORIA, SET_CATEGORIA, DELETE_CATEGORIA } from "../../utils/mutators/Mutators";
import { GET_CATEGORIAS } from "../../utils/queries/Queries";
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import { BounceLoader } from "react-spinners";
import { handleToggleModal } from "../../actions/modal";
import { connect } from "react-redux";
import { override } from "../../utils/helpers";
import toast, { ter } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectIcon from '../common/Selects/SelectIcon';
import SelectColor from '../common/Selects/SelectColor';


const FormInsertarCategoria = (props) => {
    const initialState = {
        variables: {
            nombre: "",
            icono: "add",
            color: "",
            categoriaId: ' ',
        },
        loading: true,
    }

    const [state, setState] = useState(initialState);
    const [errorExist, setErrorExist] = useState(false)
    const [validateName, setvalidateName] = useState(false)
    const [validateColor, setvalidateColor] = useState(false)
    const [editMade, seteditMade] = useState(false)



    const client = useApolloClient();

    const handleEditMode = () => {
        seteditMade(true);
        const categoriaData = client.readFragment({
            id: `Categoria:${props.modal.data}`, // The value of the to-do item's cache ID
            fragment: gql`
                fragment categoria on Categoria {
                    id
                    nombre
                    icono
                    color
                }
            `,
        });

        setState(prev => {
            return {
                ...prev,
                variables: {
                    icono: categoriaData.icono,
                    nombre: categoriaData.nombre,
                    color: categoriaData.color,
                    categoriaId: categoriaData.id
                }
            }
        })
    }

    const handleInsert = () => {

        insertarCategoria({
            variables: {
                categoria: {
                    nombre: state?.variables?.nombre,
                    icono: state?.variables?.icono,
                    color: state?.variables?.color,
                }
            }
        }).then(r => {
            console.log('Respuesta de insercion categorias', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error insercion Categoria-> ' + reason);
            });

    }

    const handleEdit = () => {
        editarCategoria({
            variables: {
                categoria: {
                    nombre: state?.variables?.nombre,
                    icono: state?.variables?.icono,
                    color: state?.variables?.color,
                },
                categoriaId: state?.variables?.categoriaId
            }
        }).then(r => {
            console.log('Respuesta de edicion categorias', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edicion Categoria-> ' + reason);
            });
        
    }

    const errorDel = (reason) => toast.error('Ocurrio un error al eliminar: ' + JSON.stringify(reason.message));
    const successDel = (r) => toast.success('Servicio eliminado exitosamente.')

    const handleDelete = (e) => {
        props.dispatch(handleToggleModal())
        eliminarCategoria({
            variables: {
                categoriaId: props.modal.data,
            }
        }).then(r => {
            console.log('capturando respuesta de borrado de categoria', r)
            resetDelete()
            successDel(r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                errorDel(reason)
                console.log('Error al eliminar categoría -> ' + reason, errorDel);
                if (errorDel) setErrorExist(true)
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (props.modal.type === 'edit') {
            handleEdit()
        }
        if (props.modal.type === 'delete') {
            handleDelete()
        } else if (props.modal.type !== 'edit') {
            handleInsert()
        }
    }

    const handleChange = (e) => {
        console.log("update", e.target.name);
        if (e.target.name === "color") setvalidateColor(true)
        if (e.target.name === "nombre") setvalidateName(true)

        setState(prevState => {
            const updatedValues = {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    [e.target.name]: e.target.value,
                }
            }
            return { ...updatedValues };
        });
    }


    // const handleChange = (e) => {
    //     setState(prevState => {
    //         const updatedValues = {
    //             ...prevState,
    //             variables: {
    //                 ...prevState.variables,
    //                 [e.target.name]: e.target.value,
    //             }
    //         }
    //         return {...updatedValues};
    //     });
    // }

    const handleClick = (e) => {
        e.preventDefault()
        handleAceptarBtn()
    }

    const handleAceptarBtn = (e) => {
        props.dispatch(handleToggleModal())
        setState(() => {
            return { ...initialState }
        })
        resetInsert()
        resetDelete()
    }

    const [insertarCategoria, {
        data: dataInsert,
        loading: loadingInsert,
        error: errorInsert,
        reset: resetInsert
    }] = useMutation(SET_CATEGORIA, {
        refetchQueries: [
            GET_CATEGORIAS, // DocumentNode object parsed with gql
            'Categorias' // Query name
        ],
    });

    const [editarCategoria, {
        data: dataEdit,
        loading: loadingEdit,
        error: errorEdit,
        //reset: resetEdit
    }] = useMutation(EDIT_CATEGORIA, {
        refetchQueries: [
            GET_CATEGORIAS, // DocumentNode object parsed with gql
            'Categorias' // Query name
        ],
    });

    const reFetch = {
        refetchQueries: [
            GET_CATEGORIAS, // DocumentNode object parsed with gql
            'Categorias' // Query name
        ],
    }

    const [eliminarCategoria, {
        data: dataDelCat,
        loading: loadingDelCat,
        error: errorDelCat,
        reset: resetDelete
    }] = useMutation(DELETE_CATEGORIA, reFetch);

    useEffect(() => {
        if (props.modal.type === 'edit') handleEditMode()
        else if (props.modal.type === 'delete') {
            setState(prevState => {
                return {
                    ...prevState,
                    modalType: 'delete'

                }
            })
        }
        // eslint-disable-next-line
    }, [])


    const data = !!(dataInsert || dataEdit || dataDelCat)
    const loading = !!(loadingInsert || loadingEdit || loadingDelCat)


    if (state.modalType !== 'delete') {
        return (
            <Fragment>
                {(errorInsert || errorEdit) ? (
                    <div className="notification is-danger">
                        <button className="delete"> </button>
                        <strong>{errorInsert ? errorInsert.message : errorEdit.message}</strong>
                    </div>
                ) : ''}
                {loading && (
                    <div className='my-5 py-5'>
                        <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                    </div>
                )}

                {!loading && (
                    ((!data) && (
                        <form action="" onSubmit={e => {
                            handleSubmit(e)
                        }}>
                            <div className="field">
                                <label className="label">Descripcion de categoría:</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        name="nombre"
                                        className="input pl-2"
                                        type="text"
                                        onChange={(e) => handleChange(e)}
                                        value={state.variables.nombre}
                                        placeholder="Descripción de la categoria"
                                        autoComplete='off' />

                                </div>
                            </div>
                            <SelectIcon defaultValue={state?.variables?.icono} handleChange={handleChange} />
                            <SelectColor defaultValue={state?.variables?.color} handleChange={handleChange} />
                            <footer className="modal-card-foot">
                                <button className={`button is-normal ${!((validateColor && validateName) || editMade) ? 'btn-disable' : 'btn-enviar'}`} disabled={!((validateColor && validateName) || editMade)}>Guardar</button>
                                <button
                                    onClick={(e) => handleClick(e)}
                                    className="button btn-cancelar" data-bulma-modal="close">Cancelar
                                </button>
                            </footer>
                        </form>
                    ))

                )}
                {data && (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon />
                        </div>
                        {props.modal.type === "edit" ? (<h6>Categoria modificada exitosamente</h6>) : (<h6>Categoria creada exitosamente</h6>)}
                        <button
                            onClick={(e) => handleClick(e)}
                            className="mt-5 button is-normal btn-enviar" data-bulma-modal="close">
                            Aceptar
                        </button>
                    </div>

                )}
            </Fragment>

        )
    } else {
        return (
            <>
                <Fragment>
                    {(errorDelCat && errorExist) ? (
                        <div className="notification is-danger">
                            <button className="delete" onClick={() => setErrorExist(false)}> </button>
                            <strong>{errorDelCat.message}</strong>
                        </div>
                    ) : ''}
                    {loading && (
                        <div className='my-5 py-5'>
                            <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                        </div>
                    )}
                    {!loading && (
                        ((!data) && (
                            <form action="" onSubmit={e => {
                                handleSubmit(e)
                            }}>
                                <div className='a-center modal-delete'>
                                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                                    <p>¿Estas seguro de eliminar?</p>
                                </div>
                                <footer className="modal-card-foot">
                                    <button className="button is-normal btn-eliminar">Eliminar</button>
                                    <button onClick={(e) => handleAceptarBtn(e)} className="button btn-cancelar" data-bulma-modal="close">
                                        Cancelar
                                    </button>
                                </footer>
                            </form>
                        ))
                    )}
                    {data && (
                        <div className='has-text-centered'>
                            <div>
                                <CheckIcon />
                            </div>
                            <h6>Se elimino correctamente</h6>
                            <button
                                onClick={(e) => handleAceptarBtn(e)}
                                className="mt-5 button is-normal btn-enviar" data-bulma-modal="close">
                                Aceptar
                            </button>
                        </div>
                    )}

                </Fragment>
            </>
        )
    }


}


function mapStateToProps({ modal }) {
    return {
        modal,
    }
}




export default connect(mapStateToProps)(FormInsertarCategoria)