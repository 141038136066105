import { Fragment, useState } from "react"
import { connect } from "react-redux"
import { useMutation } from "@apollo/client"
import { UPLOAD_IVA } from "../../../utils/mutators/Mutators"
import { GET_VENDEDOR } from "../../../utils/queries/Queries"
import { BounceLoader } from "react-spinners"
import { override } from "../../../utils/helpers"
import { ReactComponent as CheckIcon } from "../../../assets/images/check.svg"
import { handleToggleModal } from "../../../actions/modal"


const FormUpdateIva = (props) => {

    /**guardamos los valores obtenidos por las props */
    const { idVendedor } = props
    const idOperador = props?.idOperador.toString()

    /**definimos e inicializamos las variables a utilizar */
    const initialState = {
        idOperador: props?.idOperador.toString(),
        idVendedor: props.idVendedor ? props.idVendedor : "",
        primeraDeclaracion: "",
        segundaDeclaracion: "",
        terceraDeclaracion: "",
        fileNameOne: '',
        fileNameTwo: '',
        fileNameThree: ''
    }

    /**inicializamos nuestras variables */
    const [state, setState] = useState(initialState)

    /**consumimos recurso para actualizacion de IVA's */
    const [SubirDeclaracionIva, { data, loading, error, reset }] =
        useMutation(UPLOAD_IVA, {
            refetchQueries: [
                GET_VENDEDOR, // DocumentNode object parsed with gql
                "Get Vendedor", // Query name
            ],
        })

    /**funcion correspondiente para onchange y para el seteo 
     * correspondiente a base 64 de la imagen en cuestion para 
     * la iva 1
     */
    const handleFileReadIvaOne = async (event) => {

        const file = event.target.files[0]
        const baseImg = await convertBase(file)

        setState({
            ...state,
            fileNameOne: file,
            variables: {
                ...state.variables,
                idOperador: props?.idOperador.toString(),
                idVendedor: props.idVendedor ? props.idVendedor : "",
                primeraDeclaracion: baseImg,

            },
        })
    }

    /**funcion correspondiente para onchange y para el seteo 
     * correspondiente a base 64 de la imagen en cuestion para 
     * la iva 2
     */
    const handleFileReadIvaTwo = async (event) => {

        const file = event.target.files[0]
        const baseImg = await convertBase(file)

        setState({
            ...state,
            fileNameTwo: file,
            variables: {
                ...state.variables,
                idOperador: props?.idOperador.toString(),
                idVendedor: props.idVendedor ? props.idVendedor : "",
                segundaDeclaracion: baseImg,
            },
        })
    }

    /**funcion correspondiente para onchange y para el seteo 
     * correspondiente a base 64 de la imagen en cuestion para 
     * la iva 3
     */
    const handleFileReadIvaThree = async (event) => {

        const file = event.target.files[0]
        const baseImg = await convertBase(file)

        setState({
            ...state,
            fileNameThree: file,
            variables: {
                ...state.variables,
                idOperador: props?.idOperador.toString(),
                idVendedor: props.idVendedor ? props.idVendedor : "",
                terceraDeclaracion: baseImg,
            },
        })
    }

    //funcion para convertir el archivo en cuestion en string base64
    const convertBase = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    /**funcion para enviar a la consulta los archivos seleccionados 
     * para la actualizacion de las ivas
     */
    const handleSubmit = () => {

        SubirDeclaracionIva({
            variables: {
                idOperador: props?.idOperador.toString(),
                idVendedor: props.idVendedor ? props.idVendedor : "",
                primeraDeclaracion: state.variables.primeraDeclaracion,
                segundaDeclaracion: state.variables.segundaDeclaracion,
                terceraDeclaracion: state.variables.terceraDeclaracion,
            },
        })
            .then((r) => {
                console.log("Respuesta de actualizacion del documento: ", r)
            })
            .catch(function (reason) {
                console.error("Error al actualizar la imagen: " + reason)
            })
    }

    /**funcion para cerrar modal */
    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
        reset()
    }

    return (
        <>
            <Fragment>
                {
                    error && (
                        <div className="notification is-danger">
                            <button className="delete"></button>
                            {error.message}
                        </div>
                    )
                }
                {!loading && !data && (
                    <form>
                        <div className="is-fullwidth">
                            <label htmlFor="" className="mb-2">Cargar decalaracion de IVA I:</label>
                            <div className="fileuploader">
                                <input
                                    type="file"
                                    label="Image"
                                    name="file"
                                    accept=".jpeg, .png, .jpg, .pdf"
                                    onChange={(e) => handleFileReadIvaOne(e)}
                                    value={(e) => handleFileReadIvaOne(e)}
                                />
                            </div>
                            <div className="fileselected">
                                {
                                    state?.fileNameOne ?
                                        <>Archivo seleccionado: {state?.fileNameOne?.name}</>
                                        :
                                        <>Archivo no seleccionado</>
                                }
                            </div>
                        </div>
                        <div className="is-fullwidth">
                            <label htmlFor="" className="mb-2">Cargar decalaracion de IVA II:</label>
                            <div className="fileuploader">
                                <input
                                    type="file"
                                    label="Image"
                                    name="file"
                                    accept=".jpeg, .png, .jpg, .pdf"
                                    onChange={(e) => handleFileReadIvaTwo(e)}
                                    value={(e) => handleFileReadIvaTwo(e)}
                                />
                            </div>
                            <div className="fileselected">
                                {
                                    state?.fileNameTwo ?
                                        <>Archivo seleccionado:{state?.fileNameTwo?.name}</>
                                        :
                                        <>Archivo no seleccionado</>
                                }
                            </div>
                        </div>
                        <div className="is-fullwidth">
                            <label htmlFor="" className="mb-2">Cargar decalaracion de IVA III:</label>
                            <div className="fileuploader">
                                <input
                                    type="file"
                                    label="Image"
                                    name="file"
                                    accept=".jpeg, .png, .jpg, .pdf"
                                    onChange={(e) => handleFileReadIvaThree(e)}
                                    value={(e) => handleFileReadIvaThree(e)}
                                />
                            </div>
                            <div className="fileselected">
                                {
                                    state?.fileNameThree ?
                                        <>Archivo seleccionado: {state?.fileNameThree?.name}</>
                                        :
                                        <>Archivo no seleccionado</>
                                }
                            </div>
                        </div>
                    </form>
                )}
                {loading && (
                    <div className="my-5 py-5">
                        <BounceLoader
                            color={state.color}
                            loading={state.loading}
                            css={override}
                            size={50}
                        />
                    </div>
                )}
                {!data ? (
                    <footer className="modal-card-foot pb-0">
                        <button
                            onClick={() => handleSubmit()}
                            className={
                                loading
                                    ? "button is-normal btn-enviar is-loading"
                                    : "button is-normal btn-enviar"
                            }
                        >
                            Guardar
                        </button>
                        <button
                            onClick={() => handleCloseModal()}
                            className="button btn-cancelar"
                            data-bulma-modal="close"
                        >
                            Cancelar
                        </button>
                    </footer>
                ) : (
                    <div className="has-text-centered">
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>Declaraciones de IVA subidas correctamente</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    handleCloseModal()
                                }}
                                className="button is-normal btn-enviar"
                            >
                                Aceptar
                            </button>
                        </footer>
                    </div>
                )}
            </Fragment>
        </>
    )
}

function mapStateToProps({ authedUser }) {
    return {
        ...authedUser
    }
}

export default connect(mapStateToProps)(FormUpdateIva)
