export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_SIDEBAR_STATUS = 'SET_SIDEBAR_STATUS'

export function toggleSidebar ({sidebarStatus}) {
    console.log("Action Creator: ", sidebarStatus);
    return ({
        type: TOGGLE_SIDEBAR,
        sidebarStatus,
    })
}

export function setSidebarStatus(sidebarStatus){
    return{
        type: SET_SIDEBAR_STATUS,
        sidebarStatus,
    }
}

export function handleToggleSidebar (sidebarStatus) {
    return (dispatch) => {
        dispatch(toggleSidebar(sidebarStatus))

    }
}