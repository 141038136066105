import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import ModalContainer from "../common/ModalContainer";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_VENDEDOR } from "../../utils/queries/Queries";
import VendedorDatos from "./VendedorDatos";
import ComisionTabla from "./Tablas/ComisionTabla"
import TransaccionTabla from "./Tablas/TransaccionTabla";
import FacturaTabla from "./Tablas/FacturaTabla";
import FormKYC from "./Forms/FormKYC"
import { handleToggleModal } from "../../actions/modal";
import FormEstadoCuenta from "./Forms/FormEstadoCuenta";
import FormDatosContactos from "./Forms/FormDatosContactos";
import VendedorKycTabla from "./Tablas/VendedorKycTabla";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BounceLoader } from "react-spinners";
import { override } from "../../utils/helpers";
import FormUpdate from "./Forms/FormUpdate";
import VistaImagen from './Forms/VistaImagen';
import VistaImagenDorso from './Forms/VistaImagenDorso';
import VistaSelfie from './Forms/VistaSelfie';
import { Button, Dropdown } from 'react-bulma-components';
import Movimientos from './Forms/Movimientos';
import Comisiones from './Forms/Comisiones';
import FacturaTablaEnviadas from './Tablas/FacturaTablaEnviadas';
import ProtectedComponent from '../common/ProtectedComponent';
import VistaConstancia from './Forms/VistaConstancia';
import FormPEP from './Forms/FormPEP';
import FormCI from './Forms/FormCI';
import VistaIvas from './Forms/VistaIvaUno';
import FormUpdateIva from './Forms/FormUpdateIva';
import VistaIvaUno from './Forms/VistaIvaUno';
import VistaIvaDos from './Forms/VistaIvaDos';
import VistaIvaTres from './Forms/VistaIvaTres';
import VendedorDivice from './Tablas/VendedorDivice';
import Dispositivos from './Forms/Dispositivos';
import FormEstadoDispositivo from './Forms/FormEstadoDispositivo';


const VendedorDetalle = props => {

    const idOperador = props?.authedUser?.idOperador

    //declaramos las variables
    const initialState = {
        tab: {
            comisiones: true,
            transacciones: false,
            facturas: false,
            adjuntas: false,
            kyc: false,
            divice: false,
        },
        form: 'kyc',
        color: '#323980',
        boton: 'PENDIENTES'
    }

    //incializamos
    const [state, setState] = useState(initialState)
    const [informe, setInforme] = useState(false)


    // todo: Comentado mientras no se dinamiza la carga de id
    const { id } = useParams();

    const { sidebarStatus } = props;

    //obtenemos los vendedores
    const { data, loading, error , refetch} = useQuery(GET_VENDEDOR, {
        variables: {
            idVendedor: id,
        }
    })

    const [intervalId, setIntervalId] = useState(null);
    useEffect(() => {
    if (!intervalId) {
      const id = setInterval(() => {
        refetch();
      }, 3000); // poll every 3 seconds
      setIntervalId(id);
    }
    return () => clearInterval(intervalId);
    }, [intervalId, refetch]);

    // funcion para cambiar el state y actualizar la vista de tabs
    const onHandleClick = e => {

        e.preventDefault()
        setState(prev => {
            const updated = {}
            Object.keys(prev.tab).forEach(item => {
                updated[item] = false
            })
            updated[e.target.id] = true

            return {
                tab: {
                    ...updated,
                }

            }
        })
    }

    const handleModalForm = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('edit'))
    }

    const handleView = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('view'))
    }

    const handleViewIva = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('viewIva'))
    }

    const handleUpdate = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('update'))
    }

    // para disparar el modal correspondiente al listado de los movimientos
    const handleFormView = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form,
            }
        })
        props.dispatch(handleToggleModal('ver'))
    }

    // para disparar el modal correspondiente al listado de las comisiones
    const handleFormViewComisiones = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form
            }
        })
        props.dispatch(handleToggleModal('ver'))
    }

    //guardamos el nombre y apellido del vendedor para utilizar a la hora de exportar
    const vendorDataName = data?.vendedor?.nombres + " " + data?.vendedor?.apellidos

    const handleFormViewDivice = (form) => {
        setState(prev => {
            return {
                ...prev,
                form: form
            }
        })
        props.dispatch(handleToggleModal('ver'))
    }

    const pickForm = () => {
        switch (state.form) {
            case 'kyc':
                return <FormKYC idVendedor={data?.vendedor?.id} estadoKYC={data?.vendedor?.estadoKYC} />
            case 'cuenta':
                return <FormEstadoCuenta idVendedor={data?.vendedor?.id} estadoCuenta={data?.vendedor?.estadoCuenta} />
            case 'datos':
                return <FormDatosContactos idVendedor={data?.vendedor?.id} contactos={data?.vendedor?.contactos} />
            case 'dispositivo':
                return <FormEstadoDispositivo idVendedor={data?.vendedor?.id}/>
            case 'documento':
                return <FormUpdate idVendedor={data?.vendedor?.id} idOperador={idOperador} />
            case 'FRENTE':
                return <VistaImagen idVendedor={data?.vendedor?.id} />
            case 'DORSO':
                return <VistaImagenDorso idVendedor={data?.vendedor?.id} />
            case 'SELFIE':
                return <VistaSelfie idVendedor={data?.vendedor?.id} />
            case 'MOVIMIENTOS':
                return <Movimientos vendorID={id} vendorData={vendorDataName} />
            case 'COMISIONES':
                return <Comisiones vendorID={id} />
            case 'CONSTANCIA':
                return <VistaConstancia vendorID={id} />
            case 'IVA_1':
                return <VistaIvaUno idVendedor={data?.vendedor?.id} dataIVA={a_Iva} />
            case 'IVA_2':
                return <VistaIvaDos idVendedor={data?.vendedor?.id} dataIVA={a_Iva} />
            case 'IVA_3':
                return <VistaIvaTres idVendedor={data?.vendedor?.id} dataIVA={a_Iva} />
            case 'IVA':
                return <FormUpdateIva idVendedor={data?.vendedor?.id} idOperador={idOperador} />
            case 'PEP':
                return <FormPEP idVendedor={data?.vendedor?.id} estadoPEP={data?.vendedor?.pep} cedulaVencimiento={data?.vendedor.fechaVencimientoDocumento} />
            case 'CI':
                return <FormCI idVendedor={data?.vendedor?.id} estadoPEP={data?.vendedor?.pep} cedulaVencimiento={data?.vendedor.fechaVencimientoDocumento} />
            case 'DISPOSITIVOS':
                return <Dispositivos vendorID={id} />
            default:
                return ''
        }
    }

    const [botonTab, setBotonTab] = useState({ btnPendiente: true, btnEnviadas: false })
    const [botonInforme, setBotonInforme] = useState(false)


    //funcion para previsualizar pdf en adjuntas
    const previsualizarPdf = (dataPdf) => {
        return (
            <div className=''>
                <object className='viewerDoc' data={dataPdf} type="application/pdf" width="280" height="150" >
                    <embed src={dataPdf} type="application/pdf" />
                </object>
            </div>
        )
    }

    /*Array para guardar valores de decalaracion de ivas*/
    const a_Iva = data?.vendedor?.imagenes?.filter(imagen => imagen?.tipoImagen === 'DECLARACION_IVA')

    return (

        <div className="vendedor-page">
            {props.modal.modalStatus && (
                <ModalContainer title={state.form} form={pickForm()} />
            )}
            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    {!loading ? (
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li>
                                    <Link to="/Dashboard">Vendedores</Link>
                                </li>
                                <li className="is-active">
                                    <Link to="/" aria-current="page">
                                        {data?.vendedor?.nombres} {data?.vendedor?.apellidos}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    ) : 'Cargando...'}

                    <section
                        className={sidebarStatus === 'open' ? 'section content-box pd-un' : 'section content-box pd-un content-box-expand'}
                        id="cont-box">
                        <div className="columns">
                            <div className="column d-f jf-cont">
                                <h1 className="title-details ml-5 pt-3">Datos Personales</h1>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column py-0">
                                <hr className='my-0' />
                            </div>
                        </div>
                        <div className="pt-0 columns pd-un has-text-left">
                            {loading
                                ? (<BounceLoader color={state.color} loading={state.loading} css={override} size={50} />)
                                : <VendedorDatos vendedor={data?.vendedor} />
                            }

                            <div className="column  is-3 b-l">
                                <div className="column">
                                    <h1 className="title-details">Opciones</h1>
                                </div>
                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO']}>
                                    <div className="column">
                                        <button
                                            onClick={() => handleModalForm('cuenta')}
                                            className="button btn-editar text-color">
                                            Editar Estado
                                        </button>
                                    </div>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO',]}>
                                    <div className="column">
                                        <button
                                            onClick={() => handleModalForm('kyc')}
                                            className="button btn-editar text-color">
                                            <i className="fas fa-pencil-alt mr-2"> </i>
                                            Editar KYC
                                        </button>
                                    </div>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR', 'OPERADOR',]}>
                                    <div className="column">
                                        <button
                                            onClick={() => handleModalForm('datos')}
                                            className="button btn-editar text-color">
                                            <i className="fas fa-pencil-alt mr-2"> </i>Editar datos
                                        </button>
                                    </div>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO']}>
                                    <div className="column">
                                        <button
                                            onClick={() => handleModalForm('dispositivo')}
                                            className="button btn-editar text-color">
                                            <i className="fas fa-pencil-alt mr-2"> </i>
                                            Desvincular dispositivo
                                        </button>
                                    </div>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    allowedRoles={['CUMPLIMIENTO',]}>
                                    <div className="column">
                                        <button
                                            onClick={() => handleModalForm('PEP')}
                                            className="button btn-editar text-color">
                                            <i className="fas fa-pencil-alt mr-2"> </i>Editar PEP
                                        </button>
                                    </div>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    allowedRoles={['CUMPLIMIENTO']}>
                                    <div className="column">
                                        <button
                                            onClick={() => handleModalForm('CI')}
                                            className="button btn-editar text-color">
                                            <i className="fas fa-pencil-alt mr-2"> </i>Editar Vencimineto CI
                                        </button>
                                    </div>
                                </ProtectedComponent>
                            </div>
                        </div>
                    </section>
                    <section className="hero mt-4 mb-6 ">
                        <div className="hero-foot">
                            <nav className="tabs is-boxed is-fullwidth">
                                <div className="">
                                    <ul>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'OPERADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.comisiones ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='comisiones' onClick={e => onHandleClick(e)}>COMISIONES</a>
                                            </li>
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'OPERADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.transacciones ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='transacciones' onClick={e => onHandleClick(e)}>MOVIMIENTOS</a>
                                            </li>
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'OPERADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.facturas ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='facturas' onClick={e => onHandleClick(e)}>FACTURAS</a>
                                            </li>
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.adjuntas ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='adjuntas' onClick={e => onHandleClick(e)}>ADJUNTAS</a>
                                            </li>
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'OPERADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.kyc ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='kyc' onClick={e => onHandleClick(e)}>MODIFICACIONES KYC</a>
                                            </li>
                                        </ProtectedComponent>
                                        <ProtectedComponent
                                            allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO']}>
                                            <li className={state.tab.divice ? 'tab is-active' : 'tab'}>
                                                <a href='/' id='divice' onClick={e => onHandleClick(e)}>DISPOSITIVOS</a>
                                            </li>
                                        </ProtectedComponent>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className=" section">
                            <div id="Comisiones" className={state.tab.comisiones ? 'content-tab' : 'content-tab is-hidden'}>
                                {state.tab.comisiones &&
                                    <>
                                        <ComisionTabla vendorID={id} />
                                        {
                                            <div>
                                                <Button onClick={() => handleFormViewComisiones('COMISIONES')} >ver todas las comisiones</Button>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            <div id="Transacciones" className={state.tab.transacciones ? 'content-tab' : 'content-tab is-hidden'} >
                                {state.tab.transacciones &&
                                    <>
                                        <TransaccionTabla vendorID={id} />
                                        {
                                            <div>
                                                <Button onClick={() => handleFormView('MOVIMIENTOS')} >ver todos los movimientos</Button>
                                            </div>
                                        }
                                    </>

                                }
                            </div>
                            <div id="Facturas" className={state.tab.facturas ? 'content-tab' : 'content-tab is-hidden'} >
                                <div className="columns">
                                    <div className="column is-full d-f">
                                        <div className='column is-3'><h3 className="pt-text">Estado de facturas:</h3></div>
                                        <div className="column is-9">
                                            <button onClick={() => setBotonTab({ ...botonTab, btnPendiente: true, btnEnviadas: false })} className="button btn-estado is-active" id="btnpendiente">Pendientes</button>
                                            <button onClick={() => setBotonTab({ ...botonTab, btnPendiente: false, btnEnviadas: true })} class="button btn-estado is-active ml-2" id="btnenviadas">Enviadas</button>
                                        </div>
                                    </div>
                                </div>
                                <div id="pendientes" className="">
                                    {
                                        botonTab.btnPendiente === true &&
                                        <>
                                            <div className="columns">
                                                <div className="column">
                                                    <h1>Lista de facturas pendientes</h1>
                                                </div>
                                            </div>
                                            {state.tab.facturas &&
                                                < FacturaTabla vendorID={id} />
                                            }
                                        </>
                                    }
                                    {
                                        botonTab.btnEnviadas === true &&
                                        <>
                                            <div className="columns">
                                                <div className="column">
                                                    <h1>Lista de facturas enviadas</h1>
                                                </div>
                                            </div>
                                            < FacturaTablaEnviadas vendorID={id} />
                                        </>
                                    }
                                </div>
                            </div>
                            <div id="Adjuntas" className={state.tab.adjuntas ? 'content-tab' : 'content-tab is-hidden'}>
                                <div className='columns'>
                                    <div className='column a-end'>
                                        <button className="button btn-estado is-active" onClick={() => handleUpdate('documento')} id="update"><FontAwesomeIcon icon={['fas', 'id-badge']} color='#FFF' className='icon-sidebar' /> Actualizar documento</button>
                                    </div>
                                </div>
                                <div className="columns mb-4">
                                    <div className="column is-3" id='CI_FRONT' onClick={() => handleView('FRENTE')}>
                                        <label htmlFor="">Cédula (Frente):</label>
                                        <div className="box-ci">
                                            <div className="ml-4 mt-4 d-f ">
                                                <img src={data?.vendedor?.imagenes.find(imagen => imagen.tipoImagen === 'FRENTE')?.urlImagen} alt="" />
                                            </div>
                                        </div>
                                        <div className="d-f" id='subtitledoc'>
                                            <div>
                                                <span id='docview'>ver documento</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-3" id='CI_DORS' onClick={() => handleView('DORSO')}>
                                        <label htmlFor="">Cédula (dorso):</label>
                                        <div className="box-ci">
                                            <div className="ml-4 mt-4 d-f mb-2">
                                                <img src={data?.vendedor?.imagenes.find(imagen => imagen.tipoImagen === 'DORSO')?.urlImagen} alt="" />
                                            </div>
                                        </div>
                                        <div className="d-f" id='subtitledoc'>
                                            <div>
                                                <span id='docview'>ver documento</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-3" id='CI_DORS' onClick={() => handleView('SELFIE')} >
                                        <label htmlFor="">Selfie:</label>
                                        <div className="box-ci">
                                            <div className="ml-4 mt-4 d-f mb-2">
                                                <img src={data?.vendedor?.imagenes.find(imagen => imagen.tipoImagen === 'SELFIE')?.urlImagen} alt="" />
                                            </div>
                                        </div>
                                        <div className="d-f" id='subtitledoc'>
                                            <div>
                                                <span id='docview'>ver documento</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-3" id='CI_DORS' onClick={() => handleView('CONSTANCIA')} >
                                        <label htmlFor="">Constancia RUC:</label>
                                        <div className="box-ci">
                                            {previsualizarPdf(data?.vendedor?.imagenes.find(imagen => imagen?.tipoImagen === 'CONSTANCIA')?.urlImagen)}
                                        </div>
                                        <div className="d-f" id='subtitledoc'>
                                            <div>
                                                <span id='docview'>ver documento</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='columns mt-5' id='declareiva' >
                                    <div className="column is-3">
                                        <label htmlFor="" className='titleiva'>Declaraciones de IVA</label>
                                    </div>
                                    <ProtectedComponent
                                        allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO']}>
                                        <div className='column a-end'>
                                            <button className="button btn-estado is-active" onClick={() => handleUpdate('IVA')} id="updateIva"> <FontAwesomeIcon icon="fa-solid fa-file-lines" color='#FFF' className='icon-sidebar' /> Actualizar Iva</button>
                                        </div>
                                    </ProtectedComponent>
                                </div>
                                <div className='columns'>
                                    {
                                        a_Iva?.map((item, index) => {
                                            if (index === 0) {
                                                return (
                                                    <div className="column is-3" >
                                                        <label htmlFor="">DECLARACION IVA I:</label>
                                                        <div className="box-ci">
                                                            {previsualizarPdf(item?.urlImagen)}
                                                        </div>
                                                        <div className="d-f" id='subtitledoc' onClick={() => handleViewIva('IVA_1')} >
                                                            <div>
                                                                <span id='docview'>ver documento</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            } else if (index === 1) {
                                                return (
                                                    <div className="column is-3" >
                                                        <label htmlFor="">DECLARACION IVA II:</label>
                                                        <div className="box-ci">
                                                            {previsualizarPdf(item?.urlImagen)}
                                                        </div>
                                                        <div className="d-f" id='subtitledoc' onClick={() => handleViewIva('IVA_2')} >
                                                            <div>
                                                                <span id='docview'>ver documento</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="column is-3" >
                                                        <label htmlFor="">DECLARACION IVA III:</label>
                                                        <div className="box-ci">
                                                            {previsualizarPdf(item?.urlImagen)}
                                                        </div>
                                                        <div className="d-f" id='subtitledoc' onClick={() => handleViewIva('IVA_3')} >
                                                            <div>
                                                                <span id='docview'>ver documento</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div id="Kyc" className={state.tab.kyc ? 'content-tab' : 'content-tab is-hidden'} >
                                <div className="columns">
                                    <div className="column is-full d-f">
                                        <div className='column is-3'><h3 className="pt-text">Listado de modificaciones KYC:</h3></div>
                                        <div className="column is-9">
                                            <Link className="button btn-estado is-active" to={{ pathname: `/vendedor/${id}/informe` }} onclick={() => window.location.reload()}>Informe detallado</Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        <>
                                            {state.tab.kyc &&
                                                <VendedorKycTabla vendorId={id} />
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div id="divice" className={state.tab.divice ? 'content-tab' : 'content-tab is-hidden'} >
                                <div className="columns">
                                    <div className='column is-3'>
                                        <h3 className="pt-text">Listado de dispositivos:</h3>
                                    </div>
                                </div>
                                <div>
                                    {
                                        <>
                                            {state.tab.divice &&
                                                <VendedorDivice vendorId={id} />
                                            }
                                            {
                                                <div>
                                                    <Button onClick={() => handleFormViewDivice('DISPOSITIVOS')} >ver todos los dispositivos</Button>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>

        </div>
    )
}

function mapStateToProps({ sidebarStatus, modal, authedUser }) {
    return {
        sidebarStatus,
        modal,
        authedUser
    }
}

export default connect(mapStateToProps)(VendedorDetalle)