import { gql } from '@apollo/client';

export const GET_CATEGORIAS = gql`
    query Categorias {
        categorias {
            id
            nombre
            icono
            color
        }
    }
`;

export const GET_SERVICIOS = gql`
    query getServicios {
        servicios {
            id
            nombre
            habilitado
            idServicioRed
            red
            diligenciaAmpliada
            facturador {
                id
                nombre
                categoria {
                    id
                    nombre
                    icono
                }
            }
            comision {
                id
                empresa
                vendedor
            }
        }
    }
`;

export const GET_SERVICIOS_REDES = gql`
    query ServiciosRedes($red: Red!) {
        serviciosRedes(red: $red) {
            id
            nombre
            facturador {
                id
                nombre
            }
        }
    }
`;

export const GET_FACTURADORES = gql`
    query getFacturadores {
        facturadores {
            id
            nombre
            fechaInsercion
            fechaActualizacion
            categoria {
                id
                nombre
                icono
            }
        }
    }
`;


export const VENDEDORES = gql`   
query Vendedores(
  $filtros: FiltroListaVendedorInput
  $paginacion: OrdenListaVendedorInput
) {
  vendedores(filtros: $filtros, paginacion: $paginacion) {
    id
    apellidos
    nombres
    nroDocumento
    ruc
    fechaInsercion
    fechaActualizacion
    pep
    estadoKYC
    estadoCuenta
  }
}
`;

export const CANT_VENDEDORES = gql`
    query cantidadVendedores {
        cantidadVendedores
    }
`;

export const OLD_GET_VENDEDOR = gql`
    query Vendedor($idVendedor: ID!) {
        vendedor(idVendedor: $idVendedor) {
            id
            nombres
            apellidos
            estadoKYC
            estadoCuenta
            nroDocumento
            ruc
            fechaInsercion
            fechaActualizacion
            fechaNacimiento

            actividad {
                id
                descripcion
            }
            tipoDocumento {
                id
                descripcion
                abreviatura
            }
            cargas {
                id
                actividad
                envios {
                    canal
                    contacto
                    fecha
                }
                estado

                fechaSolicitud
                idTransaccion
                idTransaccionExterno
                monto
                urlComprobante

            }
            facturas {
                id
                concepto
                imagen {
                    id
                    urlImagen
                }
                periodoFacturacion
                monto
                moneda {
                    id
                    nombre
                }
                fechaInsercion
                estadoActual
            }
            paisNacimiento {
                id
                descripcion
                nacionalidad
                isoAlfa2
            }
            direccionParticular
            imagenes {
                id
                idVendedor
                tipoImagen
                urlImagen
                fechaInsercion
                fechaActualizacion
            }
            ubicacion {
                id
                descripcion
                departamento {
                    descripcion
                    id
                    pais {
                        id
                        descripcion
                        isoAlfa2
                    }
                }
            }
            fechaVencimientoDocumento
            comisiones {
                id
                estado
                monto
                fechaSolicitud
                actividad
                servicio {
                    id
                    nombre
                }
                fechaRespuesta
            }
            ventas {
                id
                monto
                servicio {
                    id
                    nombre
                    facturador {
                        id
                        nombre
                    }
                }
                fechaRespuesta
                fechaSolicitud
                estado
                idTransaccion
                actividad
                urlComprobante
            }
            contactos {
                tipo
                valor {
                    ... on Correo {
                        direccion
                    }
                    ... on Telefono {
                        numero
                    }
                }
                verificado
                fechaInsercion
            }
        }
    }
`;

export const GET_VENDEDOR = gql`
    query Vendedor($idVendedor: ID!) {
        vendedor(idVendedor: $idVendedor) {
            id
            nombres
            apellidos
            estadoKYC
            estadoCuenta
            nroDocumento
            ruc
            fechaInsercion
            fechaActualizacion
            fechaNacimiento

            actividad {
                id
                descripcion
            }
            tipoDocumento {
                id
                descripcion
                abreviatura
            }
            paisNacimiento {
                id
                descripcion
                nacionalidad
                isoAlfa2
            }
            direccionParticular
            ubicacion {
                id
                descripcion
                departamento {
                    descripcion
                    id
                    pais {
                        id
                        descripcion
                        isoAlfa2
                    }
                }
            }
            fechaVencimientoDocumento
            contactos {
                tipo
                valor {
                    ... on Correo {
                        direccion
                    }
                    ... on Telefono {
                        numero
                    }
                }
                verificado
                fechaInsercion
                habilitado
            }
            imagenes {
                tipoImagen
                idVendedor
                id
                fechaInsercion
                fechaActualizacion
                urlImagen
            }
            pep
            receptora
            saldo
        }
    }
`;

export const GET_VENDEDOR_MODIFICACIONES_KYC = gql`
    query VendedorModificacionesKYC($idVendedor: ID!) {
        vendedor(idVendedor: $idVendedor) {
            id
            modificacionesKYC {
                id
                fechaHora
                estado
                comentario
                operador {
                    id
                    id
                    nombres
                    apellidos
                    correo
                }
            }
        }
    }
`;

export const GET_VENDEDOR_COMISIONES = gql`
    query getVendedorComisiones($idVendedor: ID!) {
        vendedor(idVendedor: $idVendedor) {
            id
            comisiones {
                id
                estado
                monto
                fechaSolicitud
                actividad
                servicio {
                    id
                    nombre
                }
                fechaRespuesta
            acreditadoNetel
            acreditadoCabecera
            }            
        }
    }
`;

export const GET_VENDEDOR_VENTAS = gql`
    query Vendedor($idVendedor: ID!) {
        vendedor(idVendedor: $idVendedor) {
            id
            ventas {
                id
                monto
                servicio {
                    id
                    nombre
                    facturador {
                        id
                        nombre
                    }
                }
                fechaRespuesta
                fechaSolicitud
                estado
                idTransaccion
                actividad
                urlComprobante
            }
            cargas {
                id
                monto
                servicio {
                    id
                    nombre
                    facturador {
                        id
                        nombre
                    }
                }
                estado
                actividad
                fechaRespuesta
                fechaSolicitud
            }
            transferencias {
                id
                monto
                fechaRespuesta
                servicio {
                    id
                    nombre
                    facturador {
                        id
                        nombre
                    }
                }
                estado
                actividad
                fechaRespuesta
                fechaSolicitud
            }
        }
    }
`;

export const GET_VENDEDOR_MOVIMIENTOS = gql`
query Movimientos(
    $idVendedor: Int!
    $fechaInicio: Date
    $fechaFin: Date
    $actividad: Actividad
  ) {
    movimientos(
      idVendedor: $idVendedor
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      actividad: $actividad
    ) {
      id
      actividad
      estado
      monto
      idTransaccion
      idTransaccionExterno
      fechaSolicitud
      fechaRespuesta
      acreditadoNetel
      acreditadoCabecera
      servicio {
        id
        nombre
        facturador {
          id
          nombre
        }
      }
    }
  }
`;

export const GET_VENDEDOR_FACTURAS = gql`
    query Vendedor($idVendedor: ID!) {
        vendedor(idVendedor: $idVendedor) {
            id
            facturas {
                id
                concepto
                imagen {
                    id
                    urlImagen
                }
                periodoFacturacion
                monto
                moneda {
                    id
                    nombre
                }
                fechaInsercion
                estadoActual
            }

        }
    }
`;

export const VALIDAR_CORREO_VENDEDOR = gql`
    query ValidarCorreoVendedor(
        $id: ID!, 
        $token: String!
    ){
        validarCorreoVendedor(
            id: $id, 
            token: $token
        )
    }
`;


export const GET_IMAGENES = gql`

query Vendedor($idVendedor: ID!) {
    vendedor(idVendedor: $idVendedor) {
      imagenes {
        id
        idVendedor
        tipoImagen
        urlImagen
        fechaInsercion
        fechaActualizacion
      }
    }
  }
`;

export const GET_ICONS = gql`
    query Iconos($idIcono: ID) {
        iconos(idIcono: $idIcono) {
            figura
            id
        }
    }
`;

export const GET_COLORS = gql`
    query Query($idColor: ID) {
        colores(idColor: $idColor) {
        id
        descripcion
        rgba
        }
    }
`;


export const GET_OPERATORS = gql`
    query Operadores {
        operadores {
        apellidos
        correo
        documento
        fechaInsercion
        habilitado
        id
        nombres
        rol
        }
    }
`;


// Query que se encarga de obtener la URL del comprobante
// de venta.
export const COMPROBANTE_VENTA = gql`
    query Query(
        $idDetalle: ID!, $idComprobante: ID!
    ) {
        obtenerUrlComprobanteVenta(
            idDetalle: $idDetalle, 
            idComprobante: $idComprobante
        )
    } 
`

export const GET_NOTIFICATIONS = gql`
query GetContenidos($producto: String!) {
    getContenidos(producto: $producto) {
      idContenido
      titulo
      estado
      fechaInsercion
      fechaActualizacion
      idMultimedia
      media
      idPrioridad
      contenido
      descripcionSegmento
      idProducto
      idRegla
      fechaInicio
      fechaFin
    }
  }
`

export const GET_NOTIFICATIONS_BY_ID = gql`
    query GetContenidoById($idContenido: ID!) {
        getContenidoById(idContenido: $idContenido) {
        idContenido
        titulo
        contenido
        estado
        fechaInsercion
        fechaActualizacion
        idMultimedia
        media
        idPrioridad
        idProducto
        descripcionSegmento
        idRegla
        fechaInicio
        fechaFin
        }
    }
`

export const GET_REGLA_BY_ID = gql`
    query GetReglaById($idRegla: ID!) {
        getReglaById(idRegla: $idRegla) {
        consulta
        observacion
        idRegla
        fechaInsercion
        fechaActualizacion
        }
    }
`

// Query que obtiene las reglas predefinidas en el MongoDB en 
// la colección push a partir del TipoProducto, actualmente el 
// tipo producto definido es BOMO
export const GET_REGLA_BY_PRODUCTO = gql`
    query getReglaByProducto(
        $idProducto: ID!
    ) {
        getReglaByProducto(
            idProducto: $idProducto
        )
    }    
`

export const GET_INFORME_DETALLADO_BY_ID = gql`
query Query($idVendedor: ID!) {
    obtenerInformeDetallado(idVendedor: $idVendedor) {
      informe
      urlInforme
    }
  }
`

export const GET_VENDEDOR_DIVICE = gql`
query Dispositivos($idVendedor: ID!) {
    vendedor(idVendedor: $idVendedor) {
    allDispositivos {
      activo
      descripcion
      fechaActualizacion
      fechaInsercion
      firma
      id
      idVendedor
    }
  }
  }
`