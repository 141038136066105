import { useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { handleToggleModal } from "../../../actions/modal";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { GET_VENDEDOR } from "../../../utils/queries/Queries";
import { BounceLoader } from "react-spinners";
import { override } from "../../../utils/helpers";


const VistaConstancia = props => {

    //guardamos el id del vendedor en cuestion
    const { id } = useParams();

    //querie consulta de vendedores
    const { data, loading, error } = useQuery(GET_VENDEDOR, {
        variables: {
            idVendedor: id,
        }
    })

    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
    }

    //funcion para previsualizar pdf en adjuntas
    const previsualizarPdf = (dataPdf) => {
        return (
            <div className=''>
                <object className='viewerDocConstancia' data={dataPdf} type="application/pdf"  >
                    <embed src={dataPdf} type="application/pdf" />
                </object>
            </div>
        )
    }

    return (
        <>
            <Fragment>
                {error && (
                    <div className="notification is-danger">
                        <button className="delete"></button>
                        {error.message}
                    </div>
                )}
                {!loading && (
                    !data && (
                        <form>
                            <div className='is-fullwidth'>
                                <label htmlFor="" className='mb-2'>Imagen</label>
                                <div className="ml-4 mt-4 d-f ">
                                    {previsualizarPdf(data?.vendedor?.imagenes.find(imagen => imagen?.tipoImagen === 'CONSTANCIA')?.urlImagen)}
                                </div>
                            </div>
                        </form>
                    )
                )}{loading && (
                    <div className='my-5 py-5'>
                        <BounceLoader color={props.color} loading={props.loading} css={override} size={50} />
                    </div>
                )}
                {!data
                    ? (

                        <footer className="modal-card-foot pb-0">
                            <button
                                onClick={() => handleCloseModal()}
                                className="button btn-cancelar" data-bulma-modal="close">Cancelar
                            </button>
                        </footer>
                    )
                    : (
                        <>
                            <div className='has-text-centered' id="modalConstancia">
                                <div className="ml-4 mt-4 d-f ">
                                    {previsualizarPdf(data?.vendedor?.imagenes.find(imagen => imagen?.tipoImagen === 'CONSTANCIA')?.urlImagen)}
                                </div>
                            </div>
                            <footer className="modal-card-foot pb-0">
                                <button
                                    onClick={() => handleCloseModal()}
                                    className="button btn-cancelar" data-bulma-modal="close">Cancelar
                                </button>
                            </footer>
                        </>

                    )
                }
            </Fragment>
        </>
    )


}


function mapStateToProps({ authedUser }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(VistaConstancia);