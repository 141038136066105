import { useQuery } from '@apollo/client';
import React from 'react'
import { formatDataIcons } from '../../../utils/helpers';
import { GET_ICONS } from '../../../utils/queries/Queries';


const SelectIcon = (props) => {

    //Consulta graphql donde consumimos el recurso para mostrar 
    //el listado de iconos desde la base de datos
    const { loading, data, error } = useQuery(GET_ICONS);

    // todo: Formatear los datos de los iconos correctamente
    const formatedList = formatDataIcons(data)

    return (
        <div className="field">
            <label className="label" htmlFor="">Icono de la categoría:</label>
            <div className="control">
                <div className='select is-fullwidth'>
                    {loading && (<button className="button select is-loading is-fullwidth"> </button>)}

                    {data && (
                        <select
                            name="icono"
                            onChange={(e) => props.handleChange(e)}
                            value={props.defaultValue ? props.defaultValue : ''}
                        >
                            <option value='' disabled>Seleccione un icono</option>
                            {Object.keys(formatedList).map(item => {
                                return (
                                    <option key={item} value={formatedList[item].figura}> {formatedList[item].id} - {formatedList[item].figura}</option>
                                )
                            })}
                        </select>
                    )}

                </div>
            </div>
        </div>
    )
}

export default SelectIcon