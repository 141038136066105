import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react'
import { GET_REGLA_BY_PRODUCTO } from '../../../utils/queries/Queries'

const SelectReglaByProducto = (props) => {

    //Consulta graphql donde consumimos el recurso para mostrar 
    //el listado de colores desde la base de datos
    const reglaByProducto = useQuery(
        GET_REGLA_BY_PRODUCTO,
        { variables: { idProducto: "BOMO" } }
    )

    // Desestructura los datos a utilizar.
    const { data, error, loading } = reglaByProducto
    let listadoDeReglas = []

    if (data) {
        // Obtiene los datos del query
        const reglasDelMongoDB = data.getReglaByProducto
        // Verifica la longitud del array
        if (reglasDelMongoDB.length > 0) {
            listadoDeReglas = reglasDelMongoDB.map(
                regla => {
                    return {
                        value: regla._id,
                        label: regla.descripcion
                    }
                }
            )
        }
        else {
            listadoDeReglas = []
        }
        return (
            <div className="column">
                <div className="field">
                    <label className="label" htmlFor="">Regla:</label>
                    <div className="control">
                        <div className='select is-fullwidth'>

                            {data && (
                                <select
                                    name="idReglaPredefinida"
                                    onChange={(e) => props.handleChange(e)}
                                    value={props.defaultValue ? props.defaultValue : ''}
                                >
                                    <option value="" disabled >Seleccione la regla.</option>
                                    {listadoDeReglas.map((x, i) => {
                                        return <option key={i} value={x.value}>{x.label}</option>
                                    })}
                                </select>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="column">
                <div className="field">
                    <label className="label" htmlFor="">Regla:</label>
                    <div className="control">
                        <div className='select is-fullwidth'>
                            <select
                                name="idReglaPredefinida"
                                onChange={(e) => props.handleChange(e)}
                                value={props.defaultValue ? props.defaultValue : ''}
                            >
                                <option value="" disabled >Seleccione la regla.</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SelectReglaByProducto