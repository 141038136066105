import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Pagination } from 'react-bulma-components';
import { sortData, takeData } from '../../../utils/helpers';

const TablaDetalle = (props) => {


    const { cabecera, detalles } = props;
    const [state, setState] = useState({ sort: {}, currentPage: 1 })

    const handleCurrentPage = detalles => {
        setState(prev => ({
            ...prev,
            currentPage: detalles,
        }))
    }

    //ordenamos el listado mediante los filtros aplicados
    const listData = takeData(sortData(Object.keys(detalles).reverse().map(item => detalles[item]), state.sort), state.currentPage)

    const handleClick = (e) => {
        console.log('filter handle clicl', e.target)
    }

    // funcion para filtrar dato segun su tipo y asi poder asignar estilos correspondientes
    const dataFilter = (data) => {
        if (data === "VENTA" || data === "WALLY") {
            return (
                <>
                    <span className='egreso'>EGRESO <ion-icon name="trending-up-outline"></ion-icon></span>
                </>
            )
        }
        if (data === "CARGA" || data === "COMIS") {
            return (
                <>
                    <span className='ingreso'>INGRESO <ion-icon name="trending-down-outline"></ion-icon> </span>
                </>
            )
        }
        if (data === 'ACREDITADA') {
            return (
                <>
                    <span className='ingreso'> {data}  </span>
                </>
            )
        }
        if (data === 'PENDIENTE') {
            return (
                <>
                    <span className='box-pending'>{data}  </span>
                </>
            )
        }
        else {
            return data
        }
    }

    return (
        <>
            <div className="columns">
                <div className="column">
                    <table className="table is-striped table-list">
                        <thead>
                            <tr className="table-header">
                                {Object.keys(cabecera).map(item => {
                                    return (
                                        <th key={cabecera[item].id !== 'fechaFilter'} className="al">
                                            <Link to="" id={'filter-' + item} onClick={e => handleClick(e)}>
                                                {cabecera[item].label} <FontAwesomeIcon icon={['fas', 'sort']} className='ml-2' />
                                            </Link>
                                        </th>
                                    )
                                })}

                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(listData).map(row => {
                                return (
                                    <tr key={row} className="tr-size ">
                                        {Object.keys(listData[row]).filter(item => item !== 'fechaFilter').map(item => {
                                            return (
                                                <td key={item}>{dataFilter(listData[row][item])}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
            <div className='pt-5'>
                <Pagination
                    current={state.currentPage}
                    next={'>'}
                    previous={'<'}
                    total={Object.keys(detalles).length / 10 % 1 == 0 ? Math.floor(Object.keys(detalles).length / 10) : Math.floor(Object.keys(detalles).length / 10) + 1}
                    onChange={handleCurrentPage}
                />
            </div>
        </>
    )
}

export default TablaDetalle;