import React, { Component, useState } from "react";
import Select from "../common/Select";


const FiltroTableUsers = props => {

    //declaramos variables a utilizar
    const initialState = {
        nombre: '',
        habilitado: 'TODAS',
        rol: 'TODAS'
    }

    //inicializamos las variables 
    const [state, setState] = useState(initialState)

    //seteamos el select segun lo seleccionado
    const setSelectedValue = (data) => {
        // Si hay cambios en los selects se guarda en el state del componente
        // Y se envia al componte padre para aplicar luego el filtro a la tabla
        props.getFilter(data)
        setState(prevState => (
            {
                ...prevState,
                [data.key]: data.value,
            }
        ))
    }

    //para limpiar los filtros
    const handleCleanFilter = () => {
        setState(prev => initialState)
        props.onCleanFilter(initialState)
    }

    // Select para estado de cuenta del usuario
    const selectStatus = {
        slice: 'habilitado',
        label: 'Estado de cuenta',
        default: '',
        disabled: 'Seleccionar estado',
        options: {
            TODAS: 'Todas',
            ACTIVA: 'ACTIVA',
            BLOQUEADA: 'BLOQUEADA'
        }
    }

    // Select para estado de cuenta del usuario
    const selectRol = {
        slice: 'rol',
        label: 'Rol de usuario',
        default: '',
        disabled: 'Seleccionar rol',
        options: {
            TODAS: 'Todas',
            ADMINISTRADOR:'ADMINISTRADOR',
            OPERADOR: 'OPERADOR',
            CUMPLIMIENTO:'CUMPLIMIENTO',
            SEGURIDAD: 'SEGURIDAD',
            MARKETING:'MARKETING'
        }
    }

    return (
        <div className="columns">
            <div className="column">
                <div className="item-column has-text-left">
                    <Select defaultValue={state.habilitado} optionList={selectStatus} setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column">
                <div className="item-column has-text-left">
                    <Select defaultValue={state.rol} optionList={selectRol} setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column align">
                <div className="item-column">
                    <button className="button btn-other mt-5" onClick={() => handleCleanFilter()}>Limpiar</button>
                </div>
            </div>
        </div>
    )
}

export default FiltroTableUsers;