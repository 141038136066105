import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { Toaster } from 'react-hot-toast'
import { connect } from 'react-redux'
import { handleToggleModal } from '../../actions/modal'
import { DISABLE_OPERADOR } from '../../utils/mutators/Mutators'



const ActionsUsersList = (props) => {

    //para setear el tipo de modal
    const handleOnClick = (e, btn) => {
        e.preventDefault()

        if (btn === '_btnDelete') {
            props.dispatch(handleToggleModal('delete', props.dataID))
        }

        if (btn === '_btnEdit') {
            props.dispatch(handleToggleModal('edit', props.dataID));
        }

    }

    // props.dataID
    const [DeshabilitarOperador, { loading }] = useMutation(DISABLE_OPERADOR, {
        variables: {
            idOperador: props.dataID,
        },
    });


    return (
        <Fragment>
            <button id='_btnEdit' className='button is-link is-inverted'
                onClick={(e) => handleOnClick(e, '_btnEdit')}
            >
                <FontAwesomeIcon icon={['fas', 'pen']} />
            </button>
            <button id='_btnDelete'
                className={loading ? 'button is-loading' : 'button is-danger is-inverted '}
                onClick={(e) => handleOnClick(e, '_btnDelete')}
            >
                <FontAwesomeIcon icon="fa-solid fa-ban" />
            </button>
            <Toaster position="top-right" toastOptions={{
                className: '',
                style: {
                    border: '1px solid rgba(85, 82, 217, 0.2)',
                    padding: '16px',
                    color: '#713200',
                },
            }} />
        </Fragment>
    )
}

function mapStateToProps({ modal }) {
    return {
        modal,
    }
}

export default connect(mapStateToProps)(ActionsUsersList)
