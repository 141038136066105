import React, { Fragment, useEffect, useState } from 'react'
import { filterDataList, formatDataUsers, override, takeDataSearch } from '../../utils/helpers'
import { useQuery } from "@apollo/client";
import { GET_OPERATORS } from "../../utils/queries/Queries";
import Table from '../common/Table';
import { PuffLoader } from 'react-spinners';
import TableSearch from '../common/TableSearch';


const TableUsers = (props) => {

    //se declara las variables a tulizar
    const [state, setState] = useState(
        {
            filtros: {
                nombre: '',
                documento: '',
                estado: '',
                rol: '',
            },
            headers: {
                nombre: 'Nombre y Apellido',
                documento: 'NRO DE DOCUMENTO',
                fecha: 'Fecha de Registro',
                rol: 'Rol',
                habilitado: 'Estado',
                correo: 'Correo',
                actions: 'ACCIONES'
            },
            loading: true,
            color: '#323980',
            currentPage: 1,
        }
    )

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))
    }, [props.filtros])

    //consultamos el listado de operadores del sistema
    const { data, loading, error } = useQuery(GET_OPERATORS);

    //capturamos los datos de los operadores
    const dataOperators = data?.operadores

    //foramteamos los datos obtenidos
    const userDataFormat = formatDataUsers(dataOperators)

    return (
        <div>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {error && (
                <h6>
                    {`Error! ${error.message}`}
                </h6>
            )}
            {data && (
                <Fragment>
                    {props?.filtros?.nombre || props?.filtros?.documento ?
                        <>
                            <TableSearch headers={state.headers} data={filterDataList(userDataFormat, state.filtros)} />
                        </>
                        :
                        <>
                            < Table headers={state.headers} data={takeDataSearch(filterDataList(userDataFormat, state.filtros), state.currentPage)} />
                        </>
                    }
                </Fragment>
            )}

        </div>
    )
}

export default TableUsers;