import React, { Fragment, useEffect, useState } from 'react'
import { filterDataList, filterDataListServicio, formatDataCategory, override, takeData, takeDataSearch } from "../../utils/helpers";
import Table from "../common/Table";
import { Pagination } from 'react-bulma-components';
import { useGetCategorias } from "../../utils/queries/useGetCategorias";
import { PuffLoader } from "react-spinners";
import TableSearch from '../common/TableSearch';


const CategoriaTable = (props) => {

    //declaramos e inicializamos las variables a ser utilizadas
    const [state, setState] = useState(
        {
            filtros: {
                nombre: '',
            },
            headers: {
            },
            loading: true,
            color: '#323980',
            currentPage: 1,
        }
    );

    //cosultamos los datos
    const { loading, data, error } = useGetCategorias();

    //incializamos las variables
    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))
    }, [props.filtros])

    if (error) return `Error! ${error.message}`;

    //definimos el header de la tabla
    const headers = {
        name: 'Nombre',
        icon: 'Icono',
        actions: 'Acciones',
    }

    return (
        <div>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {data && (
                <Fragment>
                    {
                        props?.filtros?.nombre ?
                            <>
                                <TableSearch headers={headers} data={filterDataList(formatDataCategory(data.categorias), state.filtros)} />
                            </> :
                            <>
                                <Table headers={headers} data={takeDataSearch(filterDataList(formatDataCategory(data.categorias), state.filtros), state.currentPage)} />
                            </>
                    }
                </Fragment>
            )}
        </div>
    );

}

export default CategoriaTable;