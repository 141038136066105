import React, { Fragment } from "react";
import ActionsFacturadorList from "../Facturadores/ActionsFacturadorList";
import { useLocation, useNavigate } from "react-router-dom";
import ActionsCategoriaList from "../Categorias/ActionsCategoriaList";
import ActionsServiciosList from "../Servicios/ActionsServiciosList";
import ActionsUsersList from "../Usuarios/ActionsUsersList";
import ActionsNotificationList from "../Notificaciones/ActionsNotificationList.js";


export default function TableRow(props) {
    const { data } = props

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const dataStatus = data => {
        if (data === null) {
            return "-"
        } else {
            return data
        }
    }
    
    const statusStle = data => {
        return (
            <>
                <span className=
                    {   data === 'BASICO' ? 'box-reg' :
                        data === 'PENDIENTE' ? 'box-pending' :
                        data === 'MEDIO' ? 'box-medio' :
                        data === 'AVANZADO' ? 'box-avanzado' :
                        data === 'RECHAZO_PERM' ? 'box-bloq-permanent' :
                        data === 'RECHAZO_TEMP' ? 'box-bloq-temp' :
                        data === 'ACTIVA' ||  data === 'ACTIVO' ? 'box-reg' :
                        data === 'INACTIVA' ||  data === 'INACTIVO'? 'box-inactiva' :
                        data === 'BLOQUEO_LOGIN' ? 'bloq-login' :
                        data === 'BLOQUEO_FACTURA' ? 'bloq_factura' :
                        data === 'BLOQUEO_BACKOFFICE' ? 'bloq-bo' :
                        data === 'SI' ? 'box-reg' :
                        data === 'NO' ? 'box-pending' :
                        data === 'bloqueado' ? 'bloq-bo' :
                        data === 'BLOQUEADA' ? 'bloq-bo' :
                        data === 'BLOQUEADPO' ? 'bloq-bo' : 
                        data === 'ALTA' ? 'bloq-bo' : 
                        data === 'BAJA' ? 'box-reg' :
                        data === 'MEDIA' ? 'box-medio' :
                        data === 'LISTO' ? 'box-reg' : ''
                    }>{dataStatus(data)}
                </span>
            </>
        )
    }

    return (
        <tr key={data.id} onClick={e => {
            e.preventDefault()
            if (props.link) {

                navigate(`${props.link}${data.id}`)
            }

        }}>
            {Object.keys(data).filter(col => col !== 'id' && col != 'register' && col !== 'id' && col != 'modificacion' && col !='idRegla' && col != 'idServicioRed' && col != 'fechaInsercion').map(col => {
                return (
                    <Fragment key={col}>
                        {col === 'actions'
                            ? (
                                <td >

                                    {pathname === '/facturadores' && (
                                        <ActionsFacturadorList dataID={data.id} />
                                    )}
                                    {pathname === '/categorias' && (
                                        <ActionsCategoriaList dataID={data.id} />
                                    )}
                                    {pathname === '/servicios' && (
                                        <ActionsServiciosList dataID={data.id} />
                                    )}
                                    {pathname === '/usuarios' && (
                                        <ActionsUsersList dataID={data.id} />
                                    )}
                                    {pathname === '/notificaciones' && (
                                        <ActionsNotificationList dataID={data.id} estado = {data.estado} idRegla = {data.idRegla}/>
                                    )}
                                </td>
                            )
                            /**
                            * todo: Considerar poner aquí logica para formatear datos.
                             * Como por ejemplo. Números, moneda, fechas y estados.
                            * */
                            : (
                                <Fragment>
                                    <td>{statusStle(data[col])}</td>
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            })}
        </tr>
    )
}