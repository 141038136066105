import TableHeader from "../Vendedores/TableHeader";
import React, { useEffect, useState, Fragment } from "react";
import TableRow from "./TableRow";
import { dinamicTake, sortData, takeData, formatDataVendedores, override } from "../../utils/helpers";
import { Pagination } from "react-bulma-components";
import { saveAs } from 'file-saver';  // Import the file-saver library
import ExcelJS from 'exceljs';  // Import exceljs
import { PuffLoader } from "react-spinners";
import { useQuery } from "@apollo/client";
import { CANT_VENDEDORES, VENDEDORES } from "../../utils/queries/Queries";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ProtectedComponent from "./ProtectedComponent";

export default function TableVendedores(props) {
    const [state, setState] = useState({ sort: {}, currentPage: 1, step: 1 })
    const [cantidadVendedores, setCantidadVendedores] = useState(0);
    const { data, headers, filtros, filters} = props;
    const convertDate = (date) => {
        if (date) {
            const dateConvert = new Date(date);
            const year = dateConvert.getFullYear();
            const month = dateConvert.getMonth() + 1;
            const day = dateConvert.getDate();
            const upMonth = month < 10 ? '0' + month : month;
            const upDay = day < 10 ? '0' + day : day;
            const dateFormated = `${year}-${upMonth}-${upDay}`;
            return dateFormated;
        } else if (date === '') {
            return null;
        }
    }
    const [order, setOrder] = useState(1);
    let modo = 'asc'
    const vendorsPagination = props?.link === '/vendedor/' ? true : false
    const allowPagination = props?.allowPagination === true ? true : false
    const currentStep = props?.currentStep ? props?.currentStep : 1

    //ordenamos la lista que obtenemos
    const listData = vendorsPagination === false ?
        takeData(sortData(Object.keys(data).reverse().map(item => data[item]), state.sort), state.currentPage) :
        dinamicTake(sortData(Object.keys(data).reverse().map(item => data[item]), state.sort), state.currentPage, props.take)

    //funcion para pordenar desc asc y setear datos consultados
    const onHandleChangeData = (data) => {

        if (data === 'registerFormat') {
            data = 'register'
        }
        if (data === 'modificacionFormat') {
            data = 'modificacion'
        }
        setOrder(order + 1)
        if (order % 2 === 0) {
            modo = 'desc'
        } else {
            modo = 'asc'
        }

        let resetData = state.sort

        Object.keys(resetData).forEach(item => {

            resetData[item] = modo
        })
        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...resetData,
                    [data]: toogleSortData(data)
                }
            }
        })
    }
    //seteamos tipo de sort al dar click en ella
    const toogleSortData = data => {

        if (state.sort[data] === 'asc') {
            return 'desc'
        } else {
            return 'asc'
        }
    }

    //funcion para setear el nro de pagina actual y cambiar en la misma
    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    useEffect(() => {
        let filtros = Object.keys(headers)

        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...filtros.reduce(function (target, key, index) {
                        target[key] = 'asc';
                        return target;
                    }, {})
                }
            }
        })
    }, [headers])

    const { data: vendedores, loading: loadingVendedores, error: errorVendedores, refetch: refetchVendedores } = useQuery(VENDEDORES,
        {
            variables:
            {
                filtros: {
                    estadoCuenta: filtros?.filtros?.status === '' || filtros?.filtros?.status === 'TODAS' ? null : filtros?.filtros?.status,
                    estadoKyc: filtros?.filtros?.kyc === '' || filtros?.filtros?.kyc === 'TODAS' ? null : filtros?.filtros?.kyc,
                    fechaFinRegistro: filtros?.filtros?.hasta === '' ? null : convertDate(filtros?.filtros?.hasta) === '' ? null : convertDate(filtros?.filtros?.hasta),
                    fechaInicioRegistro: filtros?.filtros?.desde === '' ? null : convertDate(filtros?.filtros?.desde) === '' ? null : convertDate(filtros?.filtros?.desde),
                    textoLibre: null
                },
                paginacion: {
                    order: filtros?.paginacion?.order,
                    orderBy: filtros?.paginacion?.orderBy,
                    step: 1,
                    take: cantidadVendedores
                }
            }
        }
    )

    useEffect(() => {
        async function fetchData(){
            if (vendedores && cantidadVendedores > 0) {
                const keys = Object.keys(vendedores.vendedores);
                if (keys.length > 0) {
                    const formatedData = formatDataVendedores(vendedores);
                    console.log(filtros?.filtros?.documento)
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Listado');
                    const updatedHeaders = {
                        id: 'id',
                        ...headers,
                    };
    
                    // Add headers
                    Object.keys(updatedHeaders).forEach((item, index) => {
                        worksheet.getCell(1, index + 1).value = item;
                    });
                    Object.keys(formatedData).forEach((item, rowIndex) => {
                        Object.keys(updatedHeaders).forEach((headerKey, colIndex) => {
                            const dataValue = formatedData[item][headerKey]; // Get data value corresponding to the header
                            worksheet.getCell(rowIndex + 2, colIndex + 1).value = dataValue;
                        });
                    });
                    // Save the workbook
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'Lista de vendedores.xlsx');
                    console.log("Exportado correctamente.");
                }
            }
        }
        fetchData();
    }, [vendedores]);
      
    const { 
        data: cantidad, 
        loading: loadingCantidad, 
        error: errorCantidad, 
        refetch: refetchCantidad
    } = useQuery(CANT_VENDEDORES, {})
        
    /**
     * Function to export data to Excel
     * @author David Velozo
     */
    const exportToExcel = async () => {
        await refetchCantidad();
        setCantidadVendedores(cantidad.cantidadVendedores);
        await refetchVendedores();
        setCantidadVendedores(0);
    };

    /**
    * @param {*} allow Allow is there are available data to show
    * @param {*} currentPage Current page to show
    * @returns return the pagination steps
    * @Author Adán Vera
    */
        const renderDinamicSteps = (allow, currentPage, filters) => {

            // initialy show 3 pages 
            // if the current page is 3 then show 4 pages and so on 
            let pages = 2
            let steps = []
    
            for (let i = 1; i <= pages; i++) {
                steps.push(i)
            }
    
            // show only 2 pages from the current page initialy
            // then according to the current page show more pages +1
            // if the current page is 2 then show 2 pages and so on
            if (allow && currentPage >= 2) {
                steps = []
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    steps.push(i)
                }
            }
            // if allow is false then show the current page and 
            //not add more page only show the last 2 pages from the current page 
            if (!allow) {
                steps = []
                for (let i = currentPage - 2; i <= currentPage; i++) {
                    steps.push(i)
                }
            }
    
            // add the prev and next values and pushed values to the steps array
            const prev = currentPage - 1
            const next = currentPage + 1
            steps.push('<')
            steps.push('>')
    
            // render the steps pagination
            return steps.map((item, index) => {
                if (item === '<') {
                    return (
                        <>{prev > 0 &&
                            <div className="liside"><li key={prev} onClick={() => handleChangeStep(prev)} className={props.step === item ? 'is-active' : ''}><a>{'<'}</a></li> </div>
                        }{prev === 0 &&
                            <div className="liside"><li key={prev} className={props.step === item ? 'is-active' : 'notallowed'}><a>{'<'}</a></li></div>
                            }
                        </>
                    )
                }
                if (item === '>') {
                    return (
                        <>{allow &&
                            <div className="liside"><li key={next} onClick={() => handleChangeStep(next)} className={props.step === item ? 'is-active' : ''}><a>{'>'}</a></li></div>}
                            {!allow &&
                                <div className="liside"><li key={next} className={props.step === item ? 'is-active ' : 'notallowed'}><a>{'>'}</a></li></div>
                            }
                        </>
                    )
                }
                if (item > 0) {
                    return (<div className="liside"><li key={index} onClick={() => handleChangeStep(item)} className={props.step === item ? 'is-active' : ''}><a>{item}</a></li></div>)
                }
            })
        }
    
        // onchange pagination
        const handleChangeStep = data => {
            props.handleChangeStep(data)
        }
    
        const lengthData = listData ? listData.length : ''
    
        const fechaDesde = props.validateDesde ? props.validateDesde : false  // true
        const fechaHasta = props.validateHasta ? props.validateHasta : false  // false 

    return (
        <Fragment>
            {
                props?.link === '/vendedor/' &&
                <>
                    <ProtectedComponent
                        allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO']}>
                        <div className="" id="itemExport">
                            <button
                                className="btnExport d-flex"
                                style={{
                                background: 'none',
                                border: 'none',
                                padding: 0,
                                cursor: 'pointer',
                                display: 'flex', // Add this to make the button a flex container
                                alignItems: 'center', 
                                marginLeft: '20px' // Center the content vertically
                                }}
                                onClick={exportToExcel}
                            >
                                <span
                                    onMouseEnter={() => {
                                        // Change the color of both the icon and text
                                        document.getElementById('icon-export').style.color = 'black';
                                        document.getElementById('text-export').style.color = 'black';
                                    }}
                                    onMouseLeave={() => {
                                        // Change the color of both the icon and text back to default
                                        document.getElementById('icon-export').style.color = '#195bab';
                                        document.getElementById('text-export').style.color = '#195bab';
                                    }}
                                    >
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <FontAwesomeIcon
                                            icon={faDownload}
                                            id="icon-export"
                                            style={{ fontSize: '15px', color: '#195bab', marginRight: '5px' }} // Removed marginLeft
                                        />
                                        <p
                                            id="text-export"
                                            style={{ fontSize: '16px', margin: 0, color: '#195bab' }}
                                        >
                                            Exportar
                                        </p>
                                    </div>
                                </span>
                            </button>
                        </div>
                    </ProtectedComponent>
                </>
            }
            <table className="table is-striped table-list has-text-left main-table">
                <TableHeader onChange={onHandleChangeData} headers={headers} />
                <tbody>
                    {
                        Object.keys(listData).map(itemkey => {
                            const item = listData[itemkey];
                            return (
                                <TableRow link={props?.link} key={item.id} data={item} showww={allowPagination} />
                            )
                        })
                    }
                </tbody>
            </table>
            {
                !vendorsPagination &&
                <div className='pt-5'>
                    <Pagination
                        current={state.currentPage}
                        next={'>'}
                        previous={'<'}
                        total={Object.keys(data).length / 10 % 1 == 0 ? Math.floor(Object.keys(data).length / 10) : Math.floor(Object.keys(data).length / 10) + 1}
                        onChange={handleCurrentPage}
                    />
                </div>
            }
            {
                vendorsPagination &&
                <>
                    {
                        (lengthData === 0) &&
                        <div className="columns nodata">
                            <div className=' column d-f align'>
                                <p className='has-text-grey-light'>
                                    {
                                        ((fechaDesde === true && fechaHasta === false) || (fechaDesde === false && fechaHasta === true)) &&
                                        'Asegurarse de que las fechas desde y hasta esten seleccionadas'
                                    }
                                    {
                                        ((fechaDesde === false && fechaHasta === false) || (fechaDesde === true && fechaHasta === true)) &&
                                        'No hay datos para mostrar'
                                    }
                                </p>
                            </div>
                        </div>
                    }
                    <section id='navvendorsection'>
                        <div className="columns">
                            <div className="column is-12" id='pageselection'>
                                {renderDinamicSteps(allowPagination, currentStep, filters)}
                            </div>
                        </div>
                    </section>
                </>
            }
        </Fragment>
    )
    
}