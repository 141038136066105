
const ProtectedComponent = ({allowedRoles, children}) =>{
    const payload = localStorage.getItem('payload');
    if(payload){
        const userRol = JSON.parse(payload).rol

        if (allowedRoles.includes(userRol)){
            return children
        }else{
            return null
        }
    }else{
        return null
    }



}

export default ProtectedComponent;