import React, { Fragment, useState } from 'react'
import TablaDetalle from "../../common/Tabla/TablaDetalle";
import { formatComisiones, override } from "../../../utils/helpers";
import { useQuery } from "@apollo/client";
import { GET_INFORME_DETALLADO_BY_ID, GET_VENDEDOR, GET_VENDEDOR_MOVIMIENTOS } from "../../../utils/queries/Queries";
import { BounceLoader, PuffLoader } from "react-spinners";
import ProtectedComponent from '../../common/ProtectedComponent';
import VendedorDatos from '../VendedorDatos';
import VendedorDatosCriterion from '../VendedorDatosCriterion';
import { Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const DatosVendedorBasico = (props) => {

    //fecha auxiliar para fecha de inicio
    const fechaAux = new Date()
    //fecha auxiliar para fecha fin
    const dateData = new Date();
    //dateData.setDate(dateData.getDate());
    dateData.setDate(dateData.getDate() + 1);

    //declaramao la variables a utilizar
    const initialState = {
        variables: {
            idVendedor: props.vendorID,
            actividad: 'COMIS',
            fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            fechaFin: dateData.toISOString().slice(0, 10)
        },
        color: '#323980',
    }

    //inicializamos las variables a utilizar
    const [state, setState] = useState(initialState)

    //declaramos la cabecera de nuestra tabla
    const cabecera = {
        fecha: {
            id: 1,
            label: 'FECHA',
            order: 'desc',
            status: false
        },
        concepto: {
            id: 2,
            label: 'CONCEPTO',
            order: 'desc',
            status: false
        },
        estado: {
            id: 3,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        comisionAcreditada: {
            id: 4,
            label: 'ESTADO DE PAGO',
            order: 'desc',
            status: false
        }
        ,
        monto: {
            id: 5,
            label: 'MONTO',
            order: 'desc',
            status: false
        },
    }

    // //consultamos los datos correspondientes a comisiones del vendedor
    // const { data, loading, error } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
    //     variables: {
    //         idVendedor: +props.vendorID,
    //         fechaInicio: state?.variables?.fechaInicio,
    //         fechaFin: state?.variables?.fechaFin,
    //         actividad: state?.variables?.actividad
    //     }
    // });

    //obtenemos los vendedores
    const { data, loading, error } = useQuery(GET_INFORME_DETALLADO_BY_ID, {
        variables: {
            idVendedor: +props.vendorID,
        }
    })
    //se crea un array para guardar el listado de comisiones
    // const aComisiones = data?.movimientos ? data?.movimientos : []
    //    const nombre = data?.vendedor ? data?.vendedor: 'jj' 
    //limitamos el listado de comisiones a 5 registros
    // var showShortList = aComisiones.slice(0, 5);
    const { sidebarStatus } = props;
    const vendorDataName = data?.obtenerInformeDetallado.informe.data.Basicos?.nombres + " " + data?.obtenerInformeDetallado.informe.data.Basicos?.apellidos
    const urlInforme = data?.obtenerInformeDetallado.urlInforme
    const nroDocumento = data?.obtenerInformeDetallado.informe.data.Basicos?.CI ? data?.obtenerInformeDetallado.informe.data.Basicos?.CI : ""


    return (
        <Fragment>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <p className='mt-4'>Cargando datos...</p>
                    </div>
                </div>
            )}

            {data && (
                <section>
                    <div className="columns">
                        <div className="column d-f jf-cont">
                            <h1 className="title-details ml-5 pt-3">{vendorDataName}</h1>
                        </div>


                        <div className='is-inline-flex'>
                            <a href={urlInforme} className="btnSort" download >
                                <span className="btnExport d-flex dwninf"> <ion-icon name="download-outline"></ion-icon> Descargar informe</span>
                            </a>
                        </div>

                    </div>


                    <div className="pt-0 columns pd-un has-text-left">
                        <div className="column is-3">
                            <label htmlFor="">Documento:</label><p>{nroDocumento}</p>
                        </div>

                    </div>

                    <div className="columns">
                        <div className="column py-0">
                            <hr className='my-0' />
                        </div>
                    </div>


                    <div className="pt-0 columns pd-un has-text-left">
                        {loading
                            ? (<BounceLoader color={state.color} loading={state.loading} css={override} size={50} />)
                            : <VendedorDatosCriterion vendedor={data?.obtenerInformeDetallado.informe.data.Basicos} />
                        }
                    </div>
                </section>
            )}

        </Fragment>

    )
}

export default DatosVendedorBasico;