import { useState } from "react";
import DatePicker from "react-date-picker";
import Select from "../common/Select";


const FiltroNotifications = props => {

    const initialState = {
        desde: '',
        hasta: '',
        titulo: '',
        idPrioridad: '',
        estado: '',
    }


    const [state, setState] = useState(initialState);


    const setSelectedValue = (data) => {
        // Si hay cambios en los selects se guarda en el state del componente
        // Y se envia al componte padre para aplicar luego el filtro a la tabla
        props.getFilter(data)

        setState(prevState => (
            {
                ...prevState,
                [data.key]: data.value,
            }
        ))
    }

    //funcion para limpiar los valores de los filtros
    const handleCleanFilter = () => {
        setState(initialState)
        props.onCleanFilter(initialState)
    }

    //funcion correspondiente para acciones de select de estado de notificación
    const estadoNotificaction = {
        slice: 'estado',
        label: 'Estado de notificación',
        default: '',
        disabled: 'Seleccionar estado',
        options: {
            TODAS: 'Todas',
            PENDIENTE: 'Pendiente',
            LISTO: 'Listo',
            BAJA: 'Baja'
        }
    }

    //funcion correspondiente para acciones de select de prioridad de notificación
    const prioridadNotification = {
        slice: 'idPrioridad',
        label: 'Prioridad',
        default: '',
        disabled: 'Seleccionar prioridad de notificación',
        options: {
            TODAS: 'Todas',
            ALTA: 'Alta',
            MEDIA: 'Media',
            BAJA: 'Baja'
        }
    }

    //onchange del filtro de fecha desde
    const handleDateChange = (data) => {

        const Fecha = new Date(data)
        props.getFilter({
            key: 'desde',
            value: Fecha
        })
        setState(prevState => (
            {
                ...prevState,
                desde: new Date(Fecha),
            }
        ))
    }


    //onchange del filtro de fecha hasta
    const handleDateHastaChange = (data) => {

        const Fecha = new Date(data)
        props.getFilter({
            key: 'hasta',
            value: Fecha
        })
        setState(prevState => (
            {
                ...prevState,
                hasta: new Date(Fecha),
            }
        ))
    }

    return (
        <div className="columns">
            <div className="column">
                <div className="item-column">
                    <label className="mb-1" htmlFor="">Fecha de registro desde:</label>
                    <DatePicker
                        clearIcon={null}
                        onChange={handleDateChange}
                        value={state.desde}
                        className='date-input' />
                </div>
            </div>
            <div className="column">
                <div className=" item-column">
                    <label className="mb-1" htmlFor="">Fecha de registro hasta:</label>
                    <DatePicker
                        clearIcon={null}
                        onChange={handleDateHastaChange}
                        value={state.hasta}
                        className='date-input' />
                </div>
            </div>
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.idPrioridad}
                        optionList={prioridadNotification}
                        setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.estado}
                        optionList={estadoNotificaction}
                        setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column align">
                <div className="item-column ">
                    <button className="button btn-other mt-5" onClick={() => handleCleanFilter()}>Limpiar</button>
                </div>
            </div>
        </div>
    )
}


export default FiltroNotifications;