import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { EDIT_CONTACTO_VENDEDOR } from "../../../utils/mutators/Mutators";
import { BounceLoader } from "react-spinners";
import { override } from "../../../utils/helpers";
import { handleToggleModal } from "../../../actions/modal";
import { ReactComponent as CheckIcon } from "../../../assets/images/check.svg";
import { GET_VENDEDOR } from "../../../utils/queries/Queries";


const FormDatosContactos = props => {

    const { contactos, authedUser, idVendedor } = props
    const [errorExist, setErrorExist] = useState(false)


    // console.log('Persona: ', contactos.find(item=> item.tipo === 'CORREO').valor.direccion)

    const initialState = {
        selectOption: {
            correo: 'Correo Electrónico',
            telefono: 'Teléfono',
        },
        modificarPerfil: {
            idVendedor: idVendedor ? idVendedor : '',
            idOperador: authedUser.idOperador ? authedUser?.idOperador : '',
            telefono: props.contactos ? contactos.find(item => item?.tipo === 'TELEFONO')?.valor?.numero : '',
            correo: props.contactos ? contactos.find(item => item?.tipo === 'CORREO')?.valor?.direccion : '',
        },
        selectedOption: 'telefono',
        loading: true,
        color: '#323980',

    }

    const [state, setState] = useState(initialState);

    const [ModificarPerfil, { data, loading, error, reset }] = useMutation(EDIT_CONTACTO_VENDEDOR, {
        refetchQueries: [
            GET_VENDEDOR, // DocumentNode object parsed with gql
            'Get Vendedor' // Query name
        ],
    })

    const handleSubmit = () => {
        ModificarPerfil({
            variables: {
                modificarPerfil: {
                    idVendedor: state.modificarPerfil?.idVendedor,
                    idOperador: state.modificarPerfil?.idOperador,
                    ...(state?.selectedOption === 'telefono' && { telefono: state?.modificarPerfil?.telefono }),
                    ...(state?.selectedOption === 'correo' && { correo: state?.modificarPerfil?.correo }),
                }
            }
        }).then(r => {
            console.log('Rpta Edicion contacto', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edicion contacto -> ' + reason);
                setErrorExist(true)

            });
    }

    const handleChange = e => {
        setState(prev => {
            return {
                ...prev,
                modificarPerfil: {
                    ...prev.modificarPerfil,
                    [e.target.name]: e.target.value,
                }
            }
        })
    }

    const handleSelect = e => {
        setState(prev => {
            return {
                ...prev,
                selectedOption: e.target.value,
            }
        })
    }

    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
        reset()
    }

    return (
        <Fragment>
            {(error && errorExist) && (
              <div className="notification is-danger">
              <button className="delete" onClick={ () => setErrorExist(false)}> </button><strong>{error.message}</strong>
            </div>
            )}
            {!loading && (
                !data && (
                    <form>
                        <div className='is-fullwidth'>
                            <label htmlFor="" className='mb-2'>Seleccionar forma de contacto:</label>
                            <div className="select is-fullwidth">
                                <select
                                    name='estado'
                                    value={state.selectedOption}
                                    onChange={e => handleSelect(e)}
                                >
                                    <option value=''>Seleccione una forma de contacto</option>
                                    {Object.keys(state.selectOption).map(item =>
                                        (<option key={item} value={item}>{state.selectOption[item]}</option>)
                                    )}


                                </select>
                            </div>
                        </div>
                        {state.selectedOption === 'telefono' && (
                            <div className='mt-5'>
                                <label htmlFor="">Agregar Telefono:</label>
                                <div>
                                    <input
                                        name='telefono'
                                        onChange={e => handleChange(e)}
                                        className="input pl-2"
                                        placeholder="Agregar motivo del cambio..."
                                        value={state?.modificarPerfil?.telefono} />
                                </div>
                            </div>
                        )}
                        {state.selectedOption === 'correo' && (
                            <div className='mt-5'>
                                <label htmlFor="">Agregar Correo:</label>
                                <div>
                                    <input
                                        name='correo'
                                        onChange={e => handleChange(e)}
                                        className="input pl-2"
                                        placeholder="Agregar motivo del cambio..."
                                        value={state.modificarPerfil?.correo} />
                                </div>
                            </div>
                        )}


                    </form>
                )
            )}
            {loading && (
                <div className='my-5 py-5'>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                </div>
            )}
            {!data
                ? (
                    <footer className="modal-card-foot pb-0">

                        <button

                            onClick={() => handleSubmit()}
                            className={loading ? 'button is-normal btn-enviar is-loading' : 'button is-normal btn-enviar'}>Guardar</button>
                        <button
                            onClick={() => handleCloseModal()}
                            className="button btn-cancelar" data-bulma-modal="close">Cancelar
                        </button>
                    </footer>
                )
                : (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>Los datos del vendedor fueron modificados con éxito</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    handleCloseModal()
                                }}
                                className='button is-normal btn-enviar'>
                                Aceptar
                            </button>
                        </footer>
                    </div>

                )
            }
        </Fragment>
    )
}

function mapStateToProps({ authedUser, modal }) {
    console.log('map', authedUser)
    return {
        authedUser,
        modal
    }
}

export default connect(mapStateToProps)(FormDatosContactos)