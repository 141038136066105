import { gql, useApolloClient, useMutation } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { BounceLoader } from 'react-spinners';
import { handleToggleModal } from '../../actions/modal';
import { override } from '../../utils/helpers';
import { DISABLE_OPERADOR, EDITAR_OPERADOR, INSERTAR_OPERADOR } from '../../utils/mutators/Mutators';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_OPERATORS } from '../../utils/queries/Queries';
import toast from 'react-hot-toast';

const FormInsertarUsuario = (props) => {

    //declaramos las varibales a ser utlizadas
    const initialState = {
        variables: {
            modalType: '',
        },
        variables: {
            nombres: '',
            apellidos: '',
            nroDocumento: '',
            correo: '',
            rol: '',
            habilitado: ''
        },
        data: false,
    }

    // Variables: Necesarios para la insercción de la mutación de Servicios
    const [state, setState] = useState(initialState);

    // Mutation for inserting a operador
    const [insertarOperador, { data: dataInsert, loading: loadingInsert, error: errorInsert, reset: resetInsert }] = useMutation(INSERTAR_OPERADOR, {
        refetchQueries: [
            GET_OPERATORS, // DocumentNode object parsed with gql
            'Operadores' // Query name
        ],
    });

    // Mutator for Editing a operator
    const [EditarOperador, { data: dataEdit, loading: loadingEdit, error: errorEdit }] = useMutation(EDITAR_OPERADOR, {
        refetchQueries: [
            GET_OPERATORS, // DocumentNode object parsed with gql
            'Operadores' // Query name
        ],
    });

    // Mutator for delete a operator
    const [InhabilitarOperador, { data: dataDelete, loading: loadingDelete, error: errorDelete, reset: resetDelete }] = useMutation(DISABLE_OPERADOR, {
        refetchQueries: [
            GET_OPERATORS, // DocumentNode object parsed with gql
            'Operadores' // Query name
        ],
    });


    let data = !!(dataEdit || dataInsert || dataDelete);
    const loading = !!(loadingInsert || loadingEdit || loadingDelete)

    //llama al metodo correspondiente dependiendo del tipo de modal que se utiliza
    const handleSubmit = (e) => {
        e.preventDefault()

        if (props.modal.type === 'edit') {
            handleEdit()
        }
        if ((props.modal.type === 'delete')) {
            handleBlock()
        }
        if (props.modal.type !== 'edit') {
            handleInsert()
        }
    }

    //accion correspondiente para insertar un usuario y hacemos el llamado de la funcion de inserción
    const handleInsert = () => {
        // se carga el obj options con los datos obtenidos del form
        insertarOperador({
            variables: {
                input: {
                    nombres: state.variables.nombres,
                    apellidos: state.variables.apellidos,
                    nroDocumento: state.variables.nroDocumento,
                    correo: state.variables.correo,
                    rol: state.variables.rol,
                }
            },
        }).then(r => {
            console.log('capturando respuesta de insercion de usuario', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error insercion-> ' + reason);
            });
    }

    //onchange de los inputs
    const handleChange = (e) => {

        setState(prevState => {
            const updatedValues = {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    [e.target.name]: e.target.value,
                }
            }
            return { ...updatedValues };
        });
    }

    //reinicia los valores de todas las variables al dar aceptar 
    const handleAceptarBtn = (e) => {
        e.preventDefault()
        setState(() => {
            return { ...initialState }
        })
        props.dispatch(handleToggleModal())
        resetInsert()
        // resetDelete()
    }

    //seteamos y llamamos al modulo de edución o bloqueo
    //delete === bloquear usuario se llama ya que solo reutilize la funcion
    useEffect(() => {
        if (props.modal.type === 'edit')
            handleEditMode()
        else if (props.modal.type === 'delete') {
            setState(prevState => {
                return {
                    ...prevState,
                    modalType: 'delete'

                }
            })
            handleBlockMode()
        }
    }, [])

    const client = useApolloClient();

    //funcion para preacgar los valores del usuario en el modal en el caso de que se quiera editar un operador
    const handleEditMode = () => {

        const operadorData = client.readFragment({
            id: `Operador:${props.modal.data}`, // The value of the to-do item's cache ID
            fragment: gql`
                fragment operador on Operador {
                    id
                    rol
                    nombres
                    habilitado
                    fechaInsercion
                    documento
                    correo
                    apellidos
                    habilitado
                }
            `,
        });

        setState(prev => {
            return {
                ...prev,
                variables: {
                    id: operadorData.id,
                    nombres: operadorData.nombres,
                    apellidos: operadorData.apellidos,
                    nroDocumento: operadorData.documento,
                    correo: operadorData.correo,
                    rol: operadorData.rol,
                    habilitado: operadorData.habilitado
                }
            }
        })
    }

    const handleBlockMode = () => {

        const operadorData = client.readFragment({
            id: `Operador:${props.modal.data}`, // The value of the to-do item's cache ID
            fragment: gql`
                fragment operador on Operador {
                    habilitado
                }
            `,
        });

        setState(prev => {
            return {
                ...prev,
                variables: {
                    habilitado: operadorData.habilitado
                }
            }
        })
    }

    //funcion para enviar los nuevos valores del operador a modificar
    const handleEdit = () => {

        EditarOperador({
            variables: {
                input: {
                    id: props.modal.data,
                    nombres: state.variables.nombres,
                    apellidos: state.variables.apellidos,
                    nroDocumento: state.variables.nroDocumento,
                    correo: state.variables.correo,
                    rol: state.variables.rol,
                    habilitado: JSON.parse(state.variables.habilitado)
                }
            }
        }).then(r => {
            console.log('capturando respuesta de edicion de operador', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edit-> ' + reason);
            }
        );
    }

    //toast correspondientes a las acciones de bloqueo con los mensajes correspondiente dependiendo del caso
    const errorDel = (reason) => toast.error('Ocurrio un error al bloquear al usuario: ' + JSON.stringify(reason.message));
    const successDel = (r) => state?.variables?.habilitado === true ? toast.success('Usuario bloqueado exitosamente.') : toast.success('Usuario desbloqueado exitosamente.')

    //funcion para bloquear al usuario
    // delete === bloquear usuario para tener en cuenta
    const handleBlock = (e) => {
        props.dispatch(handleToggleModal())
        InhabilitarOperador({
            variables: {
                input: {
                    idOperador: props.modal.data,
                    habilitado: state?.variables?.habilitado === true ? false : true
                }
            }
        }).then(r => {
            console.log('capturando respuesta de inhabilitar usuario', r)
            resetDelete()
            successDel(r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                errorDel(reason)
                console.error('Error al inhabilitar usuario -> ' + reason);
            });
    }

    //conidicionamos el renderizado de las modales correspondientes y el contenido de la misma
    if (state.modalType !== 'delete') {
        return (
            <Fragment>
                {(errorInsert || errorEdit) && (
                    <div className="notification is-danger">
                        <button className="delete"></button>
                        <strong>{errorInsert ? errorInsert.message : errorEdit.message}</strong>
                    </div>
                )}
                {(loadingInsert || loadingEdit) && (
                    <div>
                        <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                    </div>
                )}
                {(!loadingInsert || !loadingEdit) && (
                    ((!data) && (
                        <form action="" className={props.modal.modalStatus ? 'open' : 'closed'} onSubmit={
                            e => {
                                handleSubmit(e)
                            }}>
                            <div className="column">
                                <div className="field">
                                    <label className="label">Nombre:</label>
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            name="nombres"
                                            className="input pl-2"
                                            type="text"
                                            onChange={(e) => handleChange(e)}
                                            value={state.variables.nombres}
                                            placeholder="Ingresar nombre..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">Apellido:</label>
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            name="apellidos"
                                            className="input pl-2"
                                            type="text"
                                            onChange={(e) => handleChange(e)}
                                            value={state.variables.apellidos}
                                            placeholder="Ingresar apellido..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">Cedula:</label>
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            name="nroDocumento"
                                            className="input pl-2"
                                            type="text"
                                            onChange={(e) => handleChange(e)}
                                            value={state.variables.nroDocumento}
                                            placeholder="Ingresar cedula..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">Correo:</label>
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            name="correo"
                                            className="input pl-2"
                                            type="mail"
                                            onChange={(e) => handleChange(e)}
                                            value={state.variables.correo}
                                            placeholder="Ingresar correo..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">Rol:</label>
                                    <div className='select is-fullwidth'>
                                        <select name="rol" value={state.variables.rol} onChange={(e) => handleChange(e)}  >
                                            <option value="" disabled >Seleccionar rol</option>
                                            <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                                            <option value="OPERADOR">OPERADOR</option>
                                            <option value="SEGURIDAD">SEGURIDAD</option>
                                            <option value="MARKETING">MARKETING</option>
                                            <option value="CUMPLIMIENTO">CUMPLIMIENTO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {props.modal.type === "edit" ?
                                (
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Estado usuario:</label>
                                            <div className='select is-fullwidth'>
                                                <select name="habilitado" value={state.variables.habilitado} onChange={(e) => handleChange(e)}>
                                                    <option value="" disabled >Seleccionar rol</option>
                                                    <option value={true}>Habilitado</option>
                                                    <option value={false}>Bloqueado</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                ) : (<></>)
                            }
                            < footer className="modal-card-foot">
                                <button className="button is-normal btn-enviar">
                                    Guardar
                                </button>
                                <button
                                    onClick={(e) => handleAceptarBtn(e)}
                                    className="button btn-cancelar"
                                    data-bulma-modal="close"
                                >
                                    Cancelar
                                </button>
                            </footer>
                        </form>
                    )))
                }
                {
                    data && (
                        <div className="has-text-centered">
                            <div>
                                <CheckIcon />
                            </div>
                            {props.modal.type === "edit" ? (<h6>Operador modificado exitosamente</h6>) : (<h6>Operador creado exitosamente</h6>)}
                            <button
                                onClick={(e) => handleAceptarBtn(e)}
                                className="mt-5 button is-normal btn-enviar"
                                data-bulma-modal="close"
                            >
                                Aceptar
                            </button>
                        </div>
                    )
                }

            </Fragment >
        )
    } else return (
        <Fragment>
            {errorDelete ? (
                <div className="notification is-danger">
                    <button className="delete"> </button>
                    {/* {" "} */}
                    <strong>{errorDelete.message}</strong>
                </div>
            ) : (
                ""
            )}
            {loading && (
                <div className="my-5 py-5">
                    <BounceLoader
                        color={state.color}
                        loading={state.loading}
                        css={override}
                        size={50}
                    />
                </div>
            )}
            {!loading && !data && (
                <form
                    action=""
                    onSubmit={(e) => {
                        handleSubmit(e)
                    }}
                >
                    <div className="a-center modal-delete">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        {
                            state?.variables?.habilitado === true ?
                                <p className='mt-3'>¿Estas seguro de  bloquear este usuario?</p> :
                                <p className='mt-3'>¿Estas seguro de  desbloquear este usuario?</p>
                        }
                    </div>
                    <footer className="modal-card-foot">
                        <button className="button is-normal btn-eliminar">
                            Aceptar
                        </button>
                        <button
                            onClick={(e) => handleAceptarBtn(e)}
                            className="button btn-cancelar"
                            data-bulma-modal="close"
                        >
                            Cancelar
                        </button>
                    </footer>
                </form>
            )}
            {data && (
                <div className="has-text-centered">
                    <div>
                        <CheckIcon />
                    </div>
                    <h6>Se bloqeuo correctamente</h6>
                    <button
                        onClick={(e) => handleAceptarBtn(e)}
                        className="mt-5 button is-normal btn-enviar"
                        data-bulma-modal="close"
                    >
                        Aceptar
                    </button>
                </div>
            )}
        </Fragment>
    )
}

function mapStateToProps({ authedUser, modal }) {
    return {
        authedUser,
        modal
    }
}

export default connect(mapStateToProps)(FormInsertarUsuario);
