import { useMutation } from "@apollo/client"
import { useQuery } from "@apollo/client"
import React, { Fragment, useEffect, useState } from "react"
import { handleToggleModal } from "../../../actions/modal"
import { connect } from "react-redux"
import { GET_VENDEDOR } from "../../../utils/queries/Queries"
import { ACTUALIZAR_IMAGEN, CARGAR_CONSTANCIA_RUC } from "../../../utils/mutators/Mutators"
import { BounceLoader } from "react-spinners"
import { override } from "../../../utils/helpers"
import moment from "moment"
import { ReactComponent as CheckIcon } from "../../../assets/images/check.svg"

const FormUpdate = (props) => {



    const { data: dataVendedor} = useQuery(GET_VENDEDOR, {
        variables: {
            idVendedor: props.idVendedor,
        }
    })

    const idOperador = props?.idOperador.toString()
    //existeConstancia sirve para controlar si ya tiene una constancia cargada o será la primera vez que lo cargue
    const existeConstancia = dataVendedor?.vendedor?.imagenes.find(imagen => imagen?.tipoImagen === 'CONSTANCIA')?.urlImagen

    //declaramos las variables a utilizar
    const initialState = {
        fileName: '',
        selectOption: {
            FRENTE: "FRENTE",
            DORSO: "DORSO",
            SELFIE: "SELFIE",
            CONSTANCIA: 'CONSTANCIA'
        },
        variables: {
            actualizarImagenVendedor: {
                idOperador: props?.idOperador,
                idVendedor: props.idVendedor ? props.idVendedor : "",
                tipoArchivo: "CEDULA",
                actualizarImagen: {
                    imagenInput: {
                        tipoImagen: "",
                        imagen: "",
                    },
                },
            },
        },
        selectedOption: "FRENTE",
    }

    const [state, setState] = useState(initialState)

    //mutator correspondiente a actulizar documento
    const [ActualizarImagenVendedor, { 
            data: dataactualizarImagen,
            loading: loadingactualizarImagen,
            error: erroractualizarImagen,
        }] =
        useMutation(ACTUALIZAR_IMAGEN, {
            refetchQueries: [
                GET_VENDEDOR, // DocumentNode object parsed with gql
                "Get Vendedor", // Query name
            ],
        })


    //mutator correspondiente a cargar constancia de ruc
    const [CargarConstanciaRuc, { 
            data: dataCargarConstancia,
            loading: loadingCargarConstancia,
            error: errorCargarConstancia,
    }] =
        useMutation(CARGAR_CONSTANCIA_RUC, {
            refetchQueries: [
                GET_VENDEDOR, // DocumentNode object parsed with gql
                "Get Vendedor", // Query name
            ],
        })

    const data = !!(dataactualizarImagen || dataCargarConstancia)
    const loading = !!(loadingactualizarImagen || loadingCargarConstancia)

    

    const handleSubmit = async() => {
        if(state.selectedOption === 'CONSTANCIA' && existeConstancia === undefined){
            CargarConstanciaRuc({
                variables: {
                    idVendedor: state.variables.actualizarImagenVendedor.idVendedor,
                    imagenConstancia: state.variables.actualizarImagenVendedor.actualizarImagen.imagenInput.imagen,
                    idOperador: idOperador,
                },
            }).then((r) => {
                console.log("Respuesta de actualizacion del documento: "+ r)
            }).catch(function (reason) {
                console.error("Error al actualizar la constancia de RUC: " + reason)
            })
        }else{
            ActualizarImagenVendedor({
                variables: {
                    actualizarImagen: state.variables.actualizarImagenVendedor.actualizarImagen,
                    idOperador: state.variables.actualizarImagenVendedor.idOperador,
                    idVendedor: state.variables.actualizarImagenVendedor.idVendedor,
                    tipoArchivo: state.variables.actualizarImagenVendedor.tipoArchivo,
                },
            }).then((r) => {
                console.log("Respuesta de actualizacion del documento: "+ r)
            }).catch(function (reason) {
                console.error("Error al actualizar la imagen: " + reason)
            })
        }  
    }

    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
    }

    const handleSelect = (e) => {
        setState((prev) => {
            return {
                ...prev,
                selectedOption: e.target.value,
            }
        })
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const baseImg = await convertBase(file)
        setState({
            ...state,
            fileName: file,
            variables: {
                actualizarImagenVendedor: {
                    idVendedor: props.idVendedor,
                    tipoArchivo: "CEDULA",
                    idOperador: props.idOperador,
                    actualizarImagen: {
                        imagenInput: {
                            imagen: baseImg,
                            tipoImagen: state.selectedOption,
                        },
                    },
                },
            },
        })
    }

    //funcion para convertir el archivo en cuestion en string base64
    const convertBase = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    return (
        <>
            <Fragment>
                {(errorCargarConstancia || erroractualizarImagen) ? (
                    <div className="notification is-danger">
                        <button className="delete"></button>
                        <strong>{errorCargarConstancia ? errorCargarConstancia.message : erroractualizarImagen.message}</strong>
                    </div>
                ) : ''}
                
                {!loading && !data && (
                    <form>
                        <div className="is-fullwidth">
                            <label htmlFor="" className="mb-2">
                                Seleccionar tipo de documento:
                            </label>
                            <div className="select is-fullwidth mb-3">
                                <select
                                    name="imgType"
                                    value={state.tipoImagen}
                                    onChange={(e) => handleSelect(e)}
                                >
                                    <option value="" selected disabled>
                                        Seleccionar tipo
                                    </option>
                                    {Object.keys(state.selectOption).map(
                                        (item) => (
                                            <option key={item} value={item}>
                                                {state.selectOption[item]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <label htmlFor="" className="mb-3">
                                Cargar imagen/pdf del documento:
                            </label>
                            <div className="fileuploader">
                                <input
                                    type="file"
                                    label="Image"
                                    name="file"
                                    accept=".jpeg, .png, .jpg, .pdf"
                                    onChange={(e) => handleFileRead(e)}
                                    value={(e) => handleFileRead(e)}
                                />
                            </div>
                            <div className="fileselected">
                                {
                                    state?.fileName ?
                                        <>Archivo seleccionado: {state?.fileName?.name}</>
                                        :
                                        <>Archivo no seleccionado</>
                                }
                            </div>
                        </div>
                    </form>
                )}
                {loading && (
                    <div className="my-5 py-5">
                        <BounceLoader
                            color={state.color}
                            loading={state.loading}
                            css={override}
                            size={50}
                        />
                    </div>
                )}
                {!data ? (
                    <footer className="modal-card-foot pb-0">
                        <button
                            onClick={() => handleSubmit()}
                            className={
                                loading
                                    ? "button is-normal btn-enviar is-loading"
                                    : "button is-normal btn-enviar"
                            }
                        >
                            Guardar
                        </button>
                        <button
                            onClick={() => handleCloseModal()}
                            className="button btn-cancelar"
                            data-bulma-modal="close"
                        >
                            Cancelar
                        </button>
                    </footer>
                ) : (
                    <div className="has-text-centered">
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>Documento actualizado correctamente</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    handleCloseModal()
                                }}
                                className="button is-normal btn-enviar"
                            >
                                Aceptar
                            </button>
                        </footer>
                    </div>
                )}
            </Fragment>
        </>
    )
}
function mapStateToProps({ authedUser }) {
    return {
        ...authedUser
    }
}

export default connect(mapStateToProps)(FormUpdate)
