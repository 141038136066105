import { SET_AUTHED_USER } from '../actions/authedUser'

export default function authedUser (state = null, action) {
  switch (action.type) {
    case SET_AUTHED_USER :
      return {
        ...state,
        id: action.authedUser.id,
        token: action.authedUser.token,
        rol: action.authedUser.rol,
        nombre: action.authedUser.nombres,
        apellido: action.authedUser.apellidos,
        correo: action.authedUser.correo,
        idOperador: action.authedUser.idOperador,
        fechaActualizacion: action.authedUser.fechaActualizacion
      }
    default :
      return state
  }
}