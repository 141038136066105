import React from 'react'
import {
    Navigate,
    Outlet,
    //useLocation,
} from 'react-router-dom';
import {connect} from "react-redux";



const ProtectedRoute = (props, { user, redirectPath = 'login', children }) => {

    const cachedUser = localStorage.getItem('payload') ? JSON.parse(localStorage.getItem('payload') ): props.authedUser;
    // const {pathname} = useLocation();

    if(!localStorage.getItem('refresh')){

        return <Navigate to='login' replace />
    }

    if (!cachedUser) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

function mapStateToProps({authedUser}){
    return{
        authedUser
    }
}

export default connect(mapStateToProps)(ProtectedRoute);