import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SearchTable = props =>{

        const [busqueda, setBusqueda ] = useState('')

        const handleOnChange = data =>{
            setBusqueda(data)
            props.handleChange(data)
        }

        return(
            <div className="columns w-100" id="buscador">
                <div className="column d-f align">
                    <div className="control has-icons-left has-icons-right input-size d-g ">
                        <input value={busqueda}
                               onChange={e=>handleOnChange(e.target.value)}
                               placeholder={props.placeholder}
                               className="input is-normal"
                               type="text"
                               />
                        <span className="icon is-right">
                            <FontAwesomeIcon className='' icon={['fas', 'search']} />
                        </span>
                    </div>
                    <button className=" button btn-buscar ml-2">Buscar</button>
                </div>
            </div>
        )

}

export default SearchTable