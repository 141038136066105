import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { handleToggleModal } from "../../actions/modal";
import ModalContainer from "../common/ModalContainer";
import ProtectedComponent from "../common/ProtectedComponent";
import SearchTable from "../common/SearchTable";
import FiltroTableUsers from "./FiltroTableUsers";
import FormInsertarUsuario from "./FormInsertarUsuario";
import TableUsers from "./TableUsers";



const Usuarios = (props) => {

    const dashboardRef = React.createRef();
    const { sidebarStatus, modal } = props;
    const isNumeric = /^[0-9]+$/;
    //declaramos los valores inicales a ser utilizadas
    const initialState = {
        vendors: [],
        filtros: {
            nombre: '',
            documento: '',
            estado: '',
            rol: '',
        },
        loading: true,
        color: '#323980',
        currentPage: 1,
    }

    //inicializamos las variables
    const [state, setState] = useState(initialState);

    //onchange correspondiente para hacer la busqueda 
    const handleSearch = data => {
        const filtros = { ...state.filtros};
        if (data === '') {
            filtros.nombre = data;
            filtros.documento = data;
        } else if (isNumeric.test(data)) {
            filtros.documento = data;
            filtros.nombre = '';
        } else {
            filtros.nombre = data;
            filtros.documento = '';
        }
        setState(prev => ({ ...prev, filtros }));
    };

    const handleShowModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                modalStatus: !prevState.modalStatus
            }
        })
    }

    const onHandleToggleModal = () => {
        setState(prev => ({
            modalStatus: !prev.modalStatus,
        }))
        props.dispatch(handleToggleModal())
    }

    //funcion para levantar formulario de registro de un usuario
    const formUsuario = () => {
        return <FormInsertarUsuario handleShowModal={handleShowModal} />
    }

    //funcion para limpiar los valores de las variables a utilizar
    const onCleanFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...data,
                }
            }
        })
    }

    //funcion para setear y pasar que filtro se selecciono
    const handleFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...prevState.filtros,
                    [data.key]: data.value
                }
            }
        })
    }

    return (
        <div className="dashboard" ref={dashboardRef} >
            {modal?.modalStatus && (
                <ModalContainer
                    title='USUARIO'
                    form={formUsuario()}
                    modalStatus={state?.modalStatus}
                    handleShowModal={handleShowModal} />
            )}
            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    <section className={sidebarStatus === 'open' ? 'section content-box pd-un' : 'section content-box pd-un content-box-expand'} id="cont-box">
                        <div className="columns">
                            <div className="column is-3 text-al-ini"><h1 className="title-details ml-5 pt-3">Lista de usuarios</h1></div>
                            <div className="column is-6">
                                <SearchTable
                                    placeholder='Buscar un usuario...'
                                    handleChange={handleSearch}
                                />
                            </div>
                            <div className="column is-3 t-al-end">

                                <ProtectedComponent
                                    allowedRoles={['SEGURIDAD', 'ADMINISTRADOR']}>
                                    <button
                                        className="button btn-primary"
                                        onClick={() => onHandleToggleModal()}
                                    >
                                        <FontAwesomeIcon className='mr-2' icon={['fas', 'plus']} color='#FFF' /> Añadir usuario
                                    </button>
                                </ProtectedComponent>

                            </div>
                        </div>
                        <FiltroTableUsers onCleanFilter={onCleanFilter} getFilter={handleFilter} />
                        <Fragment>
                            {
                                <TableUsers filtros={state.filtros} />
                            }
                        </Fragment>

                    </section>
                </div>
            </main>
        </div>
    )
}

function mapStateToProps({ authedUser, modal, sidebarStatus }) {
    return {
        authedUser,
        sidebarStatus,
        modal
    }
}

export default connect(mapStateToProps)(Usuarios)

