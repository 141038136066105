import { useQuery } from '@apollo/client';
import React from 'react'
import { formatDiviceData } from '../../../utils/helpers';
import { GET_VENDEDOR_DIVICE } from '../../../utils/queries/Queries';
import TablaDetalle from '../../common/Tabla/TablaDetalle';

const Dispositivos = (props) => {
    
    const cabecera = {
        firma: {
            id: 1,
            label: 'FRIMA',
            order: 'desc',
            status: false
        },
        modelo: {
            id: 2,
            label: 'MODELO',
            order: 'desc',
            status: false
        },
        marca: {
            id: 3,
            label: 'MARCA',
            order: 'desc',
            status: false
        },
        sistem_name_and_version_os: {
            id: 4,
            label: 'SISTEMA/VERSION',
            order: 'desc',
            status: false
        },
        versioncode: {
            id: 5,
            label: 'VERSIONCODE + VERSIONDESC',
            order: 'desc',
            status: false
        },
        estado: {
            id: 6,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        fechaActualizacion: {
            id: 7,
            label: 'FECHA ACTUALIZACIÓN',
            order: 'desc',
            status: false
        }
    }

    const { data, loading, error } = useQuery(GET_VENDEDOR_DIVICE, {
        variables: {
            idVendedor: props.vendorID,
        }
    });

    const datalist = (data?.vendedor?.allDispositivos)

    console.log(datalist);

    const dataFormated = formatDiviceData(datalist)

    return (
        <TablaDetalle
            cabecera={cabecera}
            detalles={dataFormated}
        />
    )
}

export default Dispositivos