import React, {useState} from "react";
import Select from "../../common/Select"
import DatePicker from 'react-date-picker'

const FiltroMovimientos = props => {

    const initialState = {
        desde: '',
        hasta: '',
        estado: 'TODAS',
        actividad: 'TODAS'
    }

    const [state, setState] = useState(initialState);
    /**
     *
     * **/
    const setSelectedValue = (data) =>{
        // Si hay cambios en los selects se guarda en el state del componente
        // Y se envia al componte padre para aplicar luego el filtro a la tabla
        props.getFilter(data)

        setState(prevState=>(
            {
                ...prevState,
                [data.key]: data.value,
            }
        ))
    }

    const handleCleanFilter = () =>{
        setState(initialState)
        props.onCleanFilter(initialState)
    }

    // Datos de Prueba para Select de estado de movimientos
    const estadoMovimiento = {
        slice: 'estado',
        label:'Estado de movimiento',
        default:'',
        disabled:'Seleccionar estado',
        options:{
            TODAS: 'Todas',
            RECHAZADO: 'RECHAZADO',
            APROBADO: 'APROBADO',
            REVERSADO: 'REVERSADO'
        }
    }

    const tipoMovimiento = {
        slice: 'actividad',
        label: 'Tipo de movimiento',
        default:'',
        disabled:'Seleccionar tipo',
        options:{
            TODAS:'Todas',
            CARGA: 'INGRESO',
            VENTA: 'EGRESO'
        }
    }

    return(
        <div className="columns">
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.estado}
                        optionList={estadoMovimiento}
                        setSelected={setSelectedValue}/>
                </div>
            </div>
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.actividad}
                        optionList={tipoMovimiento}
                        setSelected={setSelectedValue}/>
                </div>
            </div>
            <div className="column align">
                <div className="item-column ">
                    <button className="button btn-other mt-5" onClick={()=>handleCleanFilter()}>Limpiar</button>
                </div>
            </div>
        </div>
    )

}

export  default FiltroMovimientos;