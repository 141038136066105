import React, {Fragment} from 'react'
import {useQuery} from "@apollo/client";
import { formatDataServicioRedes} from "../../../utils/helpers";
import { GET_SERVICIOS_REDES} from "../../../utils/queries/Queries";

const SelectServicioRed = (props) =>{
    if(props.red){

    }

    const { loading, data, error } = useQuery(GET_SERVICIOS_REDES,
         {
            variables: { red: props.red },
        });

    if (error) return(
        <Fragment>
            <div className='control has-icons-left has-icons-right'>
                <label className="label" htmlFor="">Servicio de la red:</label>
                <input className='input pl-2"' type="text" placeholder={`Error! ${error.message}`}/>
            </div>
        </Fragment>
        );
    // todo: Formatear los datos de facturadores correctamente
    const formatedList = formatDataServicioRedes(data)

    return(
        <div className="field">
            <label className="label" htmlFor="">Servicio de la red:</label>
            <div className="control">
                <div className='select is-fullwidth'>
                    {loading && (<button className="button select is-loading is-fullwidth"> </button>)}

                    { data && (
                        <select
                            name="idServicioRed"
                            value={props.idServicioRed}
                            onChange={(e)=> {
                                // console.log('check,', formatedList[e.target.value]);
                                props.handleChange({...formatedList[e.target.value]})
                            }}
                        >
                            <option value='' disabled={!!props.idServicioRed}>Seleccione una red</option>
                            {Object.keys(formatedList).map(item=>{
                                return(
                                    <option key={item} value={formatedList[item].id}>

                                        {formatedList[item].id} - {formatedList[item].nombre}
                                    </option>
                                )
                            })}
                        </select>
                    )}

                </div>
            </div>
        </div>
    )
}

export default SelectServicioRed;