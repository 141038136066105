export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS'
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'

export function toggleModal () {
    return ({
        type: TOGGLE_MODAL,

    })
}

export function setModalStatus(modalStatus){
    return{
        type: SET_MODAL_STATUS,
        modalStatus,
    }
}

export function setModalType(modalType, data){
    return{
        type: SET_MODAL_TYPE,
        modalType,
        data
    }
}
export function handleToggleModal (modalType, data) {
    return (dispatch) => {
        dispatch(toggleModal())
        dispatch(setModalType(modalType,data))

    }
}