import React, { Fragment, useState } from 'react'
import TablaDetalle from "../../common/Tabla/TablaDetalle";
import { formatVentas } from "../../../utils/helpers";
import { GET_VENDEDOR_VENTAS, GET_VENDEDOR_MOVIMIENTOS } from "../../../utils/queries/Queries";
import { useQuery } from "@apollo/client";
import { PuffLoader } from "react-spinners";
import { override } from "../../../utils/helpers";

const TransaccionTabla = (props) => {

    const cabecera = {
        fecha: {
            id: 1,
            label: 'FECHA',
            order: 'desc',
            status: false
        },
        concepto: {
            id: 2,
            label: 'Servicio',
            order: 'desc',
            status: false
        },
        estado: {
            id: 3,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        monto: {
            id: 4,
            label: 'MONTO',
            order: 'desc',
            status: false
        },
        actividad: {
            id: 5,
            label: 'TIPO',
            order: 'desc',
            status: false
        }
    }

    const initialState = {
        color: '#323980',
    }

    const [state, setState] = useState(initialState);

    //fecha auxiliar para fecha de inicio
    const fechaAux = new Date()
    //fecha auxiliar para fecha fin
    const dateData = new Date();
    dateData.setDate(dateData.getDate() + 1);
    //consumimos recurso de movimeintos para obtener movimientos tipo VENTA
    const { data: dataVenta, loading: loadingVenta, error: errorVenta } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            // fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            // fechaFin: dateData.toISOString().slice(0, 10),
            actividad: 'VENTA'
        }
    });
    //declaramos un arreglo de objetos para almacenar el listado de movimientos tipo venta
    const aVentas = dataVenta?.movimientos

    //consumimos recurso de movimientos para obtener movimientos tipo CARGA
    const { data: dataCarga, loading: loadingCarga, error: errorCarga } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            // fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            // fechaFin: dateData.toISOString().slice(0, 10),
            actividad: 'CARGA'
        }
    });
    //declaramos un arreglo de objetos para almacenar el listado de movimientos tipo venta
    const aCargas = dataCarga?.movimientos

    //consumimos recurso de movimientos para obtener movimientos tipo WALLY
    const { data: dataWally, loading: loadingWally, error: errorWally } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            // fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            // fechaFin: dateData.toISOString().slice(0, 10),
            actividad: 'WALLY'
        }
    });
    //declaramos un arreglo de objetos para almacenar el listado de movimientos tipo venta
    const aWally = dataWally?.movimientos
    //concatenamos todos los movimientos
    const aGeneral = aVentas?.concat(aCargas, aWally)
    // declaramos un array final y ordenamos por fecha para que sea descndente de mayor a menor fecha segun la fecha de transaccion
    const aFinal = aGeneral?.sort((a, b) => new Date(a.fechaSolicitud).getTime() - new Date(b.fechaSolicitud).getTime()).reverse()
    //recortamos el listado para solo mostrar 5 registros en tab movimientos
    const showShortList = aFinal?.slice(0, 5);

    // todo: Agregar funcionalidad para manejo de ordenamiento de datos
    /*const handleSort = ()=>{

    }*/

    // todo: agregar funciona para manejador de filtros. Ver de deprecar tb
    /*const handleFilter = ()=>{

    }*/

    //Para mostrar mes correspondiente en el tab de movimientos
    const months = ["Enero", "Frebrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    


    return (
        <Fragment>
            {loadingVenta && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <p className='mt-4'>Cargando datos...</p>
                    </div>
                </div>
            )}
            {errorVenta && (
                'Error cargando detalles de transacciones...'
            )}
            {dataVenta && dataVenta?.movimientos?.length > 0 && (
                <TablaDetalle cabecera={cabecera} detalles={formatVentas(showShortList)} />
            )}
            {dataVenta && dataVenta?.movimientos?.length === 0 && (
                <p className='mt-5 mb-5'>El vendedor no posee movimientos en el mes de {month}</p>
            )}
        </Fragment>

    )
}

export default TransaccionTabla;