import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createStore } from "redux";
import middleware from "./middleware";
import reducer from "./reducers";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
     ApolloClient,
     InMemoryCache,
     ApolloProvider,
     createHttpLink,
     from,
     fromPromise,
     ApolloLink,
     Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { REFRESH_TOKEN } from "./utils/mutators/Mutators";
import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink({
     delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
     },
     attempts: {
          max: 5,
          retryIf: (error, _operation) => !!error,
     },
});

/**
 * Inicializa el store del Redux
 * */
const store = createStore(reducer, middleware);

/**
 * Se definen las URL de los diferentes ambientes
 * ambientes a los que apuntar.
 */
const urlDesarrollo = "https://dev.bomo.fi.com.py/api/";
const urlTesting = "https://test.bomo.fi.com.py/api/";
const urlProduccion = "https://bomo.fi.com.py/api/";

const httpLink = createHttpLink({
     uri: process.env.REACT_APP_KUBERNETES_ENGINE
          ? process.env.REACT_APP_URL_BACKEND
          : urlDesarrollo,
});

const roundTripLink = new ApolloLink((operation, forward) => {
     // Called before operation is sent to server
     operation.setContext({ start: new Date() });

     return forward(operation).map((data) => {
          // Called after server responds
          const time = new Date() - operation.getContext().start;
          console.log(
               `Operation ${operation.operationName} took ${time} to complete`
          );
          return data;
     });
});

const getNewToken = async () => {
     await client
          .mutate({
               mutation: REFRESH_TOKEN,
               variables: {
                    token: localStorage.getItem("refresh"),
               },
          })
          .then((response) => {
               // extract your accessToken from your response data and return it
               localStorage.setItem("token", response.data.refreshToken);
          });
};

let isRefreshing = false;

const setIsRefreshing = (value) => {
     isRefreshing = value;
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
     if (graphQLErrors) {
          for (const err of graphQLErrors) {
               // console.log('[GraphQL error]:', err.extensions.code + ' -> ' + err.message)
               switch (err?.extensions.code) {
                    case "EXPIRED_TOKEN":
                         if (!isRefreshing) {
                              setIsRefreshing(true);

                              return fromPromise(
                                   getNewToken().catch(() => {
                                        setIsRefreshing(false);
                                        if (localStorage.getItem("token")) {
                                             localStorage.removeItem("token");
                                        }

                                        return forward(operation);
                                   })
                              ).flatMap(() => {
                                   setIsRefreshing(false);

                                   return forward(operation);
                              });
                         } else {
                              return fromPromise(
                                   new Promise((resolve) => { })
                              ).flatMap(() => {
                                   return forward(operation);
                              });
                         }
                    // UNAUTHENTICATED
                    case "UNAUTHORIZATED":
                         // localStorage.removeItem('refresh');
                         handleLogoutWithoutHook();

                         return forward(operation);
               }
          }
     }
});
export const handleLogoutWithoutHook = () => {
     // Logout without hook
     localStorage.clear();
     // do other stuff required when logout
     // eslint-disable-next-line no-restricted-globals
     location.reload();
     // location.reload() after token removed affects user redirect
     // when component is wrapped inside <ProtectedRoute> component
};

const authLink = setContext(async (req, { headers, ...context }) => {
     const tokens = localStorage.getItem("token");

     return {
          headers: {
               ...headers,
               authorization: tokens ? tokens : "",
          },
          ...context,
     };
});

const client = new ApolloClient({
     // link: authLink.concat(httpLink),
     link: from([errorLink, authLink, retryLink, httpLink]),
     cache: new InMemoryCache(),
     credentials: "include",
});

ReactDOM.render(
     <BrowserRouter>
          <ApolloProvider client={client}>
               <Provider store={store}>
                    <App />
               </Provider>
          </ApolloProvider>
     </BrowserRouter>,
     document.getElementById("root")
);
