import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const InputBuscador = (props) =>{
    const {label, placeholder, value, name, handleOnChange} = props
    return(
        <div>
            <label className="mb-1" htmlFor="">{label}:</label>
            <br />
            <div className='inputSearchContainer'>
                <FontAwesomeIcon className='icon-search-input' icon={['fas', 'search']} />
                <input
                    onChange={e=>handleOnChange(e)}
                    name={name}
                    type="text"
                    value={value}
                    placeholder={placeholder}/>
            </div>
        </div>
    )
}

export default InputBuscador;