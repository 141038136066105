import React  from 'react'
import logo from '../../assets/images/logo.png';
import GetLoginInfo from "./GetLoginInfo";


const Login = props =>{

    return(
        <div>
            <div className="container is-fluid container-fix-login p-3">
                <div className="columns">
                    <div className="column is-half img-login" id="image-login">
                        <div className="info-container">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className="column is-half form-login">
                        <div className="section content-login">
                            <div className="align">
                                <h1 className="title-login text-color mb-6">BIENVENIDOS</h1>
                            </div>
                            <div>
                                <GetLoginInfo />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Login;