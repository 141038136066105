let vendors = {
    123: {
        id: '123',
        name: 'José Antonio Samudio Martinez',
        dni: '4225225',
        date: '15/08/2020',
        kyc: 'BASICO_PENTIENTE',
        status: 'PENDIENTE',
        city: 'ASUNCIÓN',
        register: '15/08/2020',
    },
    124: {
        id: '124',
        name: 'María Antonieta Martinez',
        dni: '1234567',
        date: '15/08/2020',
        kyc: 'BASICO_PENTIENTE',
        status: 'PENDIENTE',
        city: 'ASUNCIÓN',
        register: '15/08/2020',
    },
    125: {
        id: '125',
        name: 'Andrea Ramirez Martinez',
        dni: '1234568',
        date: '15/08/2020',
        kyc: 'PENDIENTE',
        status: 'ACTIVA',
        city: 'ASUNCIÓN',
        register: '15/08/2020',
    },
    126: {
        id: '126',
        name: 'José Gonzalez Martinez',
        dni: '1234569',
        date: '15/08/2020',
        kyc: 'BASICO',
        status: 'ACTIVA',
        city: 'ASUNCIÓN',
        register: '15/08/2020',
    },
}

// let services = {
//     123: {
//         id: '123',
//         description: 'Concepto de Pago',
//         category: 'Telfonía Movil',
//         facturador: 'TIGO',
//         red: 'PRONET',
//         actions: 'x x',
//     },
//     124: {
//         id: '124',
//         description: 'Concepto de Pago',
//         category: 'Telfonía Movil',
//         facturador: 'TIGO',
//         red: 'PRONET',
//         actions: 'x x',
//     },
//     125: {
//         id: '125',
//         description: 'Concepto de Pago',
//         category: 'Telfonía Movil',
//         facturador: 'TIGO',
//         red: 'PRONET',
//         actions: 'x x',
//     },
// }

export function _getVendors () {
    return new Promise((res, rej) => {
        setTimeout(() => res({...vendors}), 1000)
    })
}