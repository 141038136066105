import React, { useState } from 'react'
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { handleSetAuthedUser } from "../../actions/authedUser";
import { connect } from "react-redux";
import { LOGIN_USER } from "../../utils/mutators/Mutators";
import { version } from "../../../package.json"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jwt from 'jwt-decode';

const GetLoginInfo = (props) => {

    // React router for redirect to dashboard
    let navigate = useNavigate();

    //capturamos el valor del rol en la variable
    const userAuthedRol = props?.authedUser?.rol

    //capturamos el valor de la fecha de actualización
    //para verificar y redirigir en form de reseteo de contraseña
    const isUserReseted = props?.authedUser?.fechaActualizacion

    // Pass mutation to useMutation
    const options = {
        variables: {
            clave: "",
            idTipoDocumento: "CI",
            nroDocumento: "",
            sistema: "BACKOFFICE",
            idDispositivo: null
        }
    }

    // Se instancia el cliente de apolo para hacer la peticion
    const [login, { loading, error }] = useMutation(LOGIN_USER);
    const [errorExist, setErrorExist] = useState(false)

    //funcion que redirige a la pantalla correspondiente 
    const handleLogin = (id, token, refresh) => {

        const user = jwt(localStorage.getItem('token') ? localStorage.getItem('token') : token);

        if (user.payload.fechaActualizacion === null) {
            // Se envia al Store de Redux los datos del operador, y redirige al form de cambio de contraseña
            //setear los datos correspondientes para cambiar la contraseña
            props.dispatch(handleSetAuthedUser(id, token, refresh))
            navigate('/ResetPasswordToLogin')
            return
        }
        // Se envia al Store de Redux los datos del operador
        //redirige al dashboard del sistema
        props.dispatch(handleSetAuthedUser(id, token, refresh))
        navigate('/dashboard')
    }

    const handleSubmit = e => {

        e.preventDefault()
        // se carga el obj options con los datos obtenidos del form
        options.variables = {
            ...options.variables,
            nroDocumento: e.target[0].value,
            clave: e.target[1].value
        }

        login(options).then(r => {
            handleLogin(options.variables.nroDocumento, r.data.login.access, r.data.login.refresh)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error login-> ' + reason);
                setErrorExist(true)
                
            }
        );
    }

    // Initialize a boolean state
    const [passwordShow, setPasswordShow] = useState(false);

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShow(!passwordShow);
    }

    return (
        <div>
            {(error && errorExist) && (
                <div className="notification is-danger">
                    <button className="delete" onClick={ () => setErrorExist(false)}> </button><strong>{error.message}</strong>
                </div>
            )}
            <form onSubmit={e => handleSubmit(e)}>
                <h3 className="subtitle-login text-color">INICIAR SESION</h3>

                <input
                    id="inputUser"
                    className="input is-link inputlogin"
                    type="text"
                    placeholder="Usuario" />
                <div className="item-column d-flex">
                    <input
                        id="inputPass"
                        className="input is-link inputlogin mt-5"
                        type={passwordShow ? "text" : "password"}
                        placeholder="Contraseña" />
                    <span className="togglepss" onClick={togglePassword}><FontAwesomeIcon icon="fa-solid fa-eye" /></span>
                </div>
                <button
                    type='submit'
                    className={loading ? 'button is-normal btn-login mt-5 is-loading' : 'button is-normal btn-login mt-5'}>
                    Iniciar sesión
                </button>
                <div className="align mt-4">
                    <Link to='/ResetPassword' className="is-link mt-5">Olvidé mi contraseña</Link>
                    <div className='mt-5' id='nicknameversion'>Backoffice v{version}</div>
                </div>
            </form>
        </div>
    )
}
function mapStateToProps({ authedUser }) {
    return {
        authedUser,
    }
}
export default connect(mapStateToProps)(GetLoginInfo);