import React, {Component, useState} from "react";
import Select from "../common/Select";
import InputBuscador from "../common/InputBuscador";

const FiltroTable = props =>{
    
    const initialState = {
        categoria_name: '',
        facturador_name: '',
        red: 'TODAS',

    }
    const [state, setState] = useState(initialState)

    const setSelectedValue = (data) =>{
        // Si hay cambios en los selects se guarda en el state del componente
        // Y se envia al componte padre para aplicar luego el filtro a la tabla
        props.getFilter(data)
        console.log('Seteando el filtro: ', data)
        setState(prevState=>(
            {
                ...prevState,
                [data.key]: data.value,
            }
        ))
    }

    const handleOnChange = (e) =>{
        /**
         * Se envía los datos del input de buscador al componente padre <ServicioTable> para actualizar la lista
         * */
        props.getFilter({key: e.target.name, value: e.target.value})

        setState(prev=>({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleCleanFilter = () =>{
        setState(prev=>initialState)
        props.onCleanFilter(initialState)
    }

    // Datos de Prueba para Select de Registro de estado
    const selectRed = {
        slice: 'red',
        label:'Red',
        default:'',
        disabled:'Seleccionar red',
        options:{
            TODAS: 'Todos',
            AP: 'Pronet',
            PE: 'Pago Express'
        }
    }


    return(
        <div className="columns">

            <div className="column">
                <div className="item-column has-text-left">
                    <InputBuscador
                        name='categoria_name'
                        handleOnChange={e=>handleOnChange(e)}
                        label='Categoría'
                        value={state.categoria_name}
                        placeholder='Buscar categoría' />
                </div>
            </div>
            <div className="column">
                <div className="item-column has-text-left">
                    <InputBuscador
                        name='facturador_name'
                        handleOnChange={e=>handleOnChange(e)}
                        label='Facturador'
                        value={state.facturador_name}
                        placeholder='Buscar facturador' />
                </div>
            </div>
            <div className="column">
                <div className="item-column has-text-left">
                    <Select defaultValue={state.red} optionList={selectRed} setSelected={setSelectedValue}/>
                </div>
            </div>
            <div className="column align">
                <div className="item-column">
                    <button className="button btn-other mt-5" onClick={()=>handleCleanFilter()}>Limpiar</button>
                </div>
            </div>
        </div>
    )

}

export  default FiltroTable;