import React, { useState } from "react";
import Select from "../common/Select";
import DatePicker from 'react-date-picker'

const FiltroTable = props => {

    const initialState = {
        desde: '',
        hasta: '',
        kyc: 'TODAS',
        status: 'TODAS',
        currentPage: 1,
    }

    const [state, setState] = useState(initialState);
    /**
     *
     * **/
    const setSelectedValue = (data) => {
        // Si hay cambios en los selects se guarda en el state del componente
        // Y se envia al componte padre para aplicar luego el filtro a la tabla
        props.getFilter(data)

        setState(prevState => (
            {
                ...prevState,
                [data.key]: data.value,
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }
        ))
    }

    const handleCleanFilter = () => {
        setState(initialState)
        props.onCleanFilter(initialState)
    }

    // Datos de Prueba para Select de Registro de estado
    const estadoRegistroOptions = {
        slice: 'kyc',
        label: 'Estado de registro',
        default: '',
        disabled: 'Seleccionar estado',
        options: {
            TODAS: 'Todas',
            PENDIENTE: 'KYC Pendiente',
            BASICO: 'KYC Básico',
            AVANZADO: 'KYC Avanzado',
            MEDIO: 'KYC Medio',
            RECHAZO_TEMP: 'Rechazo temporal',
            RECHAZO_PERM: 'Rechazo Permanente',
        }
    }

    // Datos de Prueba para Select de Registro de estado
    const estadoCuentaOptions = {
        slice: 'status',
        label: 'Estado de cuenta',
        default: '',
        disabled: 'Seleccionar estado de cuenta',
        options: {
            TODAS: 'Todas',
            ACTIVA: 'Activa',
            // PENDIENTE: 'Pendiente',
            BLOQUEO_LOGIN: 'Bloqueo Login',
            BLOQUEO_BACKOFFICE: 'Bloqueo BO',
            BLOQUEO_FACTURA: 'Bloqueo Factura',
            INACTIVA: 'Inactiva'
        }
    }

    const handleDateChange = (data) => {
        const Fecha = new Date(data)
        props.getFilter({
            key: 'desde',
            value: Fecha
        })
        setState(prevState => (
            {
                ...prevState,
                desde: new Date(Fecha),
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }
        ))
    }

    const handleDateHastaChange = (data) => {
        const Fecha = new Date(data)
        props.getFilter({
            key: 'hasta',
            value: Fecha
        })
        setState(prevState => (
            {
                ...prevState,
                hasta: new Date(Fecha),
                currentPage: 1,
                paginacion: {
                    order: "DESC",
                    orderBy: "FECHA_REGISTRO",
                    step: 1,
                    take: 10
                }
            }
        ))
    }

    return (
        <div className="columns">
            <div className="column">
                <div className="item-column">
                    <label className="mb-1" htmlFor="">Fecha de registro desde:</label>
                    <DatePicker
                        clearIcon={null}
                        onChange={handleDateChange}
                        value={state.desde}
                        className='date-input' />
                </div>
            </div>
            <div className="column">
                <div className=" item-column">
                    <label className="mb-1" htmlFor="">Fecha de registro hasta:</label>
                    <DatePicker
                        clearIcon={null}
                        onChange={handleDateHastaChange}
                        value={state.hasta}
                        className='date-input' />
                </div>
            </div>
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.kyc}
                        optionList={estadoRegistroOptions}
                        setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column">
                <div className=" item-column tab">
                    <Select
                        defaultValue={state.status}
                        optionList={estadoCuentaOptions}
                        setSelected={setSelectedValue} />
                </div>
            </div>
            <div className="column align">
                <div className="item-column ">
                    <button className="button btn-other mt-5" onClick={() => handleCleanFilter()}>Limpiar</button>
                </div>
            </div>
        </div>
    )

}

export default FiltroTable;