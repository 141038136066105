import moment from 'moment';
import React, { Fragment, useState } from 'react'

const VendedorDatosCriterion = (props) => {

    const { vendedor } = props

    // console.log("datos basicos", vendedor)
    
    // guardamos en un array el campo correspondiente de contactos y
    //filtramos el nro de teléfono y correo que estan verificados
    const vContacto = props?.vendedor?.contactos?.filter((item) => { return item?.verificado })

    //funcion para obtener el numero de teléfono del vendedor
    const obtNumero = vContacto?.map(element => {
        if (element?.tipo === "TELEFONO") {
            return (element?.valor?.numero);
        }
    });
    
    //función para obtnener el correo del vendedor
    const obtCorreo = vContacto?.map(element => {
        if (element?.tipo === "CORREO") {
            return (element?.valor?.direccion);
        }
    });

    //funcion para dar estilo dato del vendedor segun estado de kyc
    const stateKyc = (dataKyc) => {
        if (dataKyc === "BASICO") {
            return (<p className='box-reg'>{dataKyc}</p>)
        }
        if (dataKyc === "PENDIENTE") {
            return (<p className='box-pending'>{dataKyc}</p>)
        }
        if (dataKyc === "MEDIO") {
            return (<p className='box-medio'>{dataKyc}</p>)
        }
        if (dataKyc === "AVANZADO") {
            return (<p className='box-avanzado'>{dataKyc}</p>)
        }
        if (dataKyc === "RECHAZO_PERM") {
            return (<p className='box-bloq-permanent'>{dataKyc}</p>)
        }
        if (dataKyc === "RECHAZO_TEMP") {
            return (<p className='box-bloq-temp'>{dataKyc}</p>)
        }
    }


    const stateAccount = (dataAccount) => {

        if (dataAccount === "ACTIVA") {
            return (<p className='box-reg'>{dataAccount}</p>)
        }
        if (dataAccount === "BLOQUEO_LOGIN") {
            return (<p className='bloq-login'>{dataAccount}</p>)
        }
        if (dataAccount === "BLOQUEO_FACTURA") {
            return (<p className='bloq_factura'>{dataAccount}</p>)
        }
        if (dataAccount === "BLOQUEO_BACKOFFICE") {
            return (<p className='bloq-bo'>{dataAccount}</p>)
        }
        if (dataAccount === "INACTIVA") {
            return (<p className='box-inactiva'>{dataAccount}</p>)
        }
    }


    return (
        
        <Fragment>
       
            <div className="column is-3">

                <div className="column">
                    <label htmlFor="">Fecha de nacimiento:</label>
                    <p> {vendedor?.fecha_nac}</p>
                </div>
              
                <div className="column">
                    <label htmlFor="">Profesión:</label>
                    <p> {vendedor?.profesion}</p>
                </div>
              
                <div className="column">
                    <label htmlFor="">Lugar de nacimiento:</label>
                    <p>{vendedor?.lugar_nac}</p>
                </div>
            
            </div>
            <div className="column is-3">
                <div className="column">
                    <label htmlFor="">Edad:</label>
                    <p>{vendedor?.edad}</p>
                </div>

                <div className="column">
                    <label htmlFor=""> Estado civil:</label>
                    <p>{vendedor?.estado_civil}</p>
                </div>
                
            </div>
            <div className="column is-3">

                <div className="column">
                    <label htmlFor="">Genero:</label>
                    <p>{vendedor?.genero}</p>
                </div>

                <div className="column">
                    <label htmlFor="">Nacionalidad:</label>
                    <p>{vendedor?.nacionalidad}</p>
                </div>

            </div>
        </Fragment>
    )
}

export default VendedorDatosCriterion;