import React, {Fragment, useEffect, useState} from 'react'
import {BounceLoader} from "react-spinners";
import {override} from "../../utils/helpers";
import SelectCategoria from "./SelectCategoria";
import {ReactComponent as CheckIcon} from "../../assets/images/check.svg";
import {gql, useApolloClient, useMutation} from "@apollo/client";
import {EDIT_FACTURADOR, SET_FACTURADOR, DELETE_FACTURADOR} from "../../utils/mutators/Mutators";
import {GET_FACTURADORES} from "../../utils/queries/Queries";
import {connect} from "react-redux";
import {handleToggleModal} from "../../actions/modal";
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormInsertFacturador = (props) => {
    // todo: el estado debe reflejar el cambio tambien en el selector de la categoría

    const initialState = {
        variables: {
            nombre: '',
            categoriaId: '',
            facturadorId: '',
        },
        loading: false,
        data: false,
        modalState: true,
        color: '#323980',
    }
    const [errorExist, setErrorExist] = useState(false)
    const [validateName, setvalidateName] = useState(false)
    const [validateCat, setvalidateCat] = useState(false)
    const [editMade, seteditMade] = useState(false)


    const [state, setState] = useState(initialState);

    const client = useApolloClient();

    const handleEditMode = () => {
        seteditMade(true);
        const facturadorData = client.readFragment({
            id: `Facturador:${props.modal.data}`, // The value of the to-do item's cache ID
            fragment: gql`
                fragment facturador on Facturador {
                    id
                    nombre
                    categoria{
                        id,
                        nombre
                    }
                }
            `,
        });

        setState(prev => {
            return {
                ...prev,
                variables: {
                    nombre: facturadorData.nombre,
                    categoriaId: facturadorData.categoria.id,
                    facturadorId: facturadorData.id
                }
            }
        })
    }

    const handleChange = (e) => {
        console.log("update", e.target.name);
        if (e.target.name === "nombre") setvalidateName(true)

        setState(prevState => {
            setvalidateCat(true)
            const updatedValues = {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    [e.target.name]: e.target.value,
                }
            }
            return {...updatedValues};
        });
    }

    const handleClick = (e) => {
        e.preventDefault()
        handleAceptarBtn()
        // navigate('/dashboard')
        // window.location.reload();

    }

    const handleAceptarBtn = (e) => {
        props.dispatch(handleToggleModal())
        setState(() => {
            return {...initialState}
        })
        resetInsert()
        resetDelete()
    }

    const handleInsert = () => {
        // se carga el obj options con los datos obtenidos del form
        InsertarFacturador({
            variables: {
                facturador: {
                    categoriaId: state.variables.categoriaId,
                    nombre: state.variables.nombre
                }
            }
        }).then(r => {
            console.log('capturando respuesta de insercion facturador', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error insercion-> ' + reason);
            });
    }

    const handleEdit = () => {
        // se carga el obj options con los datos obtenidos del form
        options.variables.facturador = {
            ...state.variables,
        }

        EditarFacturador({
            variables: {
                facturador: {
                    nombre: state.variables.nombre,
                    categoriaId: state.variables.categoriaId
                },
                facturadorId: state.variables.facturadorId
            }
        }).then(r => {
            console.log('capturando respuesta de edicion facturador', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edit-> ' + reason);
            });
    }

    const errorDel = (reason) => toast.error('Ocurrio un error al eliminar: ' + JSON.stringify(reason.message));
    const successDel = (r) => toast.success('Servicio eliminado exitosamente.')

    const handleDelete  = (e) =>{
        props.dispatch(handleToggleModal())
        EliminarFacturador({
            variables: {
                facturadorId: props.modal.data,
            }
        }).then(r =>{
            console.log('capturando respuesta de borrado de  servicio', r)
            resetDelete()
            successDel(r)
        }).catch(
            // Registrar la razón del rechazo
            function(reason) {
                errorDel(reason)
                console.error('Error al eliminar servicio -> '+ reason);
        }); 
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (props.modal.type === 'edit') {
            handleEdit()
        }
        if((props.modal.type === 'delete')) {
            handleDelete()
        }
        else if(props.modal.type !== 'edit'){
            handleInsert()
        }
    }

    const options = {
        variables: {
            facturador: {
                categoriaId: "2",
                nombre: "Copetin Doña Clotilde"
            }
        }
    }

    // Mutation for inserting a Facturador
    const [InsertarFacturador, {
        data: dataInsert,
        loading: loadingInsert,
        error: errorInsert,
        reset: resetInsert
    }] = useMutation(SET_FACTURADOR, {
        refetchQueries: [
            GET_FACTURADORES, // DocumentNode object parsed with gql
            'Categorias' // Query name
        ],
    });

    // Mutator for Editing a Facturador
    const [EditarFacturador, {
        data: dataEdit,
        loading: loadingEdit,
        error: errorEdit,
        //reset: resetEdit
    }] = useMutation(EDIT_FACTURADOR, {
        refetchQueries: [
            GET_FACTURADORES, // DocumentNode object parsed with gql
            'Facturadores' // Query name
        ],
    });

    // Mutator for Delet a Facturador
    const [EliminarFacturador, {
        data: dataDelete,
        loading: loadingDelete,
        error: errorDelete,
        reset: resetDelete
    }] = useMutation(DELETE_FACTURADOR,  {
        refetchQueries: [
            GET_FACTURADORES, // DocumentNode object parsed with gql
            'Facturadores' // Query name
        ],
    });

    // After component is mounted useEfecct will run and the function handleEditMode() will execute
    // calling the Facturador that is needed to edit from the cache. and populating the local state.
    useEffect(()=>{
        if (props.modal.type === 'edit')
            handleEditMode()
        else if(props.modal.type === 'delete'){
            setState (prevState =>{
                return{
                    ...prevState,
                    modalType:'delete'

                }
            }) 
        }
        // eslint-disable-next-line
    },[])

    // if(errorInsert || errorEdit){
    //     console.log('TESTING ERROR', error)
    // }

    let data = !!(dataEdit || dataInsert || dataDelete);
    const loading = !!(loadingInsert || loadingEdit || loadingDelete)
    

    if (state.modalType !== 'delete'){
        return (
            <Fragment>
                {(errorInsert || errorEdit) && (
                    <div className="notification is-danger">
                        <button className="delete"></button>
                         <strong>{errorInsert ? errorInsert.message : errorEdit.message}</strong>
                    </div>
                )}
                {(loadingInsert || loadingEdit) && (
                    <div>
                        <BounceLoader color={state.color} loading={state.loading} css={override} size={50}/>
                    </div>
                )}
                {(!loadingInsert || !loadingEdit) && (
                    ((!data) && (
                        <form action="" className={props.modal.modalStatus ? 'open' : 'closed'} onSubmit={
                            e => {
                                handleSubmit(e)
                            }}>
    
                            <div className="field">
                                <label className="label">Descripción:</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input
                                        name='nombre'
                                        className="input pl-2"
                                        type="text"
                                        onChange={(e) => handleChange(e)}
                                        value={state.variables.nombre}
                                        placeholder="Descripción del Facturador"/>
                                </div>
                            </div>
    
                            <SelectCategoria defaultValue={state.variables.categoriaId} handleChange={handleChange}/>
    
                            <footer className="modal-card-foot">
                            <button className={`button is-normal ${!((validateCat && validateName) || editMade) ? 'btn-disable' : 'btn-enviar'}`} disabled={!((validateCat && validateName) || editMade)}>Guardar</button>
                                <button
                                    onClick={(e)=>handleClick(e)}
                                    className="button btn-cancelar"
                                    data-bulma-modal="close">Cancelar</button>
                            </footer>
                        </form>
                    ))
                )}
                {(dataInsert || dataEdit) && (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon/>
                        </div>
                        {props.modal.type === "edit" ? (<h6>Facturador modificado exitosamente</h6>) : (<h6>Facturador creado exitosamente</h6>)}
                        <button
                            onClick={(e) => handleClick(e)}
                            className="mt-5 button is-normal btn-enviar" data-bulma-modal="close">
                            Aceptar
                        </button>
                    </div>
    
                )}
            </Fragment>
        )
    }else{
        return(
            <>
                <Fragment>
                    {errorDelete ? (
                        <div className="notification is-danger">
                            <button className="delete"> </button>
                             <strong>{errorDelete.message}</strong>
                        </div>
                    ) : ''}
                    {loading && (
                        <div className='my-5 py-5'>
                            <BounceLoader color={state.color} loading={state.loading} css={override} size={50}/>
                        </div>
                    )}
                    {!loading && (
                        ((!data) && (
                            <form action="" onSubmit={e => {
                                handleSubmit(e)
                            }}>
                                <div className='a-center modal-delete'>
                                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                                        <p>¿Estas seguro de eliminar?</p>
                                </div>
                                <footer className="modal-card-foot">
                                    <button className="button is-normal btn-eliminar">Eliminar</button>
                                    <button onClick={(e) => handleAceptarBtn(e)} className="button btn-cancelar" data-bulma-modal="close">
                                        Cancelar  
                                    </button>
                                </footer>
                            </form>
                        ))
                    )}
                    {data && (
                        <div className='has-text-centered'>
                            <div>
                                <CheckIcon/>
                            </div>
                            <h6>Se elimino correctamente</h6>
                            <button
                                onClick={(e) => handleAceptarBtn(e)}
                                className="mt-5 button is-normal btn-enviar" data-bulma-modal="close">
                                Aceptar
                            </button>
                        </div>
                    )}
                </Fragment>
            </>
        )
    }


   
}

function mapStateToProps({modal}) {
    return {
        modal,
    }
}

export default connect(mapStateToProps)(FormInsertFacturador);