import {combineReducers} from "redux";
import authedUser from './authedUser';
import users from './users'
import sidebarStatus from "./sidebar";
import modal from "./modal";


export default combineReducers({
    authedUser,
    sidebarStatus,
    users,
    modal,
})