import {useQuery} from "@apollo/client";
import {GET_CATEGORIAS} from "./Queries";


// GET_CATEGORIAS
export const useGetCategorias = ()=>{

    const {data, error, loading } = useQuery(GET_CATEGORIAS)
    // const dispatch = useDispatch()
    // dispatch(handleReciveCategorias(data));
    return {data, error, loading }
}