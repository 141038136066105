import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { handleToggleModal } from "../../actions/modal";

/**
 * @param props.form() el form que debe ser renderizado
 * @param props.handleShowModal() funcion para actualiza el estado del modal en el store de redux
 * @param props.title Titulo para el modal
**/
const ModalContainer = (props) => {

    const onHandleToggleModal = () => {
        props.dispatch(handleToggleModal())
    }

    /**Funcion para asignar titulo del modal, creada especificamente
     para filtrar mejor el titulo de las ivas
    */
    const verifyTitle = (title) => {
        if (title === 'IVA_1') {
            return <> Declaración IVA I</>
        }
        if (title === 'IVA_2') {
            return <> Declaración IVA II</>
        }
        if (title === 'IVA_3') {
            return <> Declaración IVA III</>
        } else {
            return <> {title}</>
        }
    }

    return (
        <Fragment>
            <div className={!props.modal.modalStatus ? 'modal' : 'modal is-active'} id="_modalContainer">
                <div className="modal-background"> </div>
                <div className={props.modal.type === 'ver' ? "modal-card movimientos" : "modal-card"} >
                    <header className="modal-card-head">
                        <p className="modal-card-title text-color is-uppercase">
                            {props.modal.type === 'edit' ? 'EDITAR' : props.modal.type === 'delete' ? 'ELIMINAR' :
                            props.modal.type === 'update' ? 'ACTUALIZAR' : props.modal.type === 'view' ? 'VISTA' : props.modal.type === 'ver' ? 'LISTA DE' : 
                            props.modal.type === 'code' ? 'REGLA' : props.modal.type === 'playModal' ? 'ENVIAR' : 
                            props.title === 'NOTIFICACIÓN' ? 'NUEVA' :  props.modal.type === 'viewIva' ? '' : 'NUEVO'}
                                {verifyTitle(props.title)}
                        </p>
                        <button
                            onClick={() => onHandleToggleModal()}
                            className="delete" aria-label="close"
                            data-bulma-modal="close"> </button>
                    </header>
                    <section className="modal-card-body has-text-left">
                        {/* Renderiza el form que se pasó por props */}
                        {props.form}
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

function mapsStateToProps({ modal }) {
    return {
        modal
    }
}

export default connect(mapsStateToProps)(ModalContainer);