import { useMutation } from "@apollo/client";
import { useState } from "react";
import toast from "react-hot-toast";
import { BounceLoader, PropagateLoader } from "react-spinners";
import { override } from "../../utils/helpers";
import { RESET_USER_PASSWORD } from "../../utils/mutators/Mutators";
import { GET_OPERATORS } from "../../utils/queries/Queries";
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg'
import { useNavigate } from "react-router-dom";

const ResetPassword = props => {

    // Pass mutation to useMutation
    const initialState = {
        variables: {
            nroDocumento: '',
        },
    }

    const [errorExist, setErrorExist] = useState(false)

    // Variables: Necesarios para la insercción de la mutación de Servicios
    const [state, setState] = useState(initialState);

    //toast correspondientes a las acciones de bloqueo con los mensajes correspondiente dependiendo del caso
    // const errorDel = (reason) => toast.error('Ocurrio un error al resetear la constraseña: ' + JSON.stringify(reason?.message));
    // const successDel = (r) => toast.success('Reseteo existoso.')

    // Mutator for delete a operator
    const [ResetPassword, { data, loading, error, reset: resetDelete }] = useMutation(RESET_USER_PASSWORD, {
        refetchQueries: [
            GET_OPERATORS, // DocumentNode object parsed with gql
            'Operadores' // Query name
        ],
    });

    //sumbit de los datos correspondientes para el recurso de resteo de contraseña
    const handleSubmit = (e) => {
        e.preventDefault()
        ResetPassword({
            variables: {
                nroDocumento: state?.variables?.nroDocumento
            }
        }).then(r => {
            console.log('capturando respuesta', r)
            // successDel()
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                // errorDel()
                console.error('Error al resetear contraseña -> ' + reason);
            }
        );
    }

    //onchange de los inputs
    const handleChange = (e) => {

        setState(prevState => {
            const updatedValues = {
                ...prevState,
                variables: {
                    ...prevState.variables,
                    [e.target.name]: e.target.value,
                }
            }
            return { ...updatedValues };
        });
    }


    // React router for redirect to dashboard
    let navigate = useNavigate();

    let identificadorTiempoDeEspera;

    function timeToReload() {
        identificadorTiempoDeEspera = setTimeout(funcionConRetraso, 2500);
    }

    function funcionConRetraso() {
        navigate('/')
        localStorage.clear();
    }

    return (
        <div className="container is-fluid container-fix-login resetpass">
            {error && (
                <div className="notification is-danger">
                    <button className="delete"> </button>
                     <strong>{error.message}</strong>
                </div>
            )}
            {(loading) && (
                <div>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                </div>
            )}
            {(!loading) &&
                (!data) &&
                <>
                    <div className="data-password password">
                        <div className="Confirm-data-pass">
                            <form onSubmit={e => handleSubmit(e)}>
                                <h3 className="subtitle-login text-color">RESETEAR CONTRASEÑA</h3>
                                <div className="inpt-pass">
                                    <label>Número de documento</label>
                                    <input
                                        name="nroDocumento"
                                        className="input pl-2"
                                        type="text"
                                        required
                                        onChange={(e) => handleChange(e)}
                                        value={state.variables.nroDocumento}
                                        placeholder="Ingresar nombre..."
                                    />
                                </div>
                                <button type='submit' className={'button is-normal btn-login pass mt-5'}>Resetear contraseña</button>
                            </form>
                        </div>
                    </div>
                </>
            }
            {
                data && (
                    <div className="data-confirm">
                        <div className="confirm-msg-password">
                            <div className="Confirm-data" id="resetpasslogin">
                                <span className="icon-mail"><ion-icon name="checkmark-circle-outline"></ion-icon></span>
                                <p className="mt-3">Reseteo existoso, contraseña temporal enviada a su correo</p>
                                <div className="mt-5">
                                    <PropagateLoader className="loader" color={state.color} loading={state.loading} css={override} size={5} />
                                </div>
                                <div className="mt-4" id="redict">
                                    <span className="redirecting">Redirigiendo pagina...</span>
                                </div>
                                {timeToReload()}
                            </div>

                        </div>
                    </div>
                )
            }
        </div>

    )
}


export default ResetPassword;