import React, { Fragment, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';  // Import the file-saver library
import ExcelJS from 'exceljs';  // Import exceljs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from 'react-bulma-components';
import { sortData, takeData } from '../../../utils/helpers';
import { Link } from 'react-router-dom';

const TableSearchMov = (props) => {

    //Declaramos variables a ser utilizadas
    const [state, setState] = useState({ sort: {}, currentPage: 1 })
    const { cabecera, detalles } = props;
    const [order, setorder] = useState(1)
    let modo = 'asc'

    //ordenamos el listado mediante los filtros aplicados
    const listData = takeData(sortData(Object.keys(detalles).reverse().map(item => detalles[item]), state.sort), state.currentPage)

    //funcion para ordenar asc desc
    const onHandleChangeData = (data) => {
        setorder(order + 1)
        if (order % 2 == 0) {
            modo = 'desc'
        } else {
            modo = 'asc'
        }

        let resetData = state.sort

        Object.keys(resetData).forEach(item => {

            resetData[item] = modo
        })
        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...resetData,
                    [data]: toogleSortData(data)
                }
            }
        })
    }

    //capturamos el nombre del vendedor mediante las props
    const vendorName = props?.dataName

    //convertimos el objeto del listado de movimientos para poder posteriormente utilizar para exportar en excel
    const arrayConvert = Object.keys(props?.dataExport).map(function (key) { return props?.dataExport[key] })

    //funcion para seatear icon de asc y desc y asignar el tipo de la misma 
    const toogleSortData = data => {
        if (state.sort[data] === 'asc') {
            return 'desc'
        } else {
            return 'asc'
        }
    }

    //funcion para cambiar de pagina y acceder a los datos de la misma
    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    // funcion para filtrar dato segun su tipo y asi poder asignar estilos correspondientes
    const dataFilter = (data) => {
        if (data === "VENTA" || data === "WALLY") {
            return (
                <>
                    <span className='egreso'>EGRESO <ion-icon name="trending-up-outline"></ion-icon></span>
                </>
            )
        }
        if (data === "CARGA" || data === "COMIS") {
            return (
                <>
                    <span className='ingreso'>INGRESO <ion-icon name="trending-down-outline"></ion-icon> </span>
                </>
            )
        }
        else {
            return data
        }
    }

    useEffect(() => {
        let filtros = Object.keys(cabecera)

        setState(prev => {
            return {
                ...prev,
                sort: {
                    ...filtros.reduce(function (target, key, index) {
                        target[key] = 'asc';
                        return target;
                    }, {})
                }
            }
        })
    }, [cabecera])

    // Function to export data to Excel
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Listado');

        // Add headers
        Object.keys(cabecera).forEach((item, index) => {
            worksheet.getCell(1, index + 1).value = item;
        });
    
        // Add data rows with only columns from the headers
        Object.keys(props.detalles).forEach((item, rowIndex) => {
            Object.keys(cabecera).forEach((headerKey, colIndex) => {
                const dataValue = props.detalles[item][headerKey]; // Get data value corresponding to the header
                worksheet.getCell(rowIndex + 2, colIndex + 1).value = dataValue;
            });
        });
    
        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Lista de movimientos ' + vendorName +'.xlsx');
    };

    const handleClick = (e) => {
        console.log('filter handle clicl', e.target)
    }

    return (
        <Fragment>
            <div className="columns">
                <div className="column">
                    <div className="" id="itemExport">
                        <button
                            className="btnExport d-flex"
                            style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            cursor: 'pointer',
                            display: 'flex', // Add this to make the button a flex container
                            alignItems: 'center', 
                            marginLeft: '20px' // Center the content vertically
                            }}
                            onClick={exportToExcel}
                        >
                            <span
                                onMouseEnter={() => {
                                    // Change the color of both the icon and text
                                    document.getElementById('icon-export').style.color = 'black';
                                    document.getElementById('text-export').style.color = 'black';
                                }}
                                onMouseLeave={() => {
                                    // Change the color of both the icon and text back to default
                                    document.getElementById('icon-export').style.color = '#195bab';
                                    document.getElementById('text-export').style.color = '#195bab';
                                }}
                                >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        id="icon-export"
                                        style={{ fontSize: '15px', color: '#195bab', marginRight: '5px' }} // Removed marginLeft
                                    />
                                    <p
                                        id="text-export"
                                        style={{ fontSize: '16px', margin: 0, color: '#195bab' }}
                                    >
                                        Exportar
                                    </p>
                                </div>
                            </span>
                        </button>
                    </div>
                    <table className="table is-striped table-list">
                        <thead>
                            <tr className="table-header">
                                {Object.keys(cabecera).map(item => {
                                    return (
                                        <th key={cabecera[item].id !== 'fechaFilter'} className="al">
                                            <Link to="" id={'filter-' + item} onClick={e => handleClick(e)}>
                                                {cabecera[item].label} <FontAwesomeIcon icon={['fas', 'sort']} className='ml-2' />
                                            </Link>
                                        </th>
                                    )
                                })}

                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(listData).map(row => {
                                return (
                                    <tr key={row} className="tr-size ">
                                        {Object.keys(listData[row]).filter(item => item !== 'fechaFilter').map(item => {
                                            return (
                                                <td key={item}>{dataFilter(listData[row][item])}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='pt-5'>
                    <Pagination
                        current={state.currentPage}
                        next={'>'}
                        previous={'<'}
                        total={Object.keys(detalles).length / 10 % 1 == 0 ? Math.floor(Object.keys(detalles).length / 10) : Math.floor(Object.keys(detalles).length / 10) + 1}
                        onChange={handleCurrentPage}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default TableSearchMov