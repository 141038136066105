import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from "@apollo/client";
import {
    filterDataList,
    filterDataListServicio,
    formatAntecedentesVendedores,
    formatDataCategory,
    formatDataFacturadores,
    override, takeData, takeDataSearch
} from "../../utils/helpers";

import { PuffLoader } from "react-spinners";
import { Pagination } from 'react-bulma-components';
import { GET_INFORME_DETALLADO_BY_ID } from '../../utils/queries/Queries';
import TableSearch from '../common/TableSearch';
import Table from '../common/Table';
import { formatRelacionesVendedores } from '../../utils/helpers';




const VendedorRelaciones = (props) => {

    //declaramos las variables que utilizaremos
    const [state, setState] = useState(
        {
            filtros: {
                nombre: '',
            },
            headers: {
            },
            loading: true,
            color: '#323980',
            currentPage: 1,
        }
    );
    // Headers de la tabla
    const headers = {
       
        vinculo: 'VINCULO',
        nroDocumento: 'NRO DOCUMENTO',
        nombre: 'NOMBRES Y APELLIDOS',
      

    }

    //seteamos los filtros a ser utlizamos
    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))
    }, [props.filtros])


    const { data, loading, error } = useQuery(GET_INFORME_DETALLADO_BY_ID, {
        variables: {
            idVendedor: props?.vendedorID,
        }
    })

    if (error) {
        console.log('Testing ERROR: ', typeof error)
        return `Error! ${error.message}`
    }

    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    return (
        <div>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {data && (
                <Fragment>
                {
                    props?.filtros?.nombre
                        ?
                        <>
                            <TableSearch headers={headers} data={filterDataList(formatRelacionesVendedores(data), state.filtros)} />

                            {/* <Table headers={headers} data={takeData(filterDataList(formatDataFacturadores(data), state.filtros), state.currentPage)} /> */}
                        </> :
                        <>
                            <Table headers={headers} data={takeDataSearch(filterDataList(formatRelacionesVendedores(data), state.filtros), state.currentPage)} />
                        </>
                }
            </Fragment>
            )}
        </div>
    )
}

export default VendedorRelaciones;