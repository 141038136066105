import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from "@apollo/client";
import { GET_SERVICIOS } from "../../utils/queries/Queries";
import Table from "../common/Table";
import { filterDataList, filterDataListServicio, formatDataServices, override, takeData, takeDataSearch } from "../../utils/helpers";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
// import Pagination from "../common/Pagination";
import { Pagination } from 'react-bulma-components';
import TableSearch from '../common/TableSearch';


const ServiciosTable = (props) => {

    // declaramos los variables con los valores iniciales
    const [state, setState] = useState(
        {
            vendors: [],
            filtros: {
                busqueda: '',
                categoria_name: '',
                facturador_name: '',
                red: 'TODAS',
                servicio_name: '',
                idServicioRed:''
            },
            headers: {
                description: 'Descripción',
                category: 'Categoría',
                facturador: 'Facturadores',
                red: 'Red',
                diligenciaAmpliada:'Control de umbrales',
                habilitado: 'Estado de servicio',
                actions: 'Acciones'
            },
            loading: true,
            color: '#323980',
            currentPage: 1,
        }
    );
    
    const navigate = useNavigate();

    //consultamos los datos
    const { loading, error, data } = useQuery(GET_SERVICIOS, {
        onCompleted: () => navigate('/servicios')
    });


    const handleCurrentPage = data => {
        setState(prev => ({
            ...prev,
            currentPage: data,
        }))
    }

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...props.filtros,
            }
        }))


    }, [props.filtros])

    return (
        <div>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <h6 className='mt-4'>Cargando datos...</h6>
                    </div>
                </div>
            )}
            {data && (

                <Fragment>
                    {props?.filtros?.servicio_name ?
                        <>
                            <TableSearch headers={state.headers} data={filterDataList(formatDataServices(data), state.filtros)} />
                        </>
                        :
                        <>
                            < Table headers={state.headers} data={takeDataSearch(filterDataList(formatDataServices(data), state.filtros), state.currentPage)} />
                        </>}
                </Fragment>
            )}
            {error && (
                <h6>
                    {`Error! ${error.message}`}
                </h6>
            )}

        </div>
    )
}

export default ServiciosTable;