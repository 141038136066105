import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { connect } from "react-redux"
import { handleToggleModal } from "../../actions/modal";
import ModalContainer from "../common/ModalContainer";
import ProtectedComponent from "../common/ProtectedComponent";
import SearchTable from "../common/SearchTable";
import TableHeader from "../Vendedores/TableHeader";
import FiltroNotifications from "./FiltroNotifications";
import FormInsertarNotificacion from "./FormInsertarNotificacion";
import TableNotifications from "./TableNotifications";


const Notificaciones = (props) => {

    const dashboardRef = React.createRef();
    const { sidebarStatus, modal } = props;

    //declaramos los valores inicales a ser utilizadas
    const initialState = {
        filtros: {
            titulo: '',
            prioridad: '',
            estado: '',
            desde: '',
            hasta: ''
        },
        headers: {
            titulo: 'Titulo',
            fechainsercion: 'Fecha de inserción',
            contenido: 'Contenido',
            prioridad: 'Prioridad',
            estado: 'Estado',
            acciones: 'Acciones'
        },
        currentPage: 1,
    }

    //inicializamos las variables
    const [state, setState] = useState(initialState);

    //funcion que hace el llamado al modal de creación de una notificación
    const formNotifications = () => {
        return (
            <>
                <FormInsertarNotificacion handleShowModal={handleShowModal} />
            </>
        )
    }

    //fincion para mostrar modal
    const handleShowModal = () => {
        setState(prevState => {
            return {
                ...prevState,
                modalStatus: !prevState.modalStatus
            }
        })
    }

    //funcion para cerra modal
    const onHandleToggleModal = () => {
        setState(prev => ({
            modalStatus: !prev.modalStatus,
        }))
        props.dispatch(handleToggleModal())
    }

    //funcion para setear y pasar que filtro se selecciono
    const handleFilter = (data) => {

        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...prevState.filtros,
                    [data.key]: data.value
                }
            }
        })
    }

    //funcion para setear a las variables a sus valores inicales
    const onCleanFilter = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                filtros: {
                    ...data,
                },

            }
        })
    }

    //onchange correspondiente para hacer la busqueda 
    const handleSearch = data => {
        setState(prev => ({
            ...prev,
            filtros: {
                ...prev.filtros,
                titulo: data,
            },
            currentPage: 1,
        }))
    }

    return (
        <div className="dashboard" ref={dashboardRef} >
            {modal?.modalStatus && (
                <ModalContainer
                    title='NOTIFICACIÓN'
                    form={formNotifications()}
                    modalStatus={state?.modalStatus}
                    handleShowModal={handleShowModal} />
            )}
            <main className={sidebarStatus}>
                <div id='cont-box' className={
                    (sidebarStatus === 'open')
                        ? 'container is-fluid main-content'
                        : 'container is-fluid main-content main-content-expand'
                }>
                    <section className={sidebarStatus === 'open' ? 'section content-box pd-un' : 'section content-box pd-un content-box-expand'} id="cont-box">
                        <div className="columns">
                            <div className="column is-3 text-al-ini"><h1 className="title-details ml-5 pt-3">Notificaciones</h1></div>
                            <div className="column is-6">
                                <SearchTable
                                    placeholder='Buscar una notificación...'
                                    handleChange={handleSearch}
                                />
                            </div>
                            <div className="column is-3 t-al-end">
                                <ProtectedComponent
                                    allowedRoles={['MARKETING']}>
                                    <button
                                        className="button btn-primary"
                                        onClick={() => onHandleToggleModal()}
                                    >
                                        <FontAwesomeIcon className='mr-2' icon={['fas', 'plus']} color='#FFF' />  Crear notificación
                                    </button>
                                </ProtectedComponent>
                            </div>
                        </div>
                        <FiltroNotifications onCleanFilter={onCleanFilter} getFilter={handleFilter} />
                        <TableNotifications filtros={state.filtros} />
                    </section>
                </div>
            </main>
        </div>
    )
}


function mapStateToProps({ authedUser, modal, sidebarStatus }) {
    return {
        authedUser,
        sidebarStatus,
        modal
    }
}

export default connect(mapStateToProps)(Notificaciones)

