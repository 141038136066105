import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/logo.png";
import { handleToggleSidebar } from "../../actions/sidebar";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ProtectedComponent from "./ProtectedComponent";
import { Dropdown } from "react-bulma-components"

const Sidebar = props => {
    // todo: convertir a función para poder usar el useLocation y poder detectar la ruta para saber
    //  cual es el menu activo

    const { nombre, apellido } = props;

    const intialState = {
        vendedores: 'is-active',
        servicios: '',
        categorias: '',
        facturadores: '',
    }

    //capturamos el valor del rol en la variable
    const userAuthedRol = props?.rol

    const [state, setState] = useState(intialState);

    const handleOnClick = (e) => {
        e.preventDefault()
        const { dispatch, sidebarStatus } = props;
        dispatch(handleToggleSidebar({ sidebarStatus }))

    }

    const handleSetActive = (data) => {
        let blankState = {
            vendedores: '',
            servicios: '',
            categorias: '',
            facturadores: '',
            vendedor: '',
            usuarios: '',
            notificaciones: '',
        }
        setState(prevState => ({
            ...blankState,
            [data]: 'is-active'
        }))

    }

    const handleOnLoad = () => { }

    const { sidebarStatus } = props;

    const { pathname } = useLocation();

    if (pathname === '/login') {
        return ''
    }

    // funcion oncilck para aplicar el eliminado de el localsotrage una vez que usuario se desloguea
    const clearLocalStorage = () => {
        localStorage.clear();
    }

    return (
        <aside className={sidebarStatus === 'open' ? 'menu sidebar' : 'menu sidebar small'} onLoad={handleOnLoad()}>
            <ul className="menu-list align expand pb-3 pt-6">
                <FontAwesomeIcon icon={['fas', 'bars']} color='#FFF' onClick={handleOnClick} />
            </ul>
            <ul className="menu-list pb-6 pt-6">
                <img src={logo} alt="" className='main-logo' />
            </ul>

            <ul className="menu-list mb-auto">
                <ProtectedComponent
                    allowedRoles={['ADMINISTRADOR', 'CUMPLIMIENTO', 'OPERADOR']}>
                    <li
                        id="vendedores"
                        onClick={(e) => handleSetActive('vendedores')}
                    >
                        <Link to="/dashboard" className={state.vendedores} >
                            {/*<FontAwesomeIcon icon={['fas', 'users']} color='#FFF' className='icon-sidebar' />*/}
                            <FontAwesomeIcon icon={['fas', 'users']} color='#FFF' className='icon-sidebar' />
                            <span>Vendedores </span>
                        </Link>
                    </li>
                </ProtectedComponent>
                <ProtectedComponent
                    allowedRoles={['ADMINISTRADOR']}>
                    <li
                        id="servicios"
                        onClick={(e) => handleSetActive('servicios')}
                    >
                        <Link
                            to="/servicios"
                            className={state.servicios} id="linkservices">
                            <FontAwesomeIcon icon={['fas', 'bookmark']} color='#FFF' className='icon-sidebar' />
                            <span>Servicios</span>
                        </Link>
                    </li>
                </ProtectedComponent>
                <ProtectedComponent
                    allowedRoles={['ADMINISTRADOR']}>
                    <li
                        id="categorias"
                        onClick={(e) => handleSetActive('categorias')}
                    >
                        <Link
                            to="/categorias"
                            className={state.categorias} id="linkservices">
                            <FontAwesomeIcon icon={['fas', 'layer-group']} color='#FFF' className='icon-sidebar' />
                            <span>Categorias</span>
                        </Link>
                    </li>
                </ProtectedComponent>
                <ProtectedComponent
                    allowedRoles={['ADMINISTRADOR']}>
                    <li
                        id="facturadores"
                        onClick={(e) => handleSetActive('facturadores')}
                    >
                        <Link
                            to="/facturadores "

                            className={state.facturadores} id="linkservices">
                            <FontAwesomeIcon icon={['fas', 'id-badge']} color='#FFF' className='icon-sidebar' />
                            <span>Facturadores</span>
                        </Link>
                    </li>
                </ProtectedComponent>
                <ProtectedComponent
                    allowedRoles={['SEGURIDAD']}>
                    <li
                        id="usuarios"
                        onClick={(e) => handleSetActive('usuarios')}
                    >
                        <Link
                            to="/usuarios "

                            className={state.usuarios} id="linkservices">
                            <FontAwesomeIcon icon="fa-solid fa-person" color='#FFF' className='icon-sidebar' />
                            <span>Usuarios</span>
                        </Link>
                    </li>
                </ProtectedComponent>
                <ProtectedComponent
                    allowedRoles={['MARKETING', 'ADMINISTRADOR']}>
                    <li
                        id="notificaciones"
                        onClick={(e) => handleSetActive('notificaciones')}
                    >
                        <Link
                            to="/notificaciones "

                            className={state.notificaciones} id="linkservices">
                            <FontAwesomeIcon icon="fa-solid fa-at" color='#FFF' className='icon-sidebar' />
                            <span>Notificaciones</span>
                        </Link>
                    </li>
                </ProtectedComponent>
            </ul>
            <ul className="menu-list sidebar-menu footer">
                <div id="user_details d-flex">
                    <div className="d-grid" >
                        <span className='has-text-white welcome-text'>¡Hola!, Bienvenido</span>
                        <span className='has-text-white'>{nombre} {apellido}</span>
                    </div>
                    <Dropdown icon={<FontAwesomeIcon icon="fa-solid fa-arrow-right" />} className="password-res" >
                        <Link className="password-reset" to="/Ajustes" onclick={() => window.location.reload()}>
                            <FontAwesomeIcon className='mr-2 text-change-pass' icon="fa-solid fa-key" />
                            <span className='has-text-dark-pass'>Cambiar contraseña</span>
                        </Link>
                    </Dropdown>
                </div>
                <li className="divisoria" id="log_out" onClick={clearLocalStorage}>
                    <Link to="/" className="">
                        <FontAwesomeIcon className='mr-2 has-text-danger' icon={['fas', 'sign-out-alt']} />
                        <span className='has-text-white'>Cerrar sesión</span>
                    </Link>
                </li>
            </ul>
        </aside>
    )
}

function mapStateToProps({ sidebarStatus, authedUser }) {
    return {
        sidebarStatus,
        ...authedUser
    }
}

export default connect(mapStateToProps)(Sidebar);