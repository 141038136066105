import {SET_MODAL_STATUS, SET_MODAL_TYPE, TOGGLE_MODAL} from "../actions/modal";

export default function modal(state = {}, action){

    switch (action.type){
        case SET_MODAL_STATUS:
            return {
                ...state,
                modalStatus: action.modalStatus,
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                modalStatus: !state.modalStatus,
            }
        case SET_MODAL_TYPE:
            return {
                ...state,
                type: action.modalType,
                data: action.data
            }
        default:
            return state
    }
}
