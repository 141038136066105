import React from 'react'
import { useState } from 'react'

const NotificationError = (props) => {
    const message = props?.message
    const [hide, setHide] = useState(false)

    const handleOnClick = (e, btn) => {
        e.preventDefault()

        if (btn === "_btnClose") {
            setHide(true)
        }
    }

    return (
        <div className={hide !== true? "notification is-danger" : "hide"}>
            <button onClick={(e) => handleOnClick(e, '_btnClose')} class="delete" ></button>
            {message}
        </div>

    )
}

export default NotificationError