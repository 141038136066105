import React, { Fragment, useState } from 'react'
import TablaDetalle from "../../common/Tabla/TablaDetalle";
import { formatComisiones, override } from "../../../utils/helpers";
import { useQuery } from "@apollo/client";
import { GET_VENDEDOR_MOVIMIENTOS } from "../../../utils/queries/Queries";
import { PuffLoader } from "react-spinners";


const ComisionTabla = (props) => {

    //fecha auxiliar para fecha de inicio
    const fechaAux = new Date()
    //fecha auxiliar para fecha fin
    const dateData = new Date();
    //dateData.setDate(dateData.getDate());
    dateData.setDate(dateData.getDate() + 1);

    //declaramao la variables a utilizar
    const initialState = {
        variables: {
            idVendedor: props.vendorID,
            actividad: 'COMIS',
            fechaInicio: new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 1).toISOString().slice(0, 10),
            fechaFin: dateData.toISOString().slice(0, 10)
        },
        color: '#323980',
    }

    //inicializamos las variables a utilizar
    const [state, setState] = useState(initialState)

    //declaramos la cabecera de nuestra tabla
    const cabecera = {
        fecha: {
            id: 1,
            label: 'FECHA',
            order: 'desc',
            status: false
        },
        concepto: {
            id: 2,
            label: 'CONCEPTO',
            order: 'desc',
            status: false
        },
        estado: {
            id: 3,
            label: 'ESTADO',
            order: 'desc',
            status: false
        },
        acreditadoNetel: {
            id: 4,
            label: 'ESTADO DE PAGO NETEL',
            order: 'desc',
            status: false
        },
        acreditadoCabecera: {
            id: 5,
            label: 'ESTADO DE PAGO BOMO',
            order: 'desc',
            status: false
        }
        ,
        monto: {
            id: 5,
            label: 'MONTO',
            order: 'desc',
            status: false
        },
    }

    //consultamos los datos correspondientes a comisiones del vendedor
    const { data, loading, error } = useQuery(GET_VENDEDOR_MOVIMIENTOS, {
        variables: {
            idVendedor: +props.vendorID,
            fechaInicio: state?.variables?.fechaInicio,
            fechaFin: state?.variables?.fechaFin,
            actividad: state?.variables?.actividad
        }
    });

    const months = ["Enero", "Frebrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];

    //se crea un array para guardar el listado de comisiones
    const aComisiones = data?.movimientos ? data?.movimientos : []
    //limitamos el listado de comisiones a 5 registros
    var showShortList = aComisiones.slice(0, 5);

    return (
        <Fragment>
            {loading && (
                <div className='my-5 py-5'>
                    <div>
                        <PuffLoader color={state.color} loading={state.loading} css={override} size={50} />
                        <p className='mt-4'>Cargando datos...</p>
                    </div>
                </div>
            )}
            {data && aComisiones?.length > 0 && (
                <TablaDetalle cabecera={cabecera} detalles={formatComisiones(showShortList)} />
            )}
            {data && aComisiones?.length === 0 && (
                <div className='Columns' id='nodatamov'>
                    <div className='Column'>
                        <p className='mt-5 mb-5'>El vendedor no posee comisiones en el mes de {month} </p>
                    </div>
                </div>
            )}

        </Fragment>

    )
}

export default ComisionTabla;