import React, { Fragment } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { handleToggleModal } from "../../actions/modal";
import { Toaster } from 'react-hot-toast';
import ProtectedComponent from '../common/ProtectedComponent';
import { GET_NOTIFICATIONS_BY_ID } from '../../utils/queries/Queries';
import { useQuery } from '@apollo/client';

const ActionsNotificationList = (props) => {

    const handleOnClick = (e, btn) => {
        e.preventDefault()

        if (btn === '_btnDelete') {
            props.dispatch(handleToggleModal('delete', props.dataID))
        }
        if (btn === '_btnEdit') {
            props.dispatch(handleToggleModal('edit', props.dataID));
        }
        // if (btn === '_btnCode'){
        //     props.dispatch(handleToggleModal('code', props.idRegla));

        // }
        if (btn === '_btnPlayCode'){
            props.dispatch(handleToggleModal('playModal', props.dataID));
        }
    }

    return (
        <Fragment>
            <button id='_btnEdit' className='button is-link is-inverted'
                onClick={(e) => handleOnClick(e, '_btnEdit')}
            >
                <FontAwesomeIcon icon={['fas', 'pen']} />
            </button>
            {/* <button id='_btnDelete'
                className={'button is-danger is-inverted '}
            >
                <FontAwesomeIcon icon={['fas', 'times']} />
            </button> */}


            {/* <ProtectedComponent
                allowedRoles={'ADMINISTRADOR'}>

            <ProtectedComponent
                allowedRoles={['ADMINISTRADOR', 'MARKETING']}>
                <button id='_btnCode'
                    className={'button is-link is-inverted'}
                    onClick={(e) => handleOnClick(e, '_btnCode')}
                >
                 <FontAwesomeIcon icon="fa-solid fa-code" />
                </button>
            </ProtectedComponent> */}



       
            <ProtectedComponent
                allowedRoles={['MARKETING', 'ADMINISTRADOR']}>
                {
                    props.estado === 'LISTO' &&
                    <button id='_btnPlayCode'
                        className={'button is-link is-inverted'}
                        onClick={(e) => handleOnClick(e, '_btnPlayCode')}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-play" />
                    </button>
                }
               
            </ProtectedComponent>
           

            <Toaster position="top-right" toastOptions={{
                className: '',
                style: {
                    border: '1px solid rgba(85, 82, 217, 0.2)',
                    padding: '16px',
                    color: '#713200',
                },
            }} />
        </Fragment>
    )
}

function mapStateToProps({ modal }) {
    return {
        modal,
    }
}

export default connect(mapStateToProps)(ActionsNotificationList)