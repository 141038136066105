import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { BounceLoader } from "react-spinners";
import { override } from "../../../utils/helpers";
import { UPDATE_DATA_PEP } from "../../../utils/mutators/Mutators";
import { GET_VENDEDOR } from "../../../utils/queries/Queries";
import { ReactComponent as CheckIcon } from "../../../assets/images/check.svg";
import { handleToggleModal } from "../../../actions/modal";

const FormPEP = props => {

    //declaramos las variables 
    const initialState = {
        variables: {
            idVendedor: props.idVendedor,
            pep: props.estadoPEP,
        }
    }

    //incicializamos en el state
    const [state, setState] = useState(initialState);

    //hacemos la consulta al recurso correspondiente
    const [ModificarPEPVendedor, { data, loading, error, reset }] = useMutation(UPDATE_DATA_PEP, {
        refetchQueries: [
            GET_VENDEDOR, // DocumentNode object parsed with gql
            'Get Vendedor' // Query name
        ],
    })

    //seteamos los valores correspondientes
    const onHandleChange = (e) => {
        e.preventDefault()
        setState(state => (
            {
                ...state,
                variables: {
                    ...state.variables,
                    pep: e.target.value === "true" ? true : false,
                }
            }
        ))

    }

    //funcion para cerrar el modal
    const handleCloseModal = () => {
        props.dispatch(handleToggleModal())
        reset()
    }

    //funcion para enviar los datos correspondientes al recurso correspondiente
    const handleSubmit = () => {
        ModificarPEPVendedor({
            variables: {
                idVendedor: state.variables.idVendedor,
                pep: state.variables.pep,
            }
        }).then(r => {
            console.log('Rpta Edicion PEP', r)
        }).catch(
            // Registrar la razón del rechazo
            function (reason) {
                console.error('Error edicion PEP-> ' + reason);
            }
        );
    }

    return (
        <Fragment>
            {error &&
                <div className="notification is-danger">
                    <button className="delete"></button>
                    {error.message}
                </div>
            }
            {
                !loading && (
                    !data && (
                        <form>
                            <div className="Columns">
                                <div className="column is-full d-grid mb-5">
                                    <label>PEP:</label>
                                    <select className="select-options pl-2 w-100" value={state.variables.pep} onChange={onHandleChange}>
                                        <option disabled>Seleccionar</option>
                                        <option value={true}>SI</option>
                                        <option value={false}>NO</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    )
                )
            }
            {loading && (
                <div className='my-5 py-5'>
                    <BounceLoader color={state.color} loading={state.loading} css={override} size={50} />
                </div>
            )}
            {!data
                ? (
                    <footer className="modal-card-foot pb-0">

                        <button

                            onClick={() => handleSubmit()}
                            className={loading ? 'button is-normal btn-enviar is-loading' : 'button is-normal btn-enviar'}>Guardar</button>
                        <button
                            onClick={() => handleCloseModal()}
                            className="button btn-cancelar" data-bulma-modal="close">Cancelar
                        </button>
                    </footer>
                )
                : (
                    <div className='has-text-centered'>
                        <div>
                            <CheckIcon />
                        </div>
                        <h6>PEP editado correctamente</h6>
                        <footer className="modal-card-foot pb-0">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    handleCloseModal()
                                }}
                                className='button is-normal btn-enviar'>
                                Aceptar
                            </button>
                        </footer>
                    </div>
                )
            }
        </Fragment>
    )
}


function mapStateToProps({ authedUser }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(FormPEP);   