import React from 'react'
import {useQuery} from "@apollo/client";
import { formatDataFacturadoresSelect } from "../../../utils/helpers";
import {GET_FACTURADORES} from "../../../utils/queries/Queries";

const SelectFacturador = (props) =>{

    const { loading, data, error } = useQuery(GET_FACTURADORES);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    const facturadoresList = formatDataFacturadoresSelect(data)

    return(
        <div className="field">
            <label className="label">Facturador:</label>
            <div className="control">
                <div className='select is-fullwidth'>
                    {/*<input className="input pl-2" type="text" placeholder="Seleccione categoria para el facturador" />*/}
                    <select
                        name="_categoria"
                        value={props.facturador}
                        onChange={(e)=> {
                            // console.log('check,', facturadoresList[e.target.value]);
                            props.handleChange({...facturadoresList[e.target.value]})
                        }}
                    >
                        <option value="">Seleccione un facturador</option>
                        {Object.keys(facturadoresList).map(item=>{
                            return(
                                <option key={item} value={facturadoresList[item].id}>{facturadoresList[item].nombre}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default SelectFacturador;